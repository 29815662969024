import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  collapsed: false,
  onHover: false,
  dashboard: " ",
  otpFlag: false,
  modalOpen: false,
  count: "",
  loadTemplateJson: {},
  upstreamAmount: "",
  downstreamAmount: "",
  totalAmount: "",
  payoutAmount: "",
  payoutCount: "",
  payoutModalOpen: "",
  profile: "",
  loadTemplateJson: {},
};

export const projectSlice = createSlice({
  name: "project",
  initialState: initialState,
  reducers: {
    resetaAllProjectDetails: () => initialState,

    setCollapsed(state, action) {
      state.collapsed = action.payload;
    },
    setOnHover(state, action) {
      state.onHover = action.payload;
    },
    setDashboard(state, action) {
      state.dashboard = action.payload;
    },
    setOtpFlag(state, action) {
      state.otpFlag = action.payload;
    },
    setModalOpen(state, action) {
      state.modalOpen = action.payload;
    },
    setCount(state, action) {
      state.count = action.payload;
    },
    setDownstreamAmount(state, action) {
      state.downstreamAmount = action.payload;
    },
    setUpstreamAmount(state, action) {
      state.upstreamAmount = action.payload;
    },
    setLoadTemplateJson(state, action) {
      state.loadTemplateJson = action.payload;
    },
    setTotalAmount(state, action) {
      state.totalAmount = action.payload;
    },
    setPayoutAmount(state, action) {
      state.payoutAmount = action.payload;
    },
    setPayoutCount(state, action) {
      state.payoutCount = action.payload;
    },
    setPayoutModalOpen(state, action) {
      state.payoutModalOpen = action.payload;
    },
    setProfile(state, action) {
      state.profile = action.payload;
    },
    setLoadTemplateJson(state, action) {
      state.loadTemplateJson = action.payload;
    },
  },
});

export const {
  setCollapsed,
  setOnHover,
  setDashboard,
  setOtpFlag,
  setModalOpen,
  setCount,
  setDownstreamAmount,
  setLoadTemplateJson,
  setUpstreamAmount,
  setTotalAmount,
  setPayoutAmount,
  setPayoutCount,
  setPayoutModalOpen,
  setProfile,
} = projectSlice.actions;

export default projectSlice.reducer;
