import React, { useEffect, useState } from "react";
import classes from "./SmsTemplates.module.scss";
import searchIcn from "../../assets/Images/searchIcon.svg";
import StyledLoader from "../../components/Styledloader/StyledLoader";
import DataTable from "react-data-table-component";
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import PreviewIcon from "@mui/icons-material/Preview";
import {
  GetUserDetailsAPICall,
  smsGetTemplateIndividual,
  approveRejectSenderIdTemplateId,
  smsGetTemplate,
} from "../../Utils/Apihelper";
import * as moment from "moment-timezone";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DoneIcon from "@mui/icons-material/Done";
import toast, { Toaster } from "react-hot-toast";
import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/material/Box";

interface RowData {
  senderId: string,
  dltTemplateId: string;
  companyName: string;
  templateName: string;
  template: string;
  templateCategory: string;
  templateType: string;
  status: string;
  createdAt: string;
  modifiedAt: string;
}

const SmsDashboard = () => {
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [tableData, setTableData] = useState<any[]>([]);
  const [userName, setuserName] = useState<any>();
  const [data, setdata] = useState<any[]>([]);
  const [opened, setopened] = useState(false);
  const [selectedRow, setSelectedRow] = useState<RowData | null>(null);
  const [rejectionMessage, setRejectionMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [rejectOpen, setrejectOpen] = useState(false);

  useEffect(() => {
    templateDetails();
    userDetails();
  }, []);

  const handleRowClick = (row: any) => {
    console.log("Clicked row:", row);
    sessionStorage.setItem("companyName", row.companyName);
    sessionStorage.setItem("userName", row.userName);
    navigate("/dashboard/sms-clients/smsclientsdetails");
  };

  const handleClickOpen = (row: any) => {
    setSelectedRow(row);
    setopened(true);
  };

  const handleClosed = () => {
    setopened(false);
  };

  const customStyles = {
    table: {
      style: {
        borderRadius: "8px",
        "&:nth-last-child(1)": {
          borderBottom: "none !important",
        },
      },
    },
    headCells: {
      style: {
        paddingLeft: "16px",
        paddingRight: "10px",
        fontWeight: "600",
      },
    },
    headRow: {
      style: {
        backgroundColor: "#F7F9FC",
        borderBottom: "1px solid #9AAAEA ",
      },
    },
    header: {
      style: {
        fontSize: "16px",
        minHeight: "0px",
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingTop: "16px",
        paddingBottom: "16px",
        wordBreak: "break-word",
      },
    },
    rows: {
      style: {
        borderBottom: "1px solid #9AAAEA !important",
        "&:hover": {
          backgroundColor: "#E7F0FF",
        },
        "&:nth-last-child(1)": {
          borderBottom: "none !important",
        },
      },
    },
    contextMenu: {
      style: {
        fontSize: "13px",
        fontWeight: 400,
        paddingLeft: "16px",
        paddingRight: "8px",
        transform: "translate3d(0, -100%, 0)",
        transitionDuration: "125ms",
        transitionTimingFunction: "cubic-bezier(0, 0, 0.2, 1)",
        willChange: "transform",
      },
      activeStyle: {
        transform: "translate3d(0, 0, 0)",
      },
    },
    fixedColumn: {
      style: {
        position: 'sticky', 
        right: 0, 
        backgroundColor: '#FFF', 
        zIndex: 1, 
      },
    },
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setAnchorEl(event.currentTarget);
    sessionStorage.setItem("TableRow", row.dltId);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const userDetails = () => {
    GetUserDetailsAPICall()
      .then((res: any) => {
        setuserName(res.data.data.name);
        localStorage.setItem("userName", res.data.data.name);
      })
      .catch((err) => {
        console.error("Error", err);
      });
  };

  const name: any = localStorage.getItem("userName");

  const apprroveRejectSenderId = (senderId: any, status: any) => {
    const userName = sessionStorage.getItem("userName");
    const requestBody = {
      request_id: senderId,
      userName: userName,
      status: status,
      rejectionMessage: rejectionMessage,
      type: "templates",
    };
    approveRejectSenderIdTemplateId(requestBody)
      .then((res: any) => {
        // console.log(res);
        toast.success(res.data.data.message);
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err.response.data.error.message);
      });
  };

  // const apprroveRejectTemplateId = (dltTemplateId: any, status: any) => {
  //   const userName = sessionStorage.getItem("userName");
  //   const requestBody = {
  //     request_id: dltTemplateId,
  //     userName: userName,
  //     status: status,
  //     rejectionMessage: rejectionMessage,
  //     type: "templates",
  //   }
  //   approveRejectSenderIdTemplateId(requestBody)
  //   .then((res:any) => {
  //     console.log(res);
  //     templateIddetails();
  //     toast.success(res.data.data.message);
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //     toast.error(err.response.data.error.message);
  //   })
  // }

  
  // const templateIddetails = () => {
  //   const userName:any= sessionStorage.getItem("userName");
  //   smsGetTemplate(userName)
  //     .then((res:any) => {
  //       console.log(res);
  //       setData(res.data.data.history);
  //     })
  //     .catch((err) => {
  //       console.log(err)
  //     });
  // }; 

  const templateDetails = () => {
    smsGetTemplateIndividual()
      .then((res: any) => {
        console.log(res);
        setdata(res.data.data.history);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
  };

  const formatDate = (utcTime: any) => {
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localTime: any = moment.tz(utcTime, localTimeZone);
    const utcDate = new Date(localTime.format());
    const istDate = new Date(utcDate.getTime() + localTime._offset * 60 * 1000);

    const options: any = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };

    const formattedDate = istDate.toLocaleString("en-IN", options);

    return formattedDate.replace("am", "AM").replace("pm", "PM");
  };

  const columns: any = [
    {
      name: (
        <div style={{ fontSize: "13px", color: "black" }}>Sender ID</div>
      ),
      selector: "senderID",
      sortable: true,
      cell: (row: any) => (
        <div style={{ fontSize: "13px", color: "#5FC322" }}>
          {row?.senderId === "" ? "-" : row?.senderId}
        </div>
      ),
    },
    {
      name: (
        <div style={{ fontSize: "13px", color: "black", marginRight: "15px" }}>
          Company Name
        </div>
      ),
      selector: "companyName",
      sortable: true,
      cell: (row: any) => (
        <div style={{ fontSize: "13px" }} onClick={() => handleRowClick(row)}>{row?.companyName}</div>
      ),
    },
    {
      name: (
        <div style={{ fontSize: "13px", color: "black" }}>DLT Template Id</div>
      ),
      selector: "dltId",
      sortable: true,
      cell: (row: any) => (
        <div style={{ fontSize: "13px", color: "#5FC322" }}>
          {row?.dltTemplateId === "" ? "-" : row?.dltTemplateId}
        </div>
      ),
    },
    {
      name: (
        <div style={{ fontSize: "13px", color: "black", marginRight: "15px" }}>
          Template Name
        </div>
      ),
      selector: "templateName",
      sortable: true,
      cell: (row: any) => (
        <div style={{ fontSize: "13px" }}>{row?.templateName}</div>
      ),
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Template</div>,
      selector: "template",
      sortable: false,
      cell: (row: any) => (
        <div style={{ fontSize: "13px" }}>
          {truncateText(row?.template || "", 7)}
        </div>
      ),
    },
    {
      name: (
        <div style={{ fontSize: "13px", color: "black" }}>
          Template Category
        </div>
      ),
      selector: "templateCategory",
      sortable: false,
      cell: (row: any) => (
        <div style={{ fontSize: "13px" }}>
          {row?.templateCategory}
        </div>
      ),
    },
    {
      name: (
        <div style={{ fontSize: "13px", color: "black" }}>Template Type</div>
      ),
      selector: "templateType",
      sortable: false,
      cell: (row: any) => (
        <div style={{ fontSize: "13px" }}>
          {row?.templateType}
        </div>
      ),
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Created At</div>,
      selector: "createdAt",
      sortable: false,
      cell: (row: any) => (
        <div style={{ fontSize: "13px" }}>
          {row?.createdAt ? formatDate(row?.createdAt) : ""}
        </div>
      ),
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Modified At</div>,
      selector: "createdAt",
      sortable: false,
      cell: (row: any) => (
        <div style={{ fontSize: "13px" }}>
          {row?.modifiedAt ? formatDate(row?.modifiedAt) : ""}
        </div>
      ),
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Status</div>,
      selector: "status",
      sortable: true,
      cell: (row: any) => (
        <div
          style={{
            fontSize: "12px",
            textTransform: "uppercase",
            background:
            row.status === "approved" || row.status === "APPROVED"
            ? "#5FC322"
            : row.status === "pending" || row.status === "PENDING"
            ? "orange"
            : row.status === "INITIATED" || row.status === "initiated"
            ? "blue"
            : row.status === "rejected" || row.status === "REJECTED"
            ? "red"
            : "white",
            color: "white",
            padding: "0.5rem",
            minWidth: "85px",
            display: "flex",
            justifyContent: "center",
            borderRadius: "8px",
            fontWeight: "500",
          }}
        >
          {row?.status}
        </div>
      ),
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>View</div>,
      selector: (row: any) => row.view,
      sortable: false,
      cell: (row: any) => (
        <>
          <div style={{ fontSize: "13px" }}>
            <VisibilityIcon onClick={() => handleClickOpen(row)} />
          </div>
        </>
      ),
    },
  ];

  const filteredData = query.trim().length
    ? data.filter(
        (a: any) =>
          a.dltTemplateId.includes(query.trim()) ||
          a.templateName.toLowerCase().includes(query.toLowerCase().trim()) ||
          a.templateName.toUpperCase().includes(query.toUpperCase().trim()) ||
          a.companyName.toLowerCase().includes(query.toLowerCase().trim()) ||
          a.companyName.toUpperCase().includes(query.toUpperCase().trim()) || 
          a.senderId.toLowerCase().includes(query.toLowerCase().trim()) ||
          a.senderId.toUpperCase().includes(query.toUpperCase().trim())
      )
    : data;

    const truncateText = (text:any, wordLimit:any) => {
      const words = text.split(' ');
      if (words.length > wordLimit) {
        return words.slice(0, wordLimit).join(' ') + '........';
      }
      return text;
    };

    const handleRejectClose = () => {
      setRejectionMessage("");
      setErrorMessage("");
      setrejectOpen(false);
    };

    const handleRejectData = () => {
      if (rejectionMessage.trim() === "") {
        setErrorMessage("* rejection message is required");
      } else {
        setErrorMessage("");
        handleClose();
      }
    };

  return (
    <>
    <Toaster />
      <div className={classes.tabsWrapper}>
        <div className={classes.tableWrapper}>
          <div className={classes.emailListHeader}>
            <div
              style={{
                color: "#000",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
                letterSpacing: "-0.2px",
                display: "flex",
                minHeight: "3rem",
                alignItems: "center",
              }}
            >
              Manage Template ID
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "right",
              }}
            >
              <div className={classes.searchInput}>
                <div>
                  <img src={searchIcn} alt="" className={classes.searchIcon} />
                </div>
                <input
                  type="text"
                  value={query}
                  className={classes.inputBox}
                  placeholder="Search...."
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              border: "1px solid  #D9D9D9",
              borderRadius: "20px",
              paddingBottom: "20px",
              // overflowX: "scroll",
              margin: "0px 0px 0px 0px",
            }}
          >
            <DataTable
              data={filteredData}
              columns={columns}
              title=" "
              pagination={true}
              selectableRows={false}
              customStyles={customStyles}
              fixedHeader
              progressComponent={<StyledLoader />}
            />
          </div>
        </div>
      </div>
      <Dialog maxWidth="xl" open={opened} onClose={handleClosed}>
        <AppBar sx={{ position: "relative", backgroundColor: "#FF5151" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClosed}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              View
            </Typography>
          </Toolbar>
        </AppBar>
        <div
          className={classes.dialogstyles}
          style={{
            display: "flex",
            gap: "90px",
            margin: "40px 70px 0px 70px",
          }}
        >
          <div
            className={classes.dialogsection1}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "30px",
            }}
          >
            <div
              className={classes.pDetailsField}
              style={{
                display: "flex",
                gap: "30px",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  width: "110px",
                }}
              >
                {" "}
                Sender ID{" "}
              </span>
              <TextField
                placeholder="DLT ID"
                id="outlined-size-small"
                size="small"
                variant="standard"
                value={selectedRow?.senderId || ""}
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    height: "40px",
                    padding: "0px 10px",
                    borderRadius: "5px",
                  },
                }}
                sx={{
                  marginTop: "8px",
                  width: "-webkit-fill-available",
                  background: "#EDF2F7",
                  borderRadius: "5px",
                }}
              />
            </div>
            <div
              className={classes.pDetailsField}
              style={{
                display: "flex",
                gap: "30px",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  width: "110px",
                }}
              >
                {" "}
                DLT ID{" "}
              </span>
              <TextField
                placeholder="DLT ID"
                id="outlined-size-small"
                size="small"
                variant="standard"
                value={selectedRow?.dltTemplateId || ""}
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    height: "40px",
                    padding: "0px 10px",
                    borderRadius: "5px",
                  },
                }}
                sx={{
                  marginTop: "8px",
                  width: "-webkit-fill-available",
                  background: "#EDF2F7",
                  borderRadius: "5px",
                }}
              />
            </div>
            <div
              className={classes.pDetailsField}
              style={{
                display: "flex",
                gap: "30px",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  width: "110px",
                }}
              >
                Company Name
              </span>
              <TextField
                placeholder="Company Name"
                id="outlined-size-small"
                size="small"
                variant="standard"
                value={selectedRow?.companyName || ""}
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    height: "40px",
                    padding: "0px 10px",
                    borderRadius: "5px",
                  },
                }}
                sx={{
                  marginTop: "8px",
                  width: "-webkit-fill-available",
                  background: "#EDF2F7",
                  borderRadius: "5px",
                }}
              />
            </div>
            <div
              className={classes.pDetailsField}
              style={{
                display: "flex",
                gap: "30px",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  width: "110px",
                }}
              >
                {" "}
                Template Name{" "}
              </span>
              <TextField
                placeholder="Template Name"
                id="outlined-size-small"
                size="small"
                variant="standard"
                value={selectedRow?.templateName || ""}
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    height: "40px",
                    padding: "0px 10px",
                    borderRadius: "5px",
                  },
                }}
                sx={{
                  marginTop: "8px",
                  width: "-webkit-fill-available",
                  background: "#EDF2F7",
                  borderRadius: "5px",
                }}
              />
            </div>
            <div
              className={classes.pDetailsField}
              style={{
                display: "flex",
                gap: "30px",
                alignItems: "start",
              }}
            >
              <span
                style={{
                  width: "110px",
                  marginTop: "10px",
                }}
              >
                Template{" "}
              </span>
              <TextField
                placeholder="Template"
                id="outlined-size-small"
                size="small"
                variant="standard"
                value={selectedRow?.template || ""}
                multiline
                rows={4}
                maxRows={10}
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    height: "fit-content",
                    padding: "5px 10px",
                    borderRadius: "5px",
                  },
                }}
                sx={{
                  marginTop: "8px",
                  width: "-webkit-fill-available",
                  background: "#EDF2F7",
                  borderRadius: "5px",
                }}
              />
            </div>
          </div>
          <div
            className={classes.dialogsection2}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "30px",
              justifyContent: "start",
            }}
          >
            <div
              className={classes.pDetailsField}
              style={{
                display: "flex",
                gap: "30px",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  width: "110px",
                }}
              >
                Template Category{" "}
              </span>
              <TextField
                placeholder="Company Name"
                id="outlined-size-small"
                size="small"
                variant="standard"
                value={selectedRow?.templateCategory || ""}
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    height: "40px",
                    padding: "0px 10px",
                    borderRadius: "5px",
                  },
                }}
                sx={{
                  marginTop: "8px",
                  width: "-webkit-fill-available",
                  background: "#EDF2F7",
                  borderRadius: "5px",
                }}
              />
            </div>
            <div
              className={classes.pDetailsField}
              style={{
                display: "flex",
                gap: "30px",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  width: "110px",
                }}
              >
                {" "}
                Template Type{" "}
              </span>
              <TextField
                placeholder="Template Type"
                id="outlined-size-small"
                size="small"
                variant="standard"
                value={selectedRow?.templateType || ""}
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    height: "40px",
                    padding: "0px 10px",
                    borderRadius: "5px",
                  },
                }}
                sx={{
                  marginTop: "8px",
                  width: "-webkit-fill-available",
                  background: "#EDF2F7",
                  borderRadius: "5px",
                }}
              />
            </div>
            <div
              className={classes.pDetailsField}
              style={{
                display: "flex",
                gap: "30px",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  width: "110px",
                }}
              >
                Created At{" "}
              </span>
              <TextField
                placeholder="Created At"
                id="outlined-size-small"
                size="small"
                variant="standard"
                value={formatDate(selectedRow?.createdAt) || ""}
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    height: "40px",
                    padding: "0px 10px",
                    borderRadius: "5px",
                  },
                }}
                sx={{
                  marginTop: "8px",
                  width: "-webkit-fill-available",
                  background: "#EDF2F7",
                  borderRadius: "5px",
                }}
              />
            </div>
            <div
              className={classes.pDetailsField}
              style={{
                display: "flex",
                gap: "30px",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  width: "110px",
                }}
              >
                Modified At{" "}
              </span>
              <TextField
                placeholder="Modified At"
                id="outlined-size-small"
                size="small"
                variant="standard"
                value={formatDate(selectedRow?.modifiedAt) || ""}
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    height: "40px",
                    padding: "0px 10px",
                    borderRadius: "5px",
                  },
                }}
                sx={{
                  marginTop: "8px",
                  width: "-webkit-fill-available",
                  background: "#EDF2F7",
                  borderRadius: "5px",
                }}
              />
            </div>
            <div
              className={classes.pDetailsField}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  width: "110px",
                }}
              >
                Status{" "}
              </span>
              <div
                style={{
                  fontSize: "12px",
                  textTransform: "uppercase",
                  background:
                      selectedRow?.status === "approved" || selectedRow?.status === "APPROVED"
                    ? "#5FC322"
                    : selectedRow?.status === "pending" || selectedRow?.status === "PENDING"
                    ? "orange"
                    : selectedRow?.status === "INITIATED" || selectedRow?.status === "initiated"
                    ? "blue"
                    : selectedRow?.status === "rejected" || selectedRow?.status === "REJECTED"
                    ? "red"
                    : "white",
                  color: "white",
                  padding: "0.5rem",
                  minWidth: "85px",
                  width: "fit-content",
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: "8px",
                  fontWeight: "500",
                }}
              >
                {selectedRow?.status}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.approvereject} style={{
          display: "flex",
          justifyContent: "center",
          margin: "14px 0px 40px 0px"
        }}>
        <div className={classes.pbuttons} style={{
            display: "flex",
            gap: "10px",
          }}>
            {/* <div className={classes.pApprove}>
              <Button
                variant="contained"
                onClick={() => {
                  apprroveRejectSenderId(selectedRow?.senderId, "APPROVED");
                }}
              >
                Approve
              </Button>
            </div>
            <div className={classes.pReject}>
              <Button
                variant="outlined"
                onClick={() => {
                  setrejectOpen(true);
                }}
              >
                Reject
              </Button>
            </div> */}
            <Dialog
              open={rejectOpen}
              onClose={handleRejectClose}
              fullWidth
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              BackdropProps={{
                style: {
                  backgroundColor: "rgba(0, 0, 0, 0)",
                  backdropFilter: "blur(5px)",
                },
              }}
              PaperProps={{
                elevation: 0,
                style: {
                  boxShadow:
                    "0 1px 1px rgba(0,0,0,0.11), 0 2px 2px rgba(0,0,0,0.11), 0 4px 4px rgba(0,0,0,0.11), 0 8px 8px rgba(0,0,0,0.11), 0 16px 16px rgba(0,0,0,0.11), 0 32px 32px rgba(0,0,0,0.11)",
                },
              }}
            >
              <DialogTitle sx={{ fontSize: "15px" }}>
                Why are you rejecting?
              </DialogTitle>
              <DialogContent>
                <Box>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    variant="outlined"
                    value={rejectionMessage}
                    onChange={(e) => setRejectionMessage(e.target.value)}
                    sx={{
                      height: "70%",
                      "& .MuiInputBase-root": {
                        height: "100%",
                        alignItems: "flex-start",
                      },
                    }}
                  />
                </Box>
              </DialogContent>
              {errorMessage && (
                <Typography
                  color="error"
                  variant="body2"
                  sx={{ marginLeft: "30px" }}
                >
                  {errorMessage}
                </Typography>
              )}
              <DialogActions>
                <Button
                  onClick={() => {
                    handleRejectClose();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    handleRejectData();
                    apprroveRejectSenderId(selectedRow?.senderId, "REJECTED");
                    console.log(selectedRow?.senderId)
                  }}
                  sx={{ color: "red" }}
                >
                  Reject
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default SmsDashboard;
