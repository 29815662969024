import React, { useEffect, useState } from "react";
import styles from "./SmsSubscriptionDetails.module.scss"
import {
    GetCompanyDetailsAPI,
  GetCompanyEmailHistoryAPICall,
} from "../../Utils/Apihelper";
import CompanyDetails from "../SmsClientDetailsPages/CompanyDetails/CompanyDetails";
import  ApprovalsTab from "../SmsClientDetailsPages/ApprovalsTab/ApprovalsTab";
import Subscription from "../SmsClientDetailsPages/Subscription/Subscription";
import CreditHistory from  "../SmsClientDetailsPages/CreditHistory/CreditHistory";
import SMSDetails from "../SmsClientDetailsPages/SMSDetails/SMSDetails";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
// import CompanyDetails from "./CompanyDetails/CompanyDetails";
// import AWSDetails from "./AWSDetails/AWSDetails";
// import SubscripitionDetails from "./SubscripitionDetails/SubscripitionDetails";
// import MailHistoryDetails from "./MailHistoryDetails/MailHistoryDetails";
// import SubscripitionLogs from "./SubscripitionDetails/SubscripitionLogs";

export default function SmsSubscriptionDetails() {
//   const CompanyName: any = sessionStorage.getItem("companyName");

  const [tab, setTab] = useState(1);
  const [open, setOpen] = React.useState(false);
  const [data, setData] = useState(
//     {
//     companyDetails: {
//       companyName: "MO",
//       companyLogoURL: "",
//     },
//     userName: "Prajwal",
//     contactNumber: "9483081313",
//     email: "prajwal.s1321997@gmail.com",
//     databaseName: "prajwal42",
//     userType: "Admin",
//     createdAt: "2024-01-20T12:15:42.081000",
//     modifiedAt: "2024-01-20T12:15:42.081000",
//     verifiedEmails: [],
//     awsCredentials: {
//       "client-id": "",
//       clientSecret: "",
//       region: "",
//       connection: false,
//       configuration: false,
//     },
//     verification: true,
//     subscription: {
//       subscriptionStatus: true,
//       subscriptionType: "Basic",
//       subscriptionExpiry: "",
//       subscriptionHistory: [],
//     },
//     assignedUser: "Umapathi",
//   }
  {
    companyDetails: {
        companyName: "MindoriginTesting",
        companyLogoURL: "",
    },
    userName: "Chethan",
    contactNumber: "9980347327",
    email: "chethan.m@mindorigin.in",
    address: "blr",
    createdAt: "2024-08-28T11:49:34.415000",
    subscription: {
        products: {
            sms: {
                subscriptionStatus: false,
                subscriptionType: "Basic",
                subscriptionExpiry: "",
                subscriptionHistory: []
            }
        }
    },
    smsDetails: {},
    rejectionMessage: "",
}
);

const [rejectErrorMessage, setrejectErrorMessage] =useState<any>();

  useEffect(() => {
    clientsDetails();
    // let companyname = CompanyName;
    // GetCompanyDetailsAPICall(companyname)
    //   .then((response: any) => {
    //     console.log("response", response.data.data);
    //     if (response.data?.data) {
    //       setData(response.data.data);
    //     }
    //   })
    //   .catch((error: any) => {
    //     console.log("error", error);
    //   });
   
  }, []);

  const userName= sessionStorage.getItem("userName");

  const clientsDetails = () => {
    const requestBody:any = {
        userName: userName,
    }
    GetCompanyDetailsAPI(requestBody)
    .then((res: any) => {
    // console.log(res);
    setData(res.data.data);
    setrejectErrorMessage(res.data.data.rejectionMessage);
    sessionStorage.setItem("rejectionMessage", res.data.data.rejectionMessage);
  })
  .catch((err) => {
    console.log(err);
  })
  }

  const handleClose = () => {
    setOpen(false);
  };

console.log(data);

  function activeTab(tabNumber: any) {
    console.log(tabNumber);
    setTab(tabNumber);
  }

  return (
   
    <div className={styles.mainWrapper}>
      <div className={styles.tabsWrapper}>
        <div className={styles.tabsCatagory}>
          <div
            className={styles.tabsItem}
            onClick={() => {
              activeTab(1);
            }}
            style={{
              backgroundColor: tab === 1 ? "#4C6FFF" : "",
              color: tab == 1 ? "white" : "",
            }}
          >
            Company Details
          </div>
          <div
            className={styles.tabsItem}
            onClick={() => {
              activeTab(2);
            }}
            style={{
              backgroundColor: tab === 2 ? "#4C6FFF" : "",
              color: tab == 2 ? "white" : "",
            }}
          >
            SMS Details
          </div>
          <div
            className={styles.tabsItem}
            onClick={() => {
              activeTab(3);
            }}
            style={{
              backgroundColor: tab === 3 ? "#4C6FFF" : "",
              color: tab == 3 ? "white" : "",
            }}
          >
            Subscription
          </div>
          <div
            className={styles.tabsItem}
            onClick={() => {
              activeTab(4);
            }}
            style={{
              backgroundColor: tab === 4 ? "#4C6FFF" : "",
              color: tab == 4 ? "white" : "",
            }}
          >
            Credit History
          </div>
          <div
            className={styles.tabsItem}
            onClick={() => {
              activeTab(5);
            }}
            style={{
              backgroundColor: tab === 5 ? "#4C6FFF" : "",
              color: tab == 5 ? "white" : "",
            }}
          >
            Approvals Tab
          </div>
        </div>
      </div>
      <p className={styles.companyname} style={{paddingBottom: "10px"}}>{data.companyDetails.companyName}</p>
            {rejectErrorMessage &&
            <div className={styles.rejectionMessage} style={{
              marginLeft: "10px",
              width: "fit-content",
            }}>
             <Stack sx={{ width: '100%' }} spacing={2}>
      <Alert severity="error">{rejectErrorMessage.toUpperCase()}</Alert>
    </Stack>
    </div>
    }
      <div className={styles.contentWrapper} style={{
        marginTop: "14px",
      }}>
        {tab === 1 && <CompanyDetails data={data} />}
        {tab === 2 && <SMSDetails data={data} />}
        {tab === 3 && <Subscription data={data} />}
        {tab === 4 && <CreditHistory data={data} />}
        {tab === 5 && <ApprovalsTab data={data} />}
      </div>
    </div>
  );
}




