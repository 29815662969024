import React, {useState, useEffect} from 'react'
import classes from './SmsCreditsPreview.module.scss'
import { GetUserDetailsAPICall,smsGetallCredits } from '../../Utils/Apihelper';
import * as moment from 'moment-timezone';
import { useLocation } from 'react-router-dom';



const SmsPreview = () => {

    const location = useLocation();
    const [rowData, setRowData] = useState<any>({});
  
    useEffect(() => {
      // Extract row data from location state
      const data = location.state?.row || {};
      setRowData(data);
    }, [location.state]);
  
    const formatDate = (utcTime: any) => {
      const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const localTime = moment.tz(utcTime, localTimeZone);
      const istDate = new Date(localTime.format());
      const options: any = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      };
      return istDate.toLocaleString("en-IN", options);
    };
  
    return (
        <>
      <div className={classes.datawrapper}>
        <div className={classes.dataheader}>Credits Details</div>
        <div className={classes.databody}>
          <div className={classes.singledata}>
            <div className={classes.dataleft}>Request ID:</div>
            <div className={classes.dataright}>{rowData?.requestId || "-"}</div>
          </div>
          <div className={classes.singledata}>
            <div className={classes.dataleft}>Transactional Count:</div>
            <div className={classes.dataright}>{rowData?.transactionalCount || "-"}</div>
          </div>
          <div className={classes.singledata}>
            <div className={classes.dataleft}>Promotional Count:</div>
            <div className={classes.dataright}>{rowData?.promotionalCount || "-"}</div>
          </div>
          <div className={classes.singledata}>
            <div className={classes.dataleft}>Total Count:</div>
            <div className={classes.dataright}>{rowData?.totalCount || "-"}</div>
          </div>
          <div className={classes.singledata}>
            <div className={classes.dataleft}>Per Sms Charges:</div>
            <div className={classes.dataright}>{rowData?.perSMSCharges || "-"}</div>
          </div>
          <div className={classes.singledata}>
            <div className={classes.dataleft}>Date:</div>
            <div className={classes.dataright}>{formatDate(rowData?.date) || "-"}</div>
          </div>
          <div className={classes.singledata}>
            <div className={classes.dataleft}>Status:</div>
            <div className={classes.dataright}>{rowData?.status || "-"}</div>
          </div>
        </div>
      </div>
  
    
    </>
  )
}

export default SmsPreview