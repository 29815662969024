import { Stack } from "@mui/material";
import { Button } from "@mui/material";
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import LogoutIcon from "@mui/icons-material/Logout";
import StyledLoader from "../../../components/Styledloader/StyledLoader";

const titletextStyle = {
  fontSize: "14px",
  fontWeight: "bold",
  color: "#2D3748",
};
const contenttextStyle = { fontSize: "14px" };

const data = [
  {
    name: "NFO Collection",
    downloadLink: "https://openapidev.thefirstock.tech/NFOSymbolDownload",
  },
  {
    name: "Index Symbol Collection",
    downloadLink: "https://openapidev.thefirstock.tech/indexSymbols/",
  },
  {
    name: "NSE-EQ Collection",
    downloadLink: "https://openapidev.thefirstock.tech/NSESymbolDownload",
  },
  {
    name: "BSE-EQ Collection",
    downloadLink: "https://openapidev.thefirstock.tech/BSESymbolDownload",
  },
  {
    name: "BFO Symbol Collection",
    downloadLink: "https://openapidev.thefirstock.tech/BFOSymbolDownload",
  },
];

export default function GenerateKey() {
  const [loading, setloading] = useState(false);

  const columnsData: any = [
    {
      name: <b style={titletextStyle}>Sr No</b>,
      selector: "srno",
      sortable: true,
      width: "100px",
      cell: (row: any, index: any) => (
        <b style={contenttextStyle}>{index + 1}</b>
      ),
    },
    {
      name: <b style={titletextStyle}>Name</b>,
      selector: "name",
      sortable: true,

      cell: (row: any) => <b style={contenttextStyle}>{row.name}</b>,
    },

    {
      name: <b style={titletextStyle}>Actions</b>,
      selector: "spamReportRate",
      sortable: false,

      button: true,
      width: "200px",
      cell: (row: any) => (
        <b style={contenttextStyle}>
          <Stack direction={"row"}>
            <Button
              variant="outlined"
              size="small"
              sx={{
                height: "30px",
                margin: "15px 5px",
                color: "#0F91D2",
                borderColor: "#0F91D2",
                textTransform: "none",
                "&:hover": {
                  background: "#57B2E0",
                  borderColor: "#57B2E0",
                  color: "white",
                },
                padding: "10px 20px",
              }}
              onClick={() => {}}
            >
              Generate Key
            </Button>
          </Stack>
        </b>
      ),
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#C4F2EE",
      },
    },

    headRow: {
      style: {
        borderBottom: "none",
      },
    },
    header: {
      style: {
        fontSize: "16px",

        minHeight: "0px",
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    contextMenu: {
      style: {
        fontSize: "14px",
        fontWeight: 400,
        paddingLeft: "16px",
        paddingRight: "8px",
        transform: "translate3d(0, -100%, 0)",
        transitionDuration: "125ms",
        transitionTimingFunction: "cubic-bezier(0, 0, 0.2, 1)",
        willChange: "transform",
      },
      activeStyle: {
        transform: "translate3d(0, 0, 0)",
      },
    },
    // rows: {
    //   style: {
    //     fontSize: "13px",
    //     fontWeight: 400,
    //     height: "60px",
    //     minHeight: "unset",
    //     "&:not(:last-of-type)": {
    //       borderBottomStyle: "solid",
    //       borderBottomWidth: "1px",
    //     },
    //   },
    // },
  };
  return (
    <div>
      <DataTable
        progressPending={loading}
        data={data}
        columns={columnsData}
        customStyles={customStyles}
        progressComponent={<StyledLoader />}
        // fixedHeader
        actions
        paginationServer={true}
      />
    </div>
  );
}
