import React, { useEffect, useState } from "react";
import classes from "./AWSDetails.module.scss";
import { Button, IconButton, Stack, TextField } from "@mui/material";
import verifiedtrue from "../../../assets/Images/verifiedtrue.svg";
import verifiedfalse from "../../../assets/Images/verifiedfalse.svg";
import { GetAWSSettingsAPICall } from "../../../Utils/Apihelper";
import DomainRecordsModal from "./DomainRecordsModal";

export default function AWSDetails({ data }: any) {
  const CompanyName: any = sessionStorage.getItem("companyName");
  const [awsData, setAwsData] = useState<any>();
  const [openDomainRecordsModal, setopenDomainRecordsModal] = useState(false);

  useEffect(() => {
    GetAWSSettingsAPICall(CompanyName)
      .then((response: any) => {
        // console.log("response", response.data.data);
        if (response.data?.data) {
          setAwsData(response.data.data);
        }
      })
      .catch((error: any) => {});
  }, []);

  const handleCloseRecordsModal = () => {
    setopenDomainRecordsModal(false);
  };
  return (
    <div>
      <div className={classes.pRight}>
        {awsData?.domainRecords[0]?.records?.domainRecords.length>0 && (
          <Stack sx={{ position: "absolute", right: "10px", top: "10px" }}>
            <Button
              size="small"
              variant="contained"
              sx={{ textTransform: "none" }}
              onClick={() => {
                setopenDomainRecordsModal(true);
              }}
            >
              View Domain Records{" "}
            </Button>
          </Stack>
        )}
        <div className={classes.pDetailsField}>
          Domain
          <TextField
            placeholder="Domain"
            id="outlined-size-small"
            size="small"
            variant="standard"
            value={
              awsData?.domainRecords[0]?.domain
                ? awsData?.domainRecords[0]?.domain
                : "-"
            }
            //   onChange={handleEmailName}
            InputProps={{
              disableUnderline: true,
              sx: {
                height: "40px",
                padding: "0px 10px",
                borderRadius: "5px",
              },
            }}
            sx={{
              marginTop: "8px",
              width: "-webkit-fill-available",
              background: "#EDF2F7",
              borderRadius: "5px",
              "& label.Mui-focused": {
                color: "grey",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#EDF2F7",
              },
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#EDF2F7",
                },
              },
            }}
          />
        </div>
        <div className={classes.pDetailsField}>
          Client Id
          <TextField
            placeholder="Username"
            id="outlined-size-small"
            size="small"
            variant="standard"
            value={
              data?.awsCredentials
                ? data.awsCredentials?.data?.awsCredentials["client-id"] === ""
                  ? "-"
                  : data.awsCredentials["client-id"]
                : "-"
            }
            //   onChange={handleEmailName}
            InputProps={{
              disableUnderline: true,
              sx: {
                height: "40px",
                padding: "0px 10px",
                borderRadius: "5px",
              },
            }}
            sx={{
              marginTop: "8px",
              width: "-webkit-fill-available",
              background: "#EDF2F7",
              borderRadius: "5px",
              "& label.Mui-focused": {
                color: "grey",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#EDF2F7",
              },
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#EDF2F7",
                },
              },
            }}
          />
        </div>
        <div className={classes.pDetailsField}>
          Client Secret
          <TextField
            placeholder="Phone Number"
            id="outlined-size-small"
            size="small"
            variant="standard"
            value={
              data.awsCredentials
                ? data.awsCredentials?.clientSecret === ""
                  ? "-"
                  : data.awsCredentials?.clientSecret
                : "-"
            }
            InputProps={{
              disableUnderline: true,
              sx: {
                height: "40px",
                padding: "0px 10px",
                borderRadius: "5px",
              },
            }}
            sx={{
              marginTop: "8px",
              width: "-webkit-fill-available",
              background: "#EDF2F7",
              borderRadius: "5px",
              "& label.Mui-focused": {
                color: "grey",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#EDF2F7",
              },
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#EDF2F7",
                },
              },
            }}
          />
        </div>
        <div className={classes.pDetailsField}>
          Region
          <TextField
            placeholder="Email"
            id="outlined-size-small"
            size="small"
            variant="standard"
            value={
              data.awsCredentials
                ? data.awsCredentials?.region === ""
                  ? "-"
                  : data.awsCredentials?.region
                : "-"
            }
            InputProps={{
              disableUnderline: true,
              sx: {
                height: "40px",
                padding: "0px 10px",
                borderRadius: "5px",
              },
            }}
            sx={{
              marginTop: "8px",
              width: "-webkit-fill-available",
              background: "#EDF2F7",
              borderRadius: "5px",
              "& label.Mui-focused": {
                color: "grey",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#EDF2F7",
              },
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#EDF2F7",
                },
              },
            }}
          />
        </div>
        <div style={{ display: "flex", gap: "20px", marginTop: "20px" }}>
          <div className={classes.pDetailsFieldConnection}>
            <p style={{ color: "grey" }}>Connection :</p>
            <img
              src={
                data.awsCredentials?.connection ? verifiedtrue : verifiedfalse
              }
              style={{ width: "25px" }}
            />
          </div>
          <div className={classes.pDetailsFieldConnection}>
            <p style={{ color: "grey" }}>Configuration :</p>
            <img
              src={
                data.awsCredentials?.configuration
                  ? verifiedtrue
                  : verifiedfalse
              }
              style={{ width: "25px" }}
            />
          </div>
        </div>
      </div>
      {openDomainRecordsModal && (
        <DomainRecordsModal
          openDomainRecordsModal={openDomainRecordsModal}
          handleCloseRecordsModal={handleCloseRecordsModal}
          styles={classes}
          domainrecordsList={
            awsData?.domainRecords[0]?.records?.domainRecords
              ? awsData?.domainRecords[0]?.records.domainRecords
              : []
          }
        />
      )}
    </div>
  );
}
