import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Autocomplete, TextField } from "@mui/material";

interface props {
  data: any;
  userName: any;
  setuserName: any;
}

export default function AutoCompleteUser({
  data,
  userName,
  setuserName,
}: props) {
  return (
    <div>
      <Autocomplete
        size="small"
        // noOptionsText={"No Records Found"}
        options={data.map((a: any) => a)}
        getOptionLabel={(option) => option.name}
        sx={{
          marginTop: "12px",
          borderRadius: "10px",
          width: "100%",
          background: "#EDF2F7",
          outline: "none",
          fontSize: "16px",
          fontWeight: 500,
          padding: "0 10px",
          color: "#000000",
          boxShadow: "none",
          "& .MuiOutlinedInput-input": {
            // padding: "4.5px 4px 2.5px 8px",
            paddingTop: "2px !important",
            cursor: "pointer",
            border: 0,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
        }}
        id="disable-clearable"
        popupIcon={
          <KeyboardArrowDownIcon
            style={{ cursor: "pointer" }}
            fontSize="small"
          />
        }
        value={userName}
        onChange={(event, newValue) => {
          setuserName(newValue);
        }}
        disableClearable
        renderInput={(params) => (
          <TextField {...params} placeholder="Select Users" label="Users" />
        )}
      />
    </div>
  );
}
