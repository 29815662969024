import { createTheme } from "@mui/material/styles";
import { hover } from "@testing-library/user-event/dist/hover";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#FF5151",
      dark: "#FF5151",
    },
    secondary: {
      main: "#FF5151",
    },
    error: {
      main: "#ef5350",
    },
  },
  shape: {
    borderRadius: 5,
  },
  typography: {
    button: {
      // textTransform: "none",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // color: "white",
          fontWeight: 400,
          // height:'45px'
        },
        contained: {
          text: {
            color: "white",
          },
        },
        outlined: {
          text: {
            color: "#FF5151",
          },
        },

        text: {
          color: "#3f50b5",
        },
      },
    },
   
    MuiIconButton: {
      // Use MuiIconButton for styling IconButton
      styleOverrides: {
        root: {
          fontSize: 20,
          color: "#A0AEC0",
          
        },
      },
    },
    // MuiSvgIcon:{
    //   styleOverrides: {
    //     root: {
    //      fill:'#A0AEC0',
       
    //     },
    //   },
    // },

    MuiPaginationItem: {
      styleOverrides: {
        root: {
          fontSize: 16,
          color: "#FF5151",
          "&.Mui-selected": {
            backgroundColor: "#FF5151",
            color: "white",
          },
          "&:hover": {
            backgroundColor: "#FF5151",
            color: "white",
          },

        },
      },
    },

    

    MuiButtonBase: {
      styleOverrides: {
        root: {},
      },
    },
  },
});
