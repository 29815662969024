import React from "react";
import classes from "./Topbar.module.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCollapsed } from "../../../Store/Project/ProjectSlice";
import { Avatar, IconButton, Tooltip } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import RoutesLabelJson from "../../../Utils/RoutesLabel.json";
import LogoutIcon from "@mui/icons-material/Logout";
import { confirmAlert } from "react-confirm-alert";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";

const Topbar = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const collapsed = useSelector((state: any) => state.project.collapsed);
  const dashboardScreen = useSelector((state: any) => state.project.dashboard);
  const labels: any = RoutesLabelJson;
  const pathName: string = useLocation().pathname;
  const title: string = labels[pathName];

  console.log("pathName", pathName);

  const handleLogout = () => {
    confirmAlert({
      title: "",
      message: "Are you sure you want to Logout?",
      buttons: [
        {
          label: "No",
          onClick: () => "",
        },
        {
          label: "Yes",
          onClick: () => {
            sessionStorage.clear();
            navigate("/");
          },
        },
      ],
    });
  };

  return (
    <>
      <div className={classes.topbarWrapper}>
        <div className={classes.container}>
          <div className={classes.dashTitle}>
            {/* <div
              onClick={() => dispatch(setCollapsed(!collapsed))}
              className={classes.collapse}
            >
              {collapsed ? (
                <ArrowForwardIosIcon fontSize="small" color="action" />
              ) : (
                <ArrowBackIosIcon fontSize="small" color="action" />
              )}
            </div> */}
            <div className={classes.title}>
              {pathName === "/dashboard/templates/emailTemplateEditor" && (
                <p
                  className={classes.backNavigation}
                  onClick={() => {
                    navigate("/dashboard/templates");
                  }}
                >
                  Templates
                </p>
              )}
              {pathName === "/dashboard/clients/clientDetails" && (
                <p
                  className={classes.backNavigation}
                  onClick={() => {
                    navigate("/dashboard/clients");
                  }}
                >
                  Clients
                </p>
              )}

              <p style={{display:'flex',alignItems:'center', margin:'0'}}>
                <ArrowRightRoundedIcon sx={{fill:"#1976d2"}} />
              </p>
              <p>{title}</p>
            </div>
            <div className={classes.logoutSection}>
              <Tooltip title="LogOut">
                <IconButton
                  onClick={() => {
                    handleLogout();
                  }}
                >
                  <LogoutIcon sx={{ fontSize: "20px" }} />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Topbar;
