import React, {useState, useEffect} from 'react'
import classes from './SmsPreview.module.scss';
import { smsGetallClients } from '../../Utils/Apihelper';
import * as moment from 'moment-timezone';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";

const SmsPreview = () => {
  const [tableData, setTableData] = useState<any>({});
  const [open, setOpen] = React.useState(false);
  const [rejectionMessage, setRejectionMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleClose = () => {
    setRejectionMessage('');
    setErrorMessage('');
    setOpen(false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRejectionMessage(event.target.value);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    allClientsData();
  }, []);

  const allClientsData = () => {
    const requestBody = {
      userName: "Chandan", 
    };

    smsGetallClients(requestBody)
      .then((res: any) => {
        console.log(res.data);
        setTableData(res.data.data);
        localStorage.setItem("requestId", res.data.data.history);
      })
      .catch((err) => {
        console.error("Error fetching client data:", err);
      });
  };

  const formatDate = (utcTime: any) => {
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localTime: any = moment.tz(utcTime, localTimeZone);
    const utcDate = new Date(localTime.format());
    const off = new Date().getTimezoneOffset();
    const istDate = new Date(utcDate.getTime() + localTime._offset * 60 * 1000);
    const options: any = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };
    return istDate.toLocaleString("en-IN", options);
  };

  const handleRejectData = () => {
    if (rejectionMessage.trim() === '') {
      setErrorMessage('* rejection message is required');
    } else {
      setErrorMessage('');
      handleClose();
    }
  };



  

  return (
    <>
    
    <div className={classes.datawrapper}>
      <div className={classes.dataheader}>Details</div>
      <div className={classes.databody}>
      <div className={classes.buttons}>
      <Button variant="outlined" sx={{color: 'green'}}>Approve</Button>
      <Button variant="outlined" onClick={() => {
        handleClickOpen();
      }} sx={{color: 'red'}}>Reject</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0)', 
            backdropFilter: 'blur(5px)',
          },
        }}
        PaperProps={{
          elevation: 0,
          style: {
            boxShadow: '0 1px 1px rgba(0,0,0,0.11), 0 2px 2px rgba(0,0,0,0.11), 0 4px 4px rgba(0,0,0,0.11), 0 8px 8px rgba(0,0,0,0.11), 0 16px 16px rgba(0,0,0,0.11), 0 32px 32px rgba(0,0,0,0.11)',
          },
        }}
        
      >
        <DialogTitle sx={{fontSize: '15px'}}>Why are you rejecting this credits?</DialogTitle>
              <DialogContent>
                <Box>
              <TextField
              fullWidth
              multiline
              rows={4} 
              variant="outlined"
              value={rejectionMessage}
              onChange={(e) => setRejectionMessage(e.target.value)}
              sx={{
                height: '70%', 
                '& .MuiInputBase-root': {
                  height: '100%', 
                  alignItems: 'flex-start', 
                },
              }}
            />
          </Box>
              </DialogContent>
              {errorMessage && (
          <Typography color="error" variant="body2" sx={{  marginLeft: '30px' }}>
            {errorMessage}
          </Typography>
        )}
              <DialogActions>
                <Button onClick={() => {
                  handleClose();
                }}>Cancel</Button>
                <Button
                  onClick={() => {
                    handleRejectData();
                   
                  }}

                  sx={{color: 'red'}}
                >
                  Reject
                </Button>
              </DialogActions>
      </Dialog>
      </div>
        <div className={classes.singledata}>
        <div className={classes.dataleft}>DLT ID:</div>
        <div className={classes.dataright}>{tableData?.smsDetails?.dltId}</div>
        </div>
        <div className={classes.singledata}>
        <div className={classes.dataleft}>Username:</div>
        <div className={classes.dataright}>{tableData?.userName}</div>
        </div>
        <div className={classes.singledata}>
        <div className={classes.dataleft}>Contact No:</div>
        <div className={classes.dataright}>{tableData?.contactNumber}</div>
        </div>
        <div className={classes.singledata}>
        <div className={classes.dataleft}>E-mail:</div>
        <div className={classes.dataright}>{tableData?.email}</div>
        </div>
        <div className={classes.singledata}>
        <div className={classes.dataleft}>Created At:</div>
        <div className={classes.dataright}>{formatDate(tableData?.createdAt)}</div>
        </div>
        <div className={classes.singledata}>
        <div className={classes.dataleft}>Company Name:</div>
        <div className={classes.dataright}>{tableData?.companyDetails?.companyName}</div>
        </div>
        <div className={classes.singledata}>
        <div className={classes.dataleft}>Address:</div>
        <div className={classes.dataright}>{tableData?.address}</div>
        </div>
        <div className={classes.singledata}>
        <div className={classes.dataleft}>Subscription Status:</div>
        <div className={classes.dataright}>{tableData?.subscription?.products?.sms?.subscriptionStatus ? "True" : "False"}</div>
        </div>
        <div className={classes.singledata}>
        <div className={classes.dataleft}>Subscription Type:</div>
        <div className={classes.dataright}>{tableData?.subscription?.products?.sms?.subscriptionType}</div>
        </div>
        <div className={classes.singledata}>
        <div className={classes.dataleft}>Staus:</div>
        <div className={classes.dataright}>{tableData?.smsDetails?.status.charAt(0).toUpperCase() + tableData?.smsDetails?.status.slice(1).toLowerCase()}</div>
        </div>
       
      </div>
      
    </div>
  
    
    </>
  )
}

export default SmsPreview