import React, { useEffect, useState } from "react";
import classes from "./MailHistoryDetails.module.scss";
import { TextField } from "@mui/material";
import verifiedtrue from "../../../assets/Images/verifiedtrue.svg";
import verifiedfalse from "../../../assets/Images/verifiedfalse.svg";
import { GetCompanyEmailHistoryAPICall } from "../../../Utils/Apihelper";

export default function MailHistoryDetails() {
  const CompanyName: any = sessionStorage.getItem("companyName");



  const [data, setData] = useState<any>([]);

  useEffect(() => {
    GetCompanyEmailHistoryAPICall(CompanyName)
      .then((response: any) => {
        console.log("GetCompanyEmailHistoryAPICall", response.data.data);
        setData(response?.data?.data ? response.data.data : []);
      })
      .catch((error: any) => {
        console.log("error", error);
      });
  }, []);
  return (
    <div>
      <div className={classes.pRight}>
        <div className={classes.verifySectionRight}>
          <div className={classes.headSection}>
            <p className={classes.verifyText}>Mail History</p>
          </div>
          <div className={classes.emailssection}>
            {data.length > 0 &&
              data.map((a: any) => (
                <div className={classes.userDetailsSection}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div className={classes.userProfile}>
              
                    </div>
                    <div className={classes.userDetailsSectionContent}>
             
                    </div>
                  </div>
                </div>
              ))}
            {data.length === 0 && (
              <div className={classes.nodataText}>No History Found</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
