import "./App.scss";
import Router from "./components/Router/Router";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { theme } from "./components/ThemeProvider/ThemeProvider";

function App() {
  return (
  
      <div className="app">
        <Router />
      </div>
  
  );
}

export default App;
