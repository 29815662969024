import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  IconButton,
  InputAdornment,
  Slide,
  Stack,
  TextField,
  ThemeProvider,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  AddUserAPICall,
  DeleteUserAPICall,
  EditUserAPICall,
  GetUserDetailsAPICall,
  GetUsersAPICall,
} from "../../Utils/Apihelper";
import UserFormModalpopup from "../../components/Modals/UserFormModalpopup";
import { IADDUser, IDeleteUser, IEditUser } from "../../Utils/dtos";
import DataTable from "react-data-table-component";
import StyledLoader from "../../components/Styledloader/StyledLoader";
import { theme } from "../../components/ThemeProvider/ThemeProvider";
import toast, { Toaster } from "react-hot-toast";
import classes from "./Users.module.scss";
import { confirmAlert } from "react-confirm-alert";
import { TransitionProps } from "@mui/material/transitions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneIcon from "@mui/icons-material/Done";

const titletextStyle = {
  fontSize: "14px",
  fontWeight: "bold",
  color: "#8C8B8F",
};
const contenttextStyle = { fontSize: "14px" };

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Users = () => {
  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F3F6FA",
        // display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
      },
    },

    headRow: {
      style: {
        borderBottom: "none",
        marginBottom: "10px",
      },
    },
    header: {
      style: {
        fontSize: "16px",
        minHeight: "38px",
        paddingLeft: "5px",
        paddingRight: "5px",
      },
    },
    contextMenu: {
      style: {
        fontSize: "14px",
        fontWeight: 400,
        paddingLeft: "16px",
        paddingRight: "8px",
        transform: "translate3d(0, -100%, 0)",
        transitionDuration: "125ms",
        transitionTimingFunction: "cubic-bezier(0, 0, 0.2, 1)",
        willChange: "transform",
      },
      activeStyle: {
        transform: "translate3d(0, 0, 0)",
      },
      rows: {
        style: {
          cursor: "pointer",
          fontSize: "13px",
          fontWeight: 400,
          backgroundColor: "red",
          minHeight: "48px",
          "&:not(:last-of-type)": {
            borderBottomStyle: "solid",
            borderBottomWidth: "1px",
          },
        },
      },
    },
    cells: {
      style: {
        paddingTop: "16px",
        paddingBottom: "16px",
        wordBreak: "break-word",
        cursor: "pointer",
      },
      draggingStyle: {},
    },
  };

  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  const [query, setQuery] = useState("");
  const [openUserModal, setopenUserModal] = useState({
    status: false,
    deleteStatus: false,
    data: {},
    user_id: "",
  });
  const [open, setOpen] = useState(false);
  const [generatedPassword, setGeneratedPassword] = useState("");
  const [copiedText, setcopiedText] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = () => {
    setloading(true);
    GetUsersAPICall()
      .then((response: any) => {
        setloading(false);
        const responseData = response.data;
        // console.log(responseData.data);
        setdata(responseData.data);
      })
      .catch((error) => {
        setloading(false);
      });
  };
  const handleEdit = (data: any) => {
    //  console.log("editData", data);
    let obj: IEditUser = {
      isActive: data.isActive,
      isAdmin: data.isAdmin,
      mobile: data.mobile,
      email: data.email,
      id: data.id,
      name: data.name,
    };
    handleUserEditModalOpen(obj);
  };
  const handleDelete = (data: any) => {
    confirmAlert({
      title: "",
      message: "Are you sure you want to Delete this User?",
      buttons: [
        {
          label: "No",
          onClick: () => "",
        },
        {
          label: "Yes",
          onClick: () => handleDeleteUser(data),
        },
      ],
    });
  };

  // User Modal Popup Start

  const handleUserModalOpen = () => {
    setopenUserModal({
      status: true,
      deleteStatus: false,
      data: {},
      user_id: "",
    });
  };
  const handleUserEditModalOpen = (data: IEditUser) => {
    setopenUserModal({
      status: true,
      deleteStatus: false,
      data: {
        ...data,
      },
      user_id: "",
    });
  };
  const handleUserModalClose = () => {
    setopenUserModal({
      status: false,
      deleteStatus: false,
      data: {},
      user_id: "",
    });
  };
  const handleUserModalSubmit = (data: any) => {
    if (Object.keys(openUserModal.data).length) {
      EditUserAPICall({
        ...data,
      })
        .then((response) => {
          handleUserModalClose();
          getUsers();
          toast.success("User edited successfully");
        })
        .catch((error) => {
          handleUserModalClose();
          toast.error("Error editing User");
        });
    } else {
      let obj: IADDUser = {
        email: data.email,
        mobile: data.mobile,
        name: data.name,
        isAdmin: data.isAdmin,
      };
      AddUserAPICall(obj)
        .then((response: any) => {
          console.log("response", response.data);
          handleUserModalClose();
          setOpen(true);
          setGeneratedPassword(response.data.data.password);
          getUsers();
          toast.success("User Created Successfully");
        })
        .catch((error) => {
          handleUserModalClose();
          toast.error(
            error.response.data.error.message
              ? error.response.data.error.message
              : "Error Creating User"
          );
        });
    }
  };

  const handleDeleteModalPopup = (data: any) => {
    // console.log("data", data)
    setopenUserModal({
      status: false,
      deleteStatus: true,
      data: {},
      user_id: data.UserId,
    });
  };

  const handleDeleteUser = (data: IDeleteUser) => {
    handleUserModalClose();
    DeleteUserAPICall(data)
      .then((response: any) => {
        getUsers();
        toast.success("User deleted Successfully");
      })
      .catch((error: any) => {
        toast.error("Error Deleting User");
      });
  };
  const handlePageChange = (value: any, page: any) => {
    console.log(page);
  };

  const columns: any = [
    {
      name: <b style={titletextStyle}>SrNO</b>,
      selector: "index",
      sortable: true,
      width: "70px",
      cell: (row: any, index: any) => (
        <b style={contenttextStyle}>{index + 1}</b>
      ),
    },
    {
      name: <b style={titletextStyle}>Name</b>,
      selector: "emailTitle",
      sortable: true,
      cell: (row: any) => <b style={contenttextStyle}>{row.name}</b>,
    },
    {
      name: <b style={titletextStyle}>Email</b>,
      selector: "publishDate",
      sortable: true,
      cell: (row: any) => <b style={contenttextStyle}>{row.email}</b>,
    },
    {
      name: <b style={titletextStyle}>Mobile</b>,
      selector: "sent",
      sortable: true,
      cell: (row: any) => <b style={contenttextStyle}>{row.mobile}</b>,
    },

    {
      name: <b style={titletextStyle}>Admin</b>,
      selector: "clickThroughRate",
      sortable: true,
      cell: (row: any) => (
        <b style={contenttextStyle}>{row.isAdmin ? "Yes" : "No"}</b>
      ),
      width: "100px",
    },
    {
      name: <b style={titletextStyle}>Active</b>,
      selector: "deliveredRate",
      sortable: true,
      width: "100px",
      cell: (row: any) => (
        <b style={contenttextStyle}>{row.isActive ? "Yes" : "No"}</b>
      ),
    },
    {
      name: <b style={titletextStyle}>Actions</b>,
      selector: "spamReportRate",
      sortable: false,
      allowOverflow: true,
      button: true,
      width: "100px",
      cell: (row: any) => (
        <b style={contenttextStyle}>
          <Stack direction={"row"}>
            <IconButton
              onClick={() => {
                handleEdit(row);
              }}
            >
              <EditIcon sx={{ fill: "#4a4ae2" }} />
            </IconButton>
            <IconButton
              onClick={() => {
                handleDelete(row.id);
              }}
            >
              <DeleteIcon sx={{ fill: "#ff5959" }} />
            </IconButton>
          </Stack>
        </b>
      ),
    },
  ];
  const filteredData = data.filter((res: any) =>
    res.name.toLowerCase().includes(query)
  );
  return (
    <div
      style={{
        background: "white",
        marginBottom: "50px",
        paddingBottom: "10px",
      }}
    >
      <Toaster />
      <ThemeProvider theme={theme}>
        <Helmet>
          <title>Users </title>
          <meta name="Users" content="Users" />
        </Helmet>
        <div className={classes.topbar}>
          <div className={classes.searchInput}>
            <div className={classes.searchIcon}>
              <SearchIcon sx={{ fill: "black" }} />
            </div>

            <input
              type="text"
              value={query}
              className={classes.inputBox}
              placeholder="Search...."
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "end",
              // margin: "0px 20px",
              // padding: "10px 0px",
            }}
          >
            <Button
              sx={{ width: "max-content" }}
              onClick={handleUserModalOpen}
              variant="contained"
            >
              Add User
            </Button>
          </div>
        </div>
        <div
          style={{
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            margin: "0px 10px",
            borderRadius: "10px",
          }}
        >
          <DataTable
            columns={columns}
            data={filteredData}
            // fixedHeader
            customStyles={customStyles}
            progressPending={loading}
            progressComponent={<StyledLoader />}
          />
        </div>

        <UserFormModalpopup
          open={openUserModal.status}
          handleClose={handleUserModalClose}
          handleSubmitData={handleUserModalSubmit}
          data={
            Object.keys(openUserModal.data).length
              ? openUserModal.data
              : undefined
          }
        />

        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"User Generated Password"}</DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              sx={{ marginBottom: "20px" }}
            >
              Copy the Password Generated of the User
            </DialogContentText>

            <TextField
              fullWidth
              size="small"
              variant="standard"
              value={generatedPassword}
              placeholder="Enter Domain Name"
              InputProps={{
                disableUnderline: true,
                sx: {
                  height: "40px",
                  padding: "0px 10px",
                  borderRadius: "5px",
                  width: "400px",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <CopyToClipboard
                      text={generatedPassword}
                      onCopy={() => {
                        setcopiedText(true);
                        setTimeout(() => {
                          setcopiedText(false);
                        }, 4000);
                      }}
                    >
                      <IconButton>
                        {copiedText ? <DoneIcon /> : <ContentCopyIcon />}
                      </IconButton>
                    </CopyToClipboard>
                  </InputAdornment>
                ),
              }}
              sx={{
                background: "#EDF2F7",
                borderRadius: "5px",
                "& label.Mui-focused": {
                  color: "grey",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#EDF2F7",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#EDF2F7",
                  },
                },
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </div>
  );
};
export default Users;
