import * as React from "react";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import {
  Stack,
  Fade,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  ThemeProvider,
  Container,
  FormHelperText,
  Typography,
  Box,
  Button,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import {
  UserAddScheme,
  UserEditScheme,
} from "../../components/Schemas/Schemas";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { IEditUser } from "../../Utils/dtos";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { theme } from "../ThemeProvider/ThemeProvider";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

interface Props {
  open: boolean;
  handleClose: any;

  handleSubmitData: any;
  data?: IEditUser | any;
}

export default function FormModalpopup({
  open,
  handleClose,
  handleSubmitData,
  data,
}: Props) {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const AddUser = {
    mobile: "",
    email: "",
    name: "",
    // password: "",
    isAdmin: true,
    isActive: false,
  };

  const type = data ? "edit" : "add";
  return (
    <ThemeProvider theme={theme}>
      <BootstrapDialog
        onClose={(event: any) => {
          if (event.keyCode === 27) {
            handleClose();
          }
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {type === "add" ? "Add User" : "Edit User"}
        </BootstrapDialogTitle>

        <Formik
          validationSchema={data ? UserEditScheme : UserAddScheme}
          initialValues={data ? data : AddUser}
          onSubmit={(values) => {
            // console.log("values", values);
            handleSubmitData(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <>
              {/* {console.log("values", values, "errors", errors)} */}
              <form noValidate onSubmit={handleSubmit}>
                <DialogContent dividers>
                  <Box component="form" noValidate>
                    <Stack spacing={2} width={500}>
                      <Stack
                        justifyContent={"space-between"}
                        spacing={2}
                        direction={"row"}
                      >
                        <TextField
                          id="name"
                          fullWidth
                          name="name"
                          label="Name"
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                          error={touched.name && Boolean(errors.name)}
                          helperText={touched.name && errors.name?.toString()}
                        />
                        <TextField
                          id="mobile"
                          fullWidth
                          name="mobile"
                          label="Mobile"
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.mobile}
                          error={touched.mobile && Boolean(errors.mobile)}
                          helperText={
                            touched.mobile && errors.mobile?.toString()
                          }
                          inputProps={{
                            maxLength: 10,
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                          onKeyPress={(event) => {
                            const keyCode = event.keyCode || event.which;
                            const keyValue = String.fromCharCode(keyCode);
                            const isValidKey = /^\d+$/.test(keyValue);

                            if (!isValidKey) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Stack>
                      <Stack>
                        <TextField
                          id="email"
                          fullWidth
                          name="email"
                          label="Email"
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          error={touched.email && Boolean(errors.email)}
                          helperText={touched.email && errors.email?.toString()}
                        />
                        {/* {type === "add" && (
                          <TextField
                            margin="normal"
                            fullWidth
                            name="password"
                            label="Password"
                            id="password"
                            autoComplete="current-password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            error={touched.password && Boolean(errors.password)}
                            helperText={
                              touched.password && errors.password?.toString()
                            }
                            type={showPassword ? "text" : "password"}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )} */}
                      </Stack>
                      <Stack></Stack>

                      <Stack justifyContent={"space-around"} direction={"row"}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.isActive}
                              value={values.isActive}
                              onChange={(event) => {
                                setFieldValue("isActive", event.target.checked);
                              }}
                            />
                          }
                          label="Is Active"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.isAdmin}
                              value={values.isAdmin}
                              onChange={(event) => {
                                setFieldValue("isAdmin", event.target.checked);
                              }}
                            />
                          }
                          label="Is Admin"
                        />
                      </Stack>
                    </Stack>
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button type="submit" variant="outlined">Save changes</Button>
                </DialogActions>
              </form>
            </>
          )}
        </Formik>
      </BootstrapDialog>
    </ThemeProvider>
  );
}
