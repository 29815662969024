import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  IconButton,
  InputAdornment,
  Pagination,
  Slide,
  Stack,
  TextField,
  ThemeProvider,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  AddUserAPICall,
  DeleteUserAPICall,
  EditUserAPICall,
  GetAllEnquiresAPICall,
  GetUserDetailsAPICall,
  GetUsersAPICall,
} from "../../Utils/Apihelper";
import UserFormModalpopup from "../../components/Modals/UserFormModalpopup";
import {
  IADDUser,
  IDeleteUser,
  IEditUser,
  IGetAllEnquires,
} from "../../Utils/dtos";
import DataTable from "react-data-table-component";
import StyledLoader from "../../components/Styledloader/StyledLoader";
import { theme } from "../../components/ThemeProvider/ThemeProvider";
import toast, { Toaster } from "react-hot-toast";
import classes from "./Enquires.module.scss";
import { confirmAlert } from "react-confirm-alert";
import { TransitionProps } from "@mui/material/transitions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneIcon from "@mui/icons-material/Done";

const titletextStyle = {
  fontSize: "14px",
  fontWeight: "bold",
  color: "#8C8B8F",
};
const contenttextStyle = { fontSize: "14px" };

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Enquires = () => {
  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F3F6FA",
        // display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
      },
    },

    headRow: {
      style: {
        borderBottom: "none",
        marginBottom: "10px",
      },
    },
    header: {
      style: {
        fontSize: "16px",
        minHeight: "38px",
        paddingLeft: "5px",
        paddingRight: "5px",
      },
    },
    contextMenu: {
      style: {
        fontSize: "14px",
        fontWeight: 400,
        paddingLeft: "16px",
        paddingRight: "8px",
        transform: "translate3d(0, -100%, 0)",
        transitionDuration: "125ms",
        transitionTimingFunction: "cubic-bezier(0, 0, 0.2, 1)",
        willChange: "transform",
      },
      activeStyle: {
        transform: "translate3d(0, 0, 0)",
      },
      rows: {
        style: {
          cursor: "pointer",
          fontSize: "13px",
          fontWeight: 400,
          backgroundColor: "red",
          minHeight: "48px",
          "&:not(:last-of-type)": {
            borderBottomStyle: "solid",
            borderBottomWidth: "1px",
          },
        },
      },
    },
    cells: {
      style: {
        paddingTop: "16px",
        paddingBottom: "16px",
        wordBreak: "break-word",
        cursor: "pointer",
      },
      draggingStyle: {},
    },
  };

  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const [paginationCount, setpaginationCount] = useState(1);
  const [totalRows, setTotalRows] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
    GetALLEnquires();
  }, [page, query]);

  const GetALLEnquires = () => {
    let obj: IGetAllEnquires = {
      query: query,
      pageNo: page,
      pageLimit: 20,
    };
    setloading(true);
    GetAllEnquiresAPICall(obj)
      .then((response: any) => {

        setloading(false);
        setdata(response.data.data.enquiries ? response.data.data.enquiries : []);
        setTotalRows(Math.ceil(response.data.data.total / 20));
      })
      .catch((error: any) => {
        setloading(false);
        // console.log("error", error);
      });
  };

  const handlePageChange = (value: any, page: any) => {
    window.scrollTo(0, 0);
    setpaginationCount(page);
    setPage(page);
  };

  const columns: any = [
    {
      name: <b style={titletextStyle}>SrNo</b>,
      selector: "index",
      sortable: true,
      width: "70px",
      cell: (row: any, index: any) => (
        <b style={contenttextStyle}>{index + 1 + paginationCount * 20 - 20}</b>
      ),
    },
    {
      name: <b style={titletextStyle}>Name</b>,
      selector: "name",
      sortable: true,
      cell: (row: any) => <b style={contenttextStyle}>{row.name}</b>,
    },
    {
      name: <b style={titletextStyle}>Email</b>,
      selector: "email",
      sortable: true,
      cell: (row: any) => <b style={contenttextStyle}>{row.email}</b>,
    },
    {
      name: <b style={titletextStyle}>Mobile</b>,
      selector: "mobileNumber",
      sortable: true,
      cell: (row: any) => <b style={contenttextStyle}>{row.mobileNumber}</b>,
    },

    {
      name: <b style={titletextStyle}>Created TS</b>,

      selector: "createdAt",
      sortable: true,

      cell: (row: any) => (
        <b style={titletextStyle}>
          {row.createdAt.length > 0 ? convertDateTime(row.createdAt) : row.createdAt}
        </b>
      ),
    },
    {
      name: <b style={titletextStyle}>Message</b>,
      selector: "message",
      sortable: true,

      cell: (row: any) => <b style={contenttextStyle}>{row.message ? row.message : "-"}</b>,
    },
  ];
  function convertDateTime(dateTimeString:any) {

    const dateTime = new Date(dateTimeString);
    const day = dateTime.getDate();
    const month = dateTime.getMonth() + 1; 
    const year = dateTime.getFullYear();
    let hours = dateTime.getHours();
    const minutes = dateTime.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; 
    const formattedDate = `${day < 10 ? "0" : ""}${day}-${
      month < 10 ? "0" : ""
    }${month}-${year}`;
    const formattedTime = `${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes} ${ampm}`;
    const formattedDateTime = `${formattedDate} ${formattedTime}`;

    return formattedDateTime;
  }
  const filteredData = data.filter((res: any) =>
    res.name.toLowerCase().includes(query)
  );
  return (
    <div
      style={{
        background: "white",
        marginBottom: "50px",
        paddingBottom: "10px",
      }}
    >
      <Toaster />
      <ThemeProvider theme={theme}>
        <Helmet>
          <title>Enquires </title>
          <meta name="Enquires" content="Enquires" />
        </Helmet>
        <div className={classes.topbar}>
          <div className={classes.searchInput}>
            <div className={classes.searchIcon}>
              <SearchIcon sx={{ fill: "black" }} />
            </div>

            <input
              type="text"
              value={query}
              className={classes.inputBox}
              placeholder="Search...."
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
        </div>
        <div
          style={{
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            margin: "0px 10px",
            borderRadius: "10px",
          }}
        >
          <DataTable
            columns={columns}
            data={filteredData}
            // fixedHeader
            customStyles={customStyles}
            progressPending={loading}
            progressComponent={<StyledLoader />}
          />
        </div>
        {data.length > 0 && (
          <Stack alignItems={"center"} marginTop={"20px"} marginBottom={"20px"}>
            <Pagination
              onChange={(value, page) => {
                handlePageChange(value, page);
                // setpaginationCount(page);
              }}
              page={paginationCount}
              count={totalRows}
              variant="outlined"
              shape="rounded"
            />
          </Stack>
        )}
      </ThemeProvider>
    </div>
  );
};
export default Enquires;
