import React, { useEffect, useState } from "react";
import classes from "./SmsDashboard.module.scss"
import {
  Box,
  Card,
  Divider,
  Grid,
  Icon,
  Paper,
  Typography,
} from "@mui/material";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import SouthEastIcon from "@mui/icons-material/SouthEast";
import { Helmet } from "react-helmet";
import WeekendRoundedIcon from "@mui/icons-material/WeekendRounded";
import SupervisorAccountRoundedIcon from "@mui/icons-material/SupervisorAccountRounded";
import DynamicFeedRoundedIcon from "@mui/icons-material/DynamicFeedRounded";
import BlurLinearRoundedIcon from "@mui/icons-material/BlurLinearRounded";
import BatchPredictionRoundedIcon from "@mui/icons-material/BatchPredictionRounded";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import AssistWalkerRoundedIcon from "@mui/icons-material/AssistWalkerRounded";
import DirectionsBikeRoundedIcon from "@mui/icons-material/DirectionsBikeRounded";
import SettingsAccessibilityRoundedIcon from "@mui/icons-material/SettingsAccessibilityRounded";
import WorkspacesRoundedIcon from "@mui/icons-material/WorkspacesRounded";
import { GetDashboardAnalyticsAPICall } from "../../Utils/Apihelper";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
} from "recharts";

const SmsDashboard = () => {
  const [bgColor, setbgColor] = useState("rgb(235, 231, 231)");
  const [dashboardData, setDashboardData] = useState({
    total: 14,
    awsconfigured: 2,
    notawsconfigured: 12,
    free: 13,
    standard: 1,
    professional: 0,
    enterprise: 0,
  });
  const [activeBarIndex, setActiveBarIndex] = useState<any>();

  useEffect(() => {
    GetDashboard("client");
  }, []);

  const GetDashboard = (type: any) => {
    GetDashboardAnalyticsAPICall(type)
      .then((response: any) => {
        // console.log("response", response.data.data);
        if (response.data.data) {
          setDashboardData(response.data.data);
        }
      })
      .catch((error: any) => {});
  };
  const data = [
    { name: "Free", value: dashboardData.free },
    { name: "Standard", value: dashboardData.standard },
    { name: "Professional", value: 15 },
    { name: "Enterprise", value: 12 },
  ];
  const clientsgraphdata = [
    { name: "Total", value: dashboardData.total },
    { name: "Active", value: 12 },
    { name: "Inactive", value: 2 },
    { name: "Aws Configured", value: 10 },
  ];
  const COLORS = [
    "rgb(25, 25, 25)",
    "rgb(216, 27, 96)",
    "rgb(67, 160, 71)",
    "rgb(251, 140, 0)",
  ];
  const COLORSClients = [
    "rgb(26, 115, 232)",
    "rgb(67, 160, 71)",
    " rgb(229, 57, 53)",
    "#51c9f5",
  ];

  return (
    <>
      <Helmet>
        <title>SMS Dashboard </title>
        <meta name="Dashboard" content="Dashboard" />
      </Helmet>
      <Box sx={{ flexGrow: 1 }} mb={4}>
        <div className={classes.wrapper}>
          <p className={classes.mainTitle}>Clients</p>
          <div className={classes.CardWrapper}>
            <div className={classes.Card}>
              <div className={classes.topContainer}>
                <div
                  className={classes.IconSection}
                  style={{
                    background:
                      "linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))",
                  }}
                >
                  <Icon fontSize="medium" color="inherit">
                    <SupervisorAccountRoundedIcon />
                  </Icon>
                </div>
                <div className={classes.TextSection}>
                  <p className={classes.textTitle}>Total</p>
                  <p className={classes.textCount}>{dashboardData.total}</p>
                </div>
              </div>
              <div className={classes.divider} />
            </div>
            <div className={classes.Card}>
              <div className={classes.topContainer}>
                <div
                  className={classes.IconSection}
                  style={{
                    background:
                      "linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71))",
                  }}
                >
                  <Icon fontSize="medium" color="inherit">
                    <DirectionsBikeRoundedIcon />
                  </Icon>
                </div>
                <div className={classes.TextSection}>
                  <p className={classes.textTitle}>Active</p>
                  <p className={classes.textCount}>280</p>
                </div>
              </div>
              <div className={classes.divider} />
            </div>
            <div className={classes.Card}>
              <div className={classes.topContainer}>
                <div
                  className={classes.IconSection}
                  style={{
                    background:
                      "linear-gradient(195deg, rgb(239, 83, 80), rgb(229, 57, 53))",
                  }}
                >
                  <Icon fontSize="medium" color="inherit">
                    <AssistWalkerRoundedIcon />
                  </Icon>
                </div>
                <div className={classes.TextSection}>
                  <p className={classes.textTitle}>In Active</p>
                  <p className={classes.textCount}>280</p>
                </div>
              </div>
              <div className={classes.divider} />
            </div>
            <div className={classes.Card}>
              <div className={classes.topContainer}>
                <div
                  className={classes.IconSection}
                  style={{
                    background:
                      "linear-gradient(-45deg, #0c9ed4 0%, #51c9f5 100%)",
                  }}
                >
                  <Icon fontSize="medium" color="inherit">
                    <WorkspacesRoundedIcon />
                  </Icon>
                </div>
                <div className={classes.TextSection}>
                  <p className={classes.textTitle}>AWS Configured</p>
                  <p className={classes.textCount}>
                    {dashboardData.awsconfigured}
                  </p>
                </div>
              </div>
              <div className={classes.divider} />
            </div>
          </div>
          <p className={classes.mainTitle}>plans</p>
          <div className={classes.CardWrapper}>
            <div className={classes.Card}>
              <div className={classes.topContainer}>
                <div className={classes.IconSection}>
                  <Icon fontSize="medium" color="inherit">
                    <DynamicFeedRoundedIcon />
                  </Icon>
                </div>
                <div className={classes.TextSection}>
                  <p className={classes.textTitle}>Free plan</p>
                  <p className={classes.textCount}>{dashboardData.free}</p>
                </div>
              </div>
              <div className={classes.divider} />
            </div>
            <div className={classes.Card}>
              <div className={classes.topContainer}>
                <div
                  className={classes.IconSection}
                  style={{
                    background:
                      "linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96))",
                  }}
                >
                  <Icon fontSize="medium" color="inherit">
                    <BlurLinearRoundedIcon />
                  </Icon>
                </div>
                <div className={classes.TextSection}>
                  <p className={classes.textTitle}>Standard Plan</p>
                  <p className={classes.textCount}>{dashboardData.standard}</p>
                </div>
              </div>
              <div className={classes.divider} />
            </div>
            <div className={classes.Card}>
              <div className={classes.topContainer}>
                <div
                  className={classes.IconSection}
                  style={{
                    background:
                      "linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71))",
                  }}
                >
                  <Icon fontSize="medium" color="inherit">
                    <BatchPredictionRoundedIcon />
                  </Icon>
                </div>
                <div className={classes.TextSection}>
                  <p className={classes.textTitle}>Professional Plan</p>
                  <p className={classes.textCount}>
                    {dashboardData.professional}
                  </p>
                </div>
              </div>
              <div className={classes.divider} />
            </div>
            <div className={classes.Card}>
              <div className={classes.topContainer}>
                <div
                  className={classes.IconSection}
                  style={{
                    background:
                      "linear-gradient(195deg, rgb(255, 167, 38), rgb(251, 140, 0))",
                  }}
                >
                  <Icon fontSize="medium" color="inherit">
                    <AutoAwesomeRoundedIcon />
                  </Icon>
                </div>
                <div className={classes.TextSection}>
                  <p className={classes.textTitle}>Enterprise Plan</p>
                  <p className={classes.textCount}>
                    {dashboardData.enterprise}
                  </p>
                </div>
              </div>
              <div className={classes.divider} />
            </div>
          </div>

          <div className={classes.graphWrapper}>
            <div className={classes.leftGraph}>
              <p className={classes.mainTitle}>Clients Graph</p>

              <PieChart width={300} height={300}>
                <Tooltip
                  // itemStyle={{ color: "rgba(0, 103, 121, 1)" }}
                  // position={{ x: 300, y: -100 }}
                  content={<CustomTooltip />}
                />
                <Pie
                  data={clientsgraphdata}
                  // cx={220}
                  // cy={200}
                  innerRadius={100}
                  outerRadius={135}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORSClients[index % COLORSClients.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </div>
            <div className={classes.leftGraph}>
              <p className={classes.mainTitle}>Plans Graph</p>

              <BarChart width={300} height={300} data={data}>
                <Tooltip
                  cursor={{ height: "max-content" }}
                  content={<CustomTooltip />}
                />

                <Bar dataKey="value" fill="#8884d8" radius={[8, 8, 8, 8]}>
                  {" "}
                  {data.map((entry: any, index: any) => (
                    <Cell
                      cursor="pointer"
                      fill={COLORS[index % COLORS.length]}
                      key={entry.value}
                      // width={20}
                    />
                  ))}
                </Bar>
              </BarChart>

              {/* <BarChart
                width={600}
                height={300}
                data={data}
                margin={{
                  top: 5,
                  right: 5,
                  left: 0,
                  bottom: 16,
                }}
              >
                {data.length > 0 ? (
                  <>
                    <CartesianGrid strokeOpacity={0.3} />
                    <XAxis
                      dataKey="name"
                      padding={{ left: 10, right: 10 }}
                      tickLine={false}
                      tickSize={10}
                      axisLine={true}
                      scale="point"
                      tick={{
                        fontSize: 12,
                        fill: "#A3AED0",
                        fontWeight: "500",
                      }}
                      // tick={<CustomTick  />}
                      strokeOpacity={0.1}
                    />
                    <YAxis
                      tickLine={false}
                      tickSize={4}
                      axisLine={true}
                      tick={{
                        fontSize: 12,
                        fill: "#A3AED0",
                        fontWeight: "500",
                        // width: "fit-content",
                      }}
                      color="#F1F2F3"
                      strokeOpacity={0.1}
                    />
             
                    <Bar
                      dataKey="value"
                      onMouseEnter={(_, index: any) => setActiveBarIndex(index)}
                      onMouseLeave={() => setActiveBarIndex(null)}
                      radius={[8, 8, 8, 8]}
                      width={20}
                    >
                      {data.map((entry: any, index: any) => (
                        <Cell
                          cursor="pointer"
                          fill={
                            index === activeBarIndex
                              ? "#092c32"
                              : "rgba(0, 103, 121, 1)"
                          }
                          key={entry.value}
                          // width={20}
                        />
                      ))}
                    </Bar>
                  </>
                ) : (
                  <>
                    <text
                      x="50%"
                      y="50%"
                      textAnchor="middle"
                      dominantBaseline="middle"
                      fontSize="16"
                      fill="#888"
                    >
                      {"No Data Found"}
                    </text>
                  </>
                )}
              </BarChart> */}
            </div>
          </div>
        </div>
      </Box>
    </>
  );
}

export default SmsDashboard;

const CustomTooltip = ({ active, payload, label }: any) => {
  // console.log("active", active, "label", label, "payload", payload);
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          background: "rgba(213, 233, 236, 1)",
          border: "1px solid rgba(0, 103, 121, 1)",
          borderRadius: "10px",
          padding: "5px 10px",
        }}
      >
        <p
          style={{ fontSize: "12px", fontWeight: 400 }}
        >{`${payload[0]?.payload?.name}`}</p>
        <p
          style={{ fontSize: "14px", fontWeight: 500 }}
        >{`${payload[0]?.payload?.value}`}</p>
      </div>
    );
  }

  return null;
};

