import React, { useEffect, useState } from "react";
import {
  CloneTemplateAPICall,
  DeleteTemplateAPICall,
  EditTemplateCategoryNameAPICall,
  EditTemplatelabelAPICall,
  GetSingleTemplateDetailsAPICall,
  GetTemplateCategoryAPICall,
  GetTemplateCategoryDetailsAPICall,
  GetTemplatesAPICall,
  deleteSingleTemplateAPICall,
} from "../../Utils/Apihelper";
import classes from "./Templates.module.scss";
import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  Stack,
  TextField,
  ThemeProvider,
  Tooltip,
} from "@mui/material";
import TemplatesDataTableComp from "./TemplatesDataTable";
import EditIcon from "@mui/icons-material/Edit";
import { confirmAlert } from "react-confirm-alert";
import toast, { Toaster } from "react-hot-toast";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Helmet } from "react-helmet";
import addicon from "../../assets/Images/addicontemplate.svg";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import NoTemplateImage from "../../assets/Images/NoTemplateImage.svg";
import CreateCategory from "./CreateCategory/CreateCategory";
import { useNavigate } from "react-router-dom";
import { setLoadTemplateJson } from "../../Store/Project/ProjectSlice";
import { useDispatch } from "react-redux";
import { theme } from "../../components/ThemeProvider/ThemeProvider";

export default function Templates() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tab, setTab] = useState(1);
  const [categoryDetailsList, setCategoryDetailsList] = useState<any>([]);
  const [query, setQuery] = useState("");
  const [autoCompleteValue, setAutoCompleteValue] = useState<any>("");
  const [categoryList, setCategoryList] = useState<any>([]);
  const [openEditTemplateName, setOpenTemplateName] = useState({
    status: false,
    name: "",
  });
  const [newTemplatename, setNewTemplateName] = useState("");
  const [loader, setLoader] = useState(false);
  const [allTemplate, setAllTemplate] = useState([]);
  const [categoryModal, setcategoryModal] = useState(false);
  const [hoveredButton, setHoveredButton] = useState(0);
  const [oldTemplateCategory, setOldTemplateCategory] = useState("");
  const [openlableedit, setopenlableedit] = useState(false);
  const [newLabelName, setNewLabelName] = useState("");
  const [tempName, setTempName] = useState("");
  const [oldlabelname, setOpenLabelName] = useState("");
  const [openClone, setOpenClone] = useState(false);
  const [deleteTemplate, setDeleteTemplate] = useState("");
  const [clonedCamp, setClonedCamp] = useState("");
  const [autoCompleteValueClone, setAutoCompleteValueClone] = useState<any>("");

  const handleCloseLabelEdit = () => {
    setopenlableedit(false);
    setNewLabelName("");
  };
  const handleOpenEditLabelClick = (tempData: any) => {
    setNewLabelName("");
    setTempName(tempData.templateName);
    setOpenLabelName(tempData.templateLabel);
    setopenlableedit(true);
  };
  useEffect(() => {
    // handleGetallTemplate()
    handleGetCatDeatilsList();
  }, []);
  useEffect(() => {
    if (tab === 1 && autoCompleteValue !== "") {
      handleGetallTemplate();
    }
  }, [autoCompleteValue]);

  const handleGetallTemplate = () => {
    setLoader(true);
    GetTemplatesAPICall()
      .then((res: any) => {
        setLoader(false);
        // console.log("res", res.data);
        setAllTemplate(res.data.data);
     
      })
      .catch((res: any) => {
        setLoader(false);
        //  console.log(res.data);
        setLoader(false);
        toast.error("Data couldn't be loaded");
      });
  };

  function activeTab(tabNumber: any) {
    setTab(tabNumber);
    handleGetCatDeatilsList();
  }

  const handleGetCatDeatilsList = () => {
    GetTemplateCategoryDetailsAPICall()
      .then((res: any) => {
        setCategoryDetailsList(res.data.data);
        setAutoCompleteValue(res.data.data[0].templateCategoryName);
      })
      .catch((res) => {
        // console.log(res.data);
        // toast.error("Category Fetch failed");
      });
  };

  const handleLoadTemplate = (templateName: any) => {
    const loadJsonRequestBody: any = {
      templateName: templateName,
      templateCategory: autoCompleteValue,
    };

    GetSingleTemplateDetailsAPICall(loadJsonRequestBody)
      .then((res: any) => {
        console.log("templatesALL", res.data.data.templateJson);
        sessionStorage.setItem("editFlag", "true");
        dispatch(setLoadTemplateJson(res.data.data.templateJson));
        sessionStorage.setItem("TemplateName", res.data.data.templateName);
        sessionStorage.setItem("TemplateLabel", res.data.data.templateLabel);
        sessionStorage.setItem(
          "TemplateCategory",
          res.data.data.templateCategory
        );
        navigate("/dashboard/templates/emailTemplateEditor");
      })
      .catch((res) => {
        toast.error("Template couldn't be loaded");
      });
  };

  const handleEditTemplateLabel = () => {
    let obj: any = {
      templateLabel: newLabelName,
    };
    EditTemplatelabelAPICall(obj, tempName, autoCompleteValue)
      .then((response: any) => {
        toast.success("Template Label Updated Successfully");
        setopenlableedit(false);
        handleGetallTemplate();
      })
      .catch((error: any) => {
        toast.error(
          error.response?.data?.error?.message
            ? error.response.data.error.message
            : "Error Editing Template label"
        );
      });
  };
  const handleClickOpenClone = (name: any) => {
    setOpenClone(true);
    setDeleteTemplate(name);
  };

  const handleCloseClone = () => {
    setOpenClone(false);
  };

  

  const handleClone = () => {
    const reqBody: any = {
      templateName: deleteTemplate,
      new_templateName: clonedCamp,
      new_templateCategory: autoCompleteValueClone,
      templateCategory: oldTemplateCategory,
    };
    // console.log("temp", deleteTemplate)
    CloneTemplateAPICall(reqBody)
      .then((res: any) => {
        // console.log(res.data);
        toast.success("Template cloned successfully");
        handleGetallTemplate();
        handleCloseClone();
      })
      .catch((res) => {
        // console.log(res.data);
        toast.error("Clone action failed :(");
        handleCloseClone();
      });
    setClonedCamp("");
  };

  const handleDeleteTemplate = (dataname: any) => {
    confirmAlert({
      title: "",
      message: "Are you sure you want to Delete?",
      buttons: [
        {
          label: "No",
          onClick: () => "",
        },
        {
          label: "Yes",
          onClick: () => {
            let obj: any = {
              templateName: dataname,
              templateCategory: autoCompleteValue,
            };
            DeleteTemplateAPICall(obj)
              .then((response: any) => {
                console.log(response);
                toast.success("Successfully Deleted the template");
                handleGetallTemplate();
              })
              .catch((error: any) => {
                toast.error(
                  error.response.data?.error?.message
                    ? error.response.data.error.message
                    : "Error Deleting the template"
                );
              });
          },
        },
      ],
    });
  };

  const handleDeleteTemplateCategory = (templateDetails: any) => {
    confirmAlert({
      title: "",
      message: "Are you sure you want to Delete?",
      buttons: [
        {
          label: "No",
          onClick: () => "",
        },
        {
          label: "Yes",
          onClick: () => handleDeleteTempCatg(templateDetails),
        },
      ],
    });
  };

  const handleDeleteTempCatg = (templateData: any) => {
    let obj: any = {
      templateCategoryName: templateData.templateCategoryName,
    };
    deleteSingleTemplateAPICall(obj)
      .then((response: any) => {
        toast.success(
          response.data.message
            ? response.data.message
            : "Template category Deleted successfully"
        );
        handleGetCatDeatilsList();
      })
      .catch((error: any) => {
        // console.log("error", error);
        toast.error(
          error.response.data.error.message
            ? error.response.data.error.message
            : "Error Deleting"
        );
      });
  };

  const handleCloseEditTemplate = () => {
    setNewTemplateName("");
    setOpenTemplateName({
      status: false,
      name: "",
    });
  };

  const handleSubmitEditTemplateCategory = () => {
    let obj: any = {
      oldTemplateCategoryName: openEditTemplateName.name,
      newTemplateCategoryName: newTemplatename,
    };

    EditTemplateCategoryNameAPICall(obj)
      .then((response: any) => {
        setNewTemplateName("");
        setOpenTemplateName({
          status: false,
          name: "",
        });
        handleGetCatDeatilsList();
        toast.success(
          response.data.message ? response.data.message : "Edited Successfully"
        );
      })
      .catch((error: any) => {
        toast.error(
          error.response.data.error.message
            ? error.response.data.error.message
            : "Error Editing"
        );
      });
  };

  const filteredData =
    allTemplate &&
    allTemplate.filter(
      (res: any) =>
        res.templateName.toLowerCase().includes(query) &&
        res.templateCategory === autoCompleteValue
    );

  // console.log("filteredData",filteredData)

  const columns = [
    {
      name: (
        <div style={{ fontSize: "13px", color: "black", fontWeight: 600 }}>
          Sl No
        </div>
      ),
      selector: "index",
      width: "6rem",
      sortable: true,
      cell: (row: any, index: any) => (
        <div style={{ fontSize: "13px" }}>{index + 1}</div>
      ),
    },
    {
      name: (
        <div style={{ fontSize: "13px", color: "black", fontWeight: 600 }}>
          Created{" "}
        </div>
      ),
      selector: "index",
      // width: "13rem",
      sortable: true,
      cell: (row: any, index: any) => (
        <div style={{ fontSize: "13px" }}>{row.createdAt}</div>
      ),
    },
    {
      name: (
        <div style={{ fontSize: "13px", color: "black", fontWeight: 600 }}>
          Template Category Name
        </div>
      ),
      selector: "templateCategoryName",
      width: "20rem",
      sortable: true,
      cell: (row: any) => (
        <div style={{ fontSize: "13px" }}>{row.templateCategoryName}</div>
      ),
    },
    {
      name: (
        <div style={{ fontSize: "13px", color: "black", fontWeight: 600 }}>
          Templates{" "}
        </div>
      ),
      selector: "index",
      // width: "13rem",
      sortable: true,
      cell: (row: any, index: any) => (
        <div style={{ fontSize: "13px" }}>{row.templatesList.length}</div>
      ),
    },
    {
      name: (
        <div
          style={{
            fontSize: "13px",
            color: "black",
            display: "flex",
            justifyContent: "right",
            width: "80%",
            fontWeight: 600,
          }}
        >
          Actions
        </div>
      ),
      selector: "",
      sortable: false,

      cell: (row: any) => (
        <b style={{ width: "90%" }}>
          <Stack
            spacing={2}
            direction={"row"}
            justifyContent={"right"}
            alignItems={"center"}
          >
            <IconButton
              onClick={() => {
                setOpenTemplateName({
                  status: true,
                  name: row.templateCategoryName,
                });
              }}
              style={{
                background: "#F7F8FF",
                borderRadius: "10px",
                height: "30px",
              }}
            >
              <Tooltip title="Edit Template Name">
                <EditIcon
                  fontSize="small"
                  sx={{
                    fontSize: "16px",
                    color: "#505780",
                  }}
                />
              </Tooltip>
            </IconButton>
            <IconButton
              onClick={() => {
                handleDeleteTemplateCategory(row);
              }}
              style={{
                background: "#F7F8FF",
                borderRadius: "10px",
                height: "30px",
              }}
            >
              <Tooltip title="Delete Template">
                <DeleteIcon
                  fontSize="small"
                  sx={{
                    fontSize: "16px",
                    color: "#FF5151",
                  }}
                />
              </Tooltip>
            </IconButton>
          </Stack>
        </b>
      ),
    },
  ];

  function formatDate(dateString: any) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero indexed
    const year = date.getFullYear();

    // Pad single digit day and month with leading zero if necessary
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}-${formattedMonth}-${year}`;
  }

  return (
    <div>
      <Helmet>
        <title>Templates </title>
        <meta name="Templates" content="Templates" />
      </Helmet>
      <Toaster />
      <div className={classes.wrapper}>
        <div className={classes.utils2}>
          <div className={classes.tabsWrapper}>
            <div className={classes.tabsCatagory}>
              <div
                className={classes.tabsItem}
                onClick={() => {
                  activeTab(1);
                }}
                style={{
                  backgroundColor: tab === 1 ? "#4C6FFF" : "",
                  color: tab == 1 ? "white" : "",
                }}
              >
                Templates
              </div>
              <div
                className={classes.tabsItem}
                onClick={() => {
                  activeTab(2);
                }}
                style={{
                  backgroundColor: tab === 2 ? "#4C6FFF" : "",
                  color: tab == 2 ? "white" : "",
                }}
              >
                Categories
              </div>
            </div>
          </div>
          {tab === 1 && (
            <div className={classes.filterWrapper}>
              <div className={classes.searchInput} style={{ width: "50%" }}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <input
                  type="text"
                  className={classes.inputBox}
                  placeholder="Search...."
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>
              <div style={{ width: "100%" }}>
                <Autocomplete
                  size="small"
                  value={autoCompleteValue}
                  options={categoryDetailsList.map(
                    (a: any) => a.templateCategoryName
                  )}
                  onChange={(event, value) => setAutoCompleteValue(value)}
                  // onInputChange={(event, value) => console.log(value)}
                  sx={{
                    borderRadius: "10px",
                    marginTop: "0px",
                    width: "-webkit-fill-available",
                    background: "#EDF2F7",
                    outline: "none",
                    fontSize: "16px",
                    fontWeight: 500,
                    padding: "0 10px",
                    color: "#000000",
                    boxShadow: "none",
                    "& .MuiOutlinedInput-input": {
                      // padding: "4.5px 4px 2.5px 8px",
                      paddingTop: "2px !important",
                      cursor: "pointer",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                  }}
                  id="disable-clearable"
                  popupIcon={
                    <KeyboardArrowDownIcon
                      style={{ cursor: "pointer" }}
                      fontSize="small"
                    />
                  }
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Category"
                      label="Category"
                      InputLabelProps={{
                        sx: {
                          backgroundColor: "",
                          marginTop: "0px",
                          fontSize: "16px",
                          fontWeight: 600,
                          marginLeft: "-5px",
                        },
                      }}
                    />
                  )}
                />
              </div>
            </div>
          )}
        </div>
        {tab === 1 ? (
          <>
            {loader ? (
              <>
                <span className={classes.loader}></span>
              </>
            ) : (
              <>
                {filteredData.length > 0 ? (
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        sx={{ display: { xs: "none", sm: "block" } }}
                      >
                        <div
                          className={classes.cardContainernew}
                          style={{
                            border: "1px solid #D9D9D9",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                            padding: "0",
                            boxShadow: "none",
                            cursor: "pointer",
                            height: "95.5%",
                          }}
                          onClick={() => {
                            setcategoryModal(true);
                            sessionStorage.setItem("editFlag", "false");
                            // handleGenerateRandomName();
                          }}
                        >
                          <img src={addicon} />
                          <p
                            style={{
                              fontSize: "14px",
                              fontWeight: 600,
                              margin: "10px 0px 5px 0px",
                            }}
                          >
                            Create A NewTemplate{" "}
                          </p>
                          <p
                            style={{
                              fontSize: "14px",
                              fontWeight: 400,
                              color: "#425466",
                            }}
                          >
                            Start From Scratch
                          </p>
                        </div>
                      </Grid>
                      {filteredData.map((data: any) => (
                        <>
                          <Grid item xs={12} sm={3}>
                            <div
                              className={classes.cardContainer}
                              style={{
                                backgroundImage: `url(data:image/png;base64,${data.templateImage})`,
                              }}
                            >
                              {" "}
                              {data.templateLabel !== "" && (
                                <div className={classes.cardContainerNewText}>
                                  {data.templateLabel === ""
                                    ? "-"
                                    : data.templateLabel}
                                </div>
                              )}
                              <div className={classes.hovertranslatediv}>
                                <div className={classes.name}>
                                  <Tooltip
                                    title={
                                      data.templateName.length > 18
                                        ? data.templateName
                                        : ""
                                    }
                                  >
                                    <div>
                                      {" "}
                                      <div
                                        style={{
                                          maxWidth: "190px",
                                          overflow: "hidden",
                                        }}
                                      >
                                        {/* {data.templateName.length > 20
                                         ? data.templateName.slice(0, 17) 
                                         : data.templateName} */}
                                        {data.templateName.length > 18
                                          ? data.templateName.slice(0, 17) +
                                            "..."
                                          : data.templateName === ""
                                          ? "-"
                                          : data.templateName}
                                      </div>{" "}
                                    </div>
                                  </Tooltip>
                                </div>
                                <div className={classes.createdBy}>
                                  {data.addedBy?.charAt(0).toUpperCase() +
                                    data.addedBy?.slice(1)}{" "}
                                  created this on -{" "}
                                  {data.createdAt !== ""
                                    ? formatDate(data.createdAt.slice(0, 10))
                                    : "-"}
                                </div>
                                {/* <img src={`data:image/jpeg;base64,${data.encodedhtml}`} alt="" /> */}
                                <div className={classes.dateCreated}>
                                  {" "}
                                  Last Modified -
                                  {data.modifiedAt !== ""
                                    ? formatDate(data.modifiedAt?.slice(0, 10))
                                    : "-"}
                                </div>
                                <div className={classes.buttons}>
                                  <div
                                    className={classes.viewButtonEdit}
                                    onClick={() =>
                                      handleLoadTemplate(data.templateName)
                                    }
                                    onMouseEnter={() => setHoveredButton(2)}
                                    onMouseLeave={() => setHoveredButton(0)}
                                  >
                                    {" "}
                                    <Tooltip title="Edit">
                                      <EditIcon
                                        fontSize="small"
                                        sx={{
                                          fontSize: "16px",
                                          color:
                                            hoveredButton === 2 ? "white" : "",
                                        }}
                                      />
                                    </Tooltip>
                                  </div>
                                  <div
                                    className={classes.viewButton}
                                    onClick={() => {
                                      handleClickOpenClone(data.templateName);
                                      setOldTemplateCategory(
                                        data.templateCategory
                                      );
                                    }}
                                    style={{
                                      borderRadius: "0px 0px 0px 0px",
                                    }}
                                    onMouseEnter={() => setHoveredButton(1)}
                                    onMouseLeave={() => setHoveredButton(0)}
                                  >
                                    <Tooltip title="Clone">
                                      <ContentCopyIcon
                                        fontSize="small"
                                        sx={{
                                          fontSize: "16px",
                                          color:
                                            hoveredButton === 1 ? "white" : "",
                                        }}
                                      />
                                    </Tooltip>
                                    {/* <div>Delete</div> */}
                                  </div>
                                  <div
                                    className={classes.deleteButton}
                                    onClick={() => {
                                      handleDeleteTemplate(data.templateName);
                                      //   setDeleteTemplate(data.templateName);
                                    }}
                                    style={{
                                      borderRadius: "0px 6px 6px 0px",
                                    }}
                                    onMouseEnter={() => setHoveredButton(3)}
                                    onMouseLeave={() => setHoveredButton(0)}
                                  >
                                    <Tooltip title="Delete">
                                      <DeleteIcon
                                        fontSize="small"
                                        sx={{
                                          fontSize: "16px",
                                          color:
                                            hoveredButton === 3
                                              ? "#FF5151"
                                              : "",
                                        }}
                                      />
                                    </Tooltip>
                                  </div>
                                </div>
                                <div
                                  className={classes.datemodified}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                >
                                  {" "}
                                  Label :
                                  <span
                                    style={{
                                      fontWeight: 600,
                                      fontSize: "13px",
                                      marginLeft: "0px",
                                    }}
                                  >
                                    {data.templateLabel === ""
                                      ? "-"
                                      : data.templateLabel}
                                  </span>
                                  <Tooltip title="Edit Label">
                                    <div
                                      onClick={(event: any) => {
                                        handleOpenEditLabelClick(data);
                                      }}
                                    >
                                      <EditIcon sx={{ fontSize: "16px" }} />
                                    </div>
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                          </Grid>
                        </>
                      ))}
                      {filteredData.length === 0 && (
                        <div
                          className={classes.cardContainer}
                          style={{
                            border: "none",
                            background: "none",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "60%",
                            padding: "0",
                            boxShadow: "none",
                            cursor: "pointer",
                            // height:'300px'
                          }}
                        >
                          No Results Found
                        </div>
                      )}
                    </Grid>
                  </Box>
                ) : (
                  <div className={classes.wrapper}>
                    <img
                      src={NoTemplateImage}
                      alt=""
                      className={classes.noTemplateImage}
                      onClick={() => {
                        setcategoryModal(true);
                        // handleGenerateRandomName();
                      }}
                    />
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <TemplatesDataTableComp
            data={categoryDetailsList}
            columns={columns}
            loading={undefined}
          />
        )}
      </div>

      {categoryModal && (
        <CreateCategory
          open={categoryModal}
          autoCompleteValue={autoCompleteValue}
          handleClose={() => {
            setcategoryModal(false);
          }}
        />
      )}
      <ThemeProvider theme={theme}>
        <Dialog
          open={openEditTemplateName.status}
          PaperProps={{
            sx: {
              borderRadius: "20px",
              padding: "0px 10px 10px 10px",
              width: "700px",
            },
          }}
        >
          <DialogTitle>Edit template Category</DialogTitle>
          <DialogContent>
            <DialogContentText mb={2}>
              Please enter new category name here.
            </DialogContentText>
            <div>
              <p className={classes.lableText}>Template Category Name</p>
              <TextField
                margin="dense"
                id="name"
                // label="Old Template Name"
                type="text"
                fullWidth
                size="small"
                value={openEditTemplateName.name}
                placeholder="Old Template Name"
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    height: "40px",
                    padding: "0px 10px",
                    borderRadius: "5px",
                  },
                }}
                sx={{
                  background: "#EDF2F7",
                  borderRadius: "5px",
                  "& label.Mui-focused": {
                    color: "grey",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#EDF2F7",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#EDF2F7",
                    },
                  },
                }}
              />
            </div>
            <div style={{ marginTop: "10px" }}>
              <p className={classes.lableText}>New template Category Name</p>
              <TextField
                focused
                autoFocus
                margin="dense"
                id="name"
                // label="New Template Name"
                type="text"
                fullWidth
                size="small"
                value={newTemplatename}
                onChange={(event) => {
                  // const newValue = event.target.value.replace(/\s/g, "_");
                  const inputValue = event.target.value;
                  const isValidInput = /^[a-zA-Z0-9]*$/.test(inputValue);
                  if (isValidInput) {
                    setNewTemplateName(inputValue);
                  }
                  // setNewTemplateName(newValue);
                }}
                placeholder="Enter New Category Name"
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    height: "40px",
                    padding: "0px 10px",
                    borderRadius: "5px",
                  },
                }}
                sx={{
                  background: "#EDF2F7",
                  borderRadius: "5px",
                  "& label.Mui-focused": {
                    color: "grey",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#EDF2F7",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#EDF2F7",
                    },
                  },
                }}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseEditTemplate}
              sx={{ textTransform: "none" }}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleSubmitEditTemplateCategory();
              }}
              disabled={newTemplatename === ""}
              variant="contained"
              sx={{ textTransform: "none" }}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
      <ThemeProvider theme={theme}>
        <Dialog
          open={openlableedit}
          PaperProps={{
            sx: {
              borderRadius: "20px",
              padding: "0px 10px 10px 10px",
              width: "700px",
            },
          }}
        >
          <DialogTitle>Edit Template Label</DialogTitle>
          <DialogContent>
            <DialogContentText mb={2}>
              Please enter new Label name here.
            </DialogContentText>
            <div>
              <p className={classes.lableText}>Template Label Name</p>
              <TextField
                margin="dense"
                id="name"
                // label="Old Template Name"
                type="text"
                fullWidth
                size="small"
                value={oldlabelname}
                placeholder="Old Label Name"
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    height: "40px",
                    padding: "0px 10px",
                    borderRadius: "5px",
                  },
                }}
                sx={{
                  background: "#EDF2F7",
                  borderRadius: "5px",
                  "& label.Mui-focused": {
                    color: "grey",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#EDF2F7",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#EDF2F7",
                    },
                  },
                }}
              />
            </div>
            <div style={{ marginTop: "10px" }}>
              <p className={classes.lableText}>New Template Label Name</p>
              <TextField
                focused
                autoFocus
                margin="dense"
                id="name"
                // label="New Template Name"
                type="text"
                fullWidth
                size="small"
                value={newLabelName}
                onChange={(event) => {
                  // const newValue = event.target.value.replace(/\s/g, "_");
                  const inputValue = event.target.value;
                  const isValidInput = /^[a-zA-Z0-9]*$/.test(inputValue);
                  // if (isValidInput) {
                  setNewLabelName(inputValue);
                  // }
                  // setNewTemplateName(newValue);
                }}
                placeholder="Enter New Label Name"
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    height: "40px",
                    padding: "0px 10px",
                    borderRadius: "5px",
                  },
                }}
                inputProps={{ maxLength: 15 }}
                sx={{
                  background: "#EDF2F7",
                  borderRadius: "5px",
                  "& label.Mui-focused": {
                    color: "grey",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#EDF2F7",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#EDF2F7",
                    },
                  },
                }}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseLabelEdit}
              sx={{ textTransform: "none" }}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleEditTemplateLabel();
              }}
              disabled={newLabelName === ""}
              variant="contained"
              sx={{ textTransform: "none" }}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
      <ThemeProvider theme={theme}>
        <Dialog
          open={openClone}
          PaperProps={{
            sx: { borderRadius: "20px", padding: "0px 10px 10px 10px" },
          }}
        >
          <DialogTitle>Clone Template</DialogTitle>
          <DialogContent>
            <DialogContentText mb={2}>
              To clone this template, please enter new Template name here.
            </DialogContentText>
            <div>
              <p className={classes.lableText}>New Template Name</p>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                placeholder="Enter New Template Name"
                type="text"
                fullWidth
                size="small"
                value={clonedCamp}
                onChange={(event) => {
                  const newValue = event.target.value.replace(/\s/g, "_");
                  setClonedCamp(newValue);
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    height: "40px",
                    padding: "0px 10px",
                    borderRadius: "10px",
                  },
                }}
                sx={{
                  background: "#EDF2F7",
                  borderRadius: "10px",
                  "& label.Mui-focused": {
                    color: "grey",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#EDF2F7",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#EDF2F7",
                    },
                  },
                }}
              />
            </div>
            <div style={{ width: "100%", marginTop: "20px" }}>
              <p className={classes.lableText}>Select Category</p>
              <Autocomplete
                size="small"
                fullWidth
                value={autoCompleteValueClone}
                options={categoryDetailsList.map((a: any) => a.templateCategoryName)}
                onChange={(event, value) => setAutoCompleteValueClone(value)}
                // onInputChange={(event, value) => console.log(value)}

                sx={{
                  marginLeft: "0px",
                  marginTop: "10px",
                  borderRadius: "10px",
                  width: "100%",
                  background: "#EDF2F7",
                  outline: "none",
                  fontSize: "16px",
                  fontWeight: 500,
                  padding: "0 10px",
                  color: "#000000",
                  boxShadow: "none",
                  "& .MuiOutlinedInput-input": {
                    // padding: "4.5px 4px 2.5px 8px",
                    paddingTop: "2px !important",
                    cursor: "pointer",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                }}
                id="disable-clearable"
                popupIcon={
                  <KeyboardArrowDownIcon
                    style={{ cursor: "pointer" }}
                    fontSize="small"
                  />
                }
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Category"
                    // label="Select Category"
                    fullWidth
                  />
                )}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseClone}>Cancel</Button>
            <Button
              onClick={() => {
                handleClone();
              }}
              disabled={clonedCamp === ""}
              variant="outlined"
            >
              Clone
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </div>
  );
}
