import React, {useState, useEffect} from 'react';
import classes from "./ApprovalsTab.module.scss";
import SmsClientsData from '../SmsClientsData/SmsClientsData';
import SmsTemplatesApproval from '../SmsTemplatesApproval/SmsTemplatesApproval';
import Button from '@mui/material/Button';

const ApprovalsTab = ({data}:any) => {
  const [tab, settab]= useState(1);

  const tabAction = (tabNumber:any) => {
    settab(tabNumber);
  }

  return (
    <>
    <div className={classes.buttons}>
    <Button onClick={() => {
      tabAction(1);
    }} 
    style={{
      color: tab === 1 ? 'white' : 'black',
      backgroundColor: tab === 1 ? '#4C6FFF' : '#f5f5f5',
    }}>Sender Id</Button>
    <Button onClick={() => {
      tabAction(2);
    }}
    style={{
      color: tab === 2 ? 'white' : 'black',
            backgroundColor: tab === 2 ? '#4C6FFF' : '#f5f5f5',
    }}
    >Template Id</Button>
    </div>

    <div className={classes.contentWrapper}>
    {tab==1 && <SmsClientsData />}
    {tab==2 && <SmsTemplatesApproval />}
    </div>
    </>
  )
}

export default ApprovalsTab