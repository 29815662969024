import React, { useEffect, useState } from "react";
import styles from "./CreateCategory.module.scss";
import previewImage from "../../../assets/Images/PreviewCampaignImage.svg";
import EditIcon from "@mui/icons-material/Edit";
import UpdateIcon from "@mui/icons-material/Update";
import Radio from "@mui/material/Radio";
import Autocomplete from "@mui/material/Autocomplete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TextField from "@mui/material/TextField";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Box, Button, Modal, ThemeProvider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  CreateTemplateCategoryAPICall,
  GetTemplateCategoryDetailsAPICall,
} from "../../../Utils/Apihelper";
import { Toaster, toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { theme } from "../../../components/ThemeProvider/ThemeProvider";

const options3 = ["transactional", "promotional"];
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  height: "max-content",
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
};

interface props {
  open: any;
  handleClose: any;
  autoCompleteValue: any;
}

const CreateCategory = ({ open, handleClose, autoCompleteValue }: props) => {
  const [selectedValue, setSelectedValue] = useState(
    "Import In existing category "
  );
  const [categoryList, setCategoryList] = useState([]);
  const [categoryName, setCategoryName] = useState(autoCompleteValue);
  const [templateType, setTemplateType] = useState(options3[1]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const templateName = useSelector((state: any) => state.project.templatename);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const handleInputChange = (event: any) => {
    const inputValue = event.target.value;
    const isValidInput = /^[a-zA-Z0-9]*$/.test(inputValue);
    if (isValidInput) {
      setCategoryName(inputValue);
    }
  };

  useEffect(() => {
    handleGetAllCatList();
  }, []);

  const handleGetAllCatList = () => {
    GetTemplateCategoryDetailsAPICall()
      .then((res: any) => {
        // console.log(res.data);
        setCategoryList(res.data.data);
      })
      .catch((res: any) => {
        // console.log(res.data);
        // toast.error("Category Fetch failed");
      });
  };

  const handleCreateTemplateCategory = () => {
    if (selectedValue === "Create a new category") {
      const requestbody = {
        templateCategoryName: categoryName,
      };
      CreateTemplateCategoryAPICall(requestbody)
        .then((res: any) => {
          sessionStorage.setItem("editFlag", "false");
          sessionStorage.setItem("TemplateLabel", "");
          sessionStorage.setItem("TemplateCategory", categoryName);
          sessionStorage.setItem("TemplateName", "");
          toast.success("Successfully Created Template category");

          navigate("/dashboard/templates/emailTemplateEditor");
        })
        .catch((res) => {
          toast.error("Error Creating Template category");
        });
    } else {
      sessionStorage.setItem("TemplateName", "");
      sessionStorage.setItem("TemplateLabel", "");
      sessionStorage.setItem("TemplateCategory", categoryName);
      toast.success(`Template will be saved in ${categoryName}`);
      navigate("/dashboard/templates/emailTemplateEditor");
    }
  };

  return (
    <>
      <Toaster />
      <ThemeProvider theme={theme}>
        <Modal
          disableAutoFocus={true}
          open={open}
          onClose={handleClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={style}>
            <div className={styles.wrapper}>
              <div className={styles.container}>
                <div className={styles.header}>Create Template </div>
                <div className={styles.para}>
                  Create your template into an existing category or create a new
                  category and proceed with the design process.
                </div>
                <div className={styles.radioWrap}>
                  Categories
                  <RadioGroup
                    row
                    defaultValue="Save In existing category "
                    name="radio-buttons-group"
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="Create a new category"
                      control={<Radio />}
                      label="Create a new category"
                    />
                    <FormControlLabel
                      value="Save In existing category "
                      control={<Radio />}
                      label="Save In existing category "
                    />
                  </RadioGroup>
                  {/* <div className={styles.radio1}>Create a new group</div>
              <div className={styles.radio2}>Import In existing group </div> */}
                </div>
                <div className={styles.input}>
                  {selectedValue === "Create a new category" ? (
                    <>
                      Create new category
                      <TextField
                        placeholder="Category Name"
                        value={categoryName}
                        onChange={handleInputChange}
                        id="outlined-size-small"
                        size="small"
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                          sx: {
                            height: "40px",
                            padding: "0px 10px",
                            borderRadius: "5px",
                          },
                        }}
                        sx={{
                          marginTop: "12px",
                          width: "-webkit-fill-available",
                          background: "#EDF2F7",
                          borderRadius: "5px",
                          "& label.Mui-focused": {
                            color: "grey",
                          },
                          "& .MuiInput-underline:after": {
                            borderBottomColor: "#EDF2F7",
                          },
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "#EDF2F7",
                            },
                          },
                        }}
                      />
                    </>
                  ) : (
                    <>
                      Select a category
                      <Autocomplete
                        size="small"
                        options={categoryList.map(
                          (a: any) => a.templateCategoryName
                        )}
                        sx={{
                          borderRadius: "10px",
                          marginTop: "12px",
                          width: "-webkit-fill-available",
                          background: "#EDF2F7",
                          outline: "none",
                          fontSize: "16px",
                          fontWeight: 500,
                          padding: "0 10px",
                          color: "#000000",
                          boxShadow: "none",
                          "& .MuiOutlinedInput-input": {
                            // padding: "4.5px 4px 2.5px 8px",
                            paddingTop: "2px !important",
                            cursor: "pointer",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: 0,
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            border: 0,
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: 0,
                          },
                        }}
                        id="disable-clearable"
                        popupIcon={
                          <KeyboardArrowDownIcon
                            style={{ cursor: "pointer" }}
                            fontSize="small"
                          />
                        }
                        value={categoryName}
                        onChange={(event, newValue) =>
                          setCategoryName(newValue)
                        }
                        disableClearable
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select category"
                          />
                        )}
                      />
                    </>
                  )}
                </div>
              </div>
              <div className={styles.buttons}>
                <div className={styles.cancelBtn} onClick={handleClose}>
                  Cancel
                </div>
                <div
                // className={styles.createBtn}
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleCreateTemplateCategory();
                    }}
                    disabled={categoryName === ""}
                    sx={{ textTransform: "none" }}
                  >
                    Create
                  </Button>
                  {/* Create{" "} */}
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </ThemeProvider>
    </>
  );
};

export default CreateCategory;
