import React, { useState, useEffect } from "react";
import classes from "./SMSDetails.module.scss";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import StyledLoader from "../../../components/Styledloader/StyledLoader";
import * as moment from "moment-timezone";
import DataTable from "react-data-table-component";
import CloseIcon from "@mui/icons-material/Close";
import { InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const SMSDetails = ({ data }: any) => {
  const [open, setOpen] = React.useState(false);
  const [opened, setOpened] = React.useState(false);
  const [rejectionMessage, setRejectionMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [route, setRoute] = useState<any>();
  const [dcs, setDcs] = useState<any>();
  const [flashsms, setFlashSms] = useState<any>();
  const [username, setusername] = useState<any>();
  const [password, setpassword] = useState<any>();
  const [imageData, setImageData] = useState({ base64: "", format: "" });
  const [opens, setOpens] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleClickApproveOpen = () => {
    setOpened(true);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleClose = () => {
    setRejectionMessage("");
    setErrorMessage("");
    setOpen(false);
    setOpened(false);
    setRoute("");
    setDcs("");
    setFlashSms("");
    setusername("");
    setpassword("");
  };

  const handleViewClick = (imageUrl: any) => {
    setSelectedImage(imageUrl);
    setOpens(true);
  };

  const handleClosed = () => {
    setOpens(false);
    setSelectedImage("");
  };

  const handleApprove = () => {
    // approveId();
  };

  const handleReject = () => {
    // rejectId();
  };
  useEffect(() => {
    const { base64, format } = data?.smsDetails?.documents?.dlt;
    setImageData({ base64, format });
  }, []);

  const imageUrl = `data:image/${imageData.format};base64,${imageData.base64}`;

  const customStyles = {
    table: {
      style: {
        borderRadius: "8px solid grey",
        "&:nth-last-child(1)": {
          borderBottom: "none !important",
          border: "1px solid grey",
        },
      },
    },
    headCells: {
      style: {
        paddingLeft: "16px",
        paddingRight: "10px",
        fontWeight: "600",
      },
    },
    headRow: {
      style: {
        backgroundColor: "#F7F9FC",
        borderBottom: "1px solid #9AAAEA ",
      },
    },
    header: {
      style: {
        fontSize: "16px",
        minHeight: "0px",
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingTop: "16px",
        paddingBottom: "16px",
        wordBreak: "break-word",
      },
    },
    rows: {
      style: {
        borderBottom: "1px solid #9AAAEA !important",
        "&:hover": {
          backgroundColor: "#E7F0FF",
        },
        "&:nth-last-child(1)": {
          borderBottom: "none !important",
        },
      },
    },
    contextMenu: {
      style: {
        fontSize: "13px",
        fontWeight: 400,
        paddingLeft: "16px",
        paddingRight: "8px",
        transform: "translate3d(0, -100%, 0)",
        transitionDuration: "125ms",
        transitionTimingFunction: "cubic-bezier(0, 0, 0.2, 1)",
        willChange: "transform",
      },
      activeStyle: {
        transform: "translate3d(0, 0, 0)",
      },
    },
  };

  const columns: any = [
    {
      name: (
        <div style={{ fontSize: "13px", color: "black", marginLeft: "10px" }}>
          Requested ID
        </div>
      ),
      selector: (row: any) => row.requestId,
      sortable: true,
      cell: (row: any) => (
        <div style={{ fontSize: "13px", color: "#5FC322", marginLeft: "10px" }}>
          {row.requestId || "-"}
        </div>
      ),
    },
    {
      name: (
        <div style={{ fontSize: "13px", color: "black" }}>
          Requested timestamp
        </div>
      ),
      selector: (row: any) => row.requestedTS,
      sortable: false,
      cell: (row: any) => (
        <div style={{ fontSize: "13px", textTransform: "capitalize" }}>
          {formatDate(row.requestedTS)}
        </div>
      ),
    },
    {
      name: (
        <div style={{ fontSize: "13px", color: "black" }}>Transactional</div>
      ),
      selector: (row: any) => row.transactionalCount,
      sortable: false,
      cell: (row: any) => (
        <div style={{ fontSize: "13px", textTransform: "capitalize" }}>
          {row.transactionalCount}
        </div>
      ),
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Promotional</div>,
      selector: (row: any) => row.promotionalCount,
      sortable: false,
      cell: (row: any) => (
        <div style={{ fontSize: "13px", textTransform: "capitalize" }}>
          {row.promotionalCount}
        </div>
      ),
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Total Count</div>,
      selector: (row: any) => row.totalCount,
      sortable: false,
      cell: (row: any) => (
        <div style={{ fontSize: "13px", textTransform: "capitalize" }}>
          {row.totalCount}
        </div>
      ),
    },
    {
      name: (
        <div style={{ fontSize: "13px", color: "black" }}>Per Sms Charges</div>
      ),
      selector: (row: any) => row.perSMSCharges,
      sortable: false,
      cell: (row: any) => (
        <div style={{ fontSize: "13px", textTransform: "capitalize" }}>
          {row.perSMSCharges}
        </div>
      ),
    },
    {
      name: (
        <div style={{ fontSize: "13px", color: "black" }}>
          Approved timestamp
        </div>
      ),
      selector: (row: any) => row.approvedTS,
      sortable: false,
      cell: (row: any) => (
        <div style={{ fontSize: "13px", textTransform: "capitalize" }}>
          {formatDate(row.approvedTS)}
        </div>
      ),
    },
    {
      name: (
        <div style={{ fontSize: "13px", color: "black" }}>Approved User</div>
      ),
      selector: (row: any) => row.approvedUser,
      sortable: false,
      cell: (row: any) => (
        <div style={{ fontSize: "13px", textTransform: "capitalize" }}>
          {row.approvedUser}
        </div>
      ),
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Status</div>,
      selector: (row: any) => row.status,
      sortable: true,
      cell: (row: any) => (
        <div
          style={{
            fontSize: "12px",
            textTransform: "uppercase",
            background:
              row.status === "APPROVED"
                ? "#5FC322"
                : row.status === "CREATED"
                ? "#4C6FFF"
                : row.status === "REJECTED"
                ? "red"
                : "white",
            color: "white",
            padding: "0.5rem",
            minWidth: "85px",
            display: "flex",
            justifyContent: "center",
            borderRadius: "8px",
            fontWeight: "500",
          }}
        >
          {row.status}
        </div>
      ),
    },
  ];

  console.log("hi" + data);
  console.log("hello" + columns);

  const formatDate = (utcTime: any) => {
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localTime: any = moment.tz(utcTime, localTimeZone);
    const utcDate = new Date(localTime.format());
    const istDate = new Date(utcDate.getTime() + localTime._offset * 60 * 1000);

    const options: any = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };

    const formattedDate = istDate.toLocaleString("en-IN", options);

    return formattedDate.replace("am", "AM").replace("pm", "PM");
  };

  return (
    <>
      <div className={classes.pRight}>
        <div className={classes.pTop}>
          <div className={classes.pDetailsField}>
            Username
            <TextField
              placeholder=""
              id="outlined-size-small"
              size="small"
              variant="standard"
              value={data?.smsDetails?.username}
              //   onChange={handleEmailName}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
                sx: {
                  height: "40px",
                  padding: "0px 10px",
                  borderRadius: "5px",
                },
              }}
              sx={{
                marginTop: "8px",
                width: "350px",
                background: "#EDF2F7",
                borderRadius: "5px",
                "& label.Mui-focused": {
                  color: "grey",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#EDF2F7",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#EDF2F7",
                  },
                },
              }}
            />
          </div>
          <div className={classes.pDetailsField}>
            Password
            <TextField
              placeholder=""
              id="outlined-size-small"
              size="small"
              variant="standard"
              type={showPassword ? "text" : "password"}
              value={data?.smsDetails?.password}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
                sx: {
                  height: "40px",
                  padding: "0px 10px",
                  borderRadius: "5px",
                },
              }}
              sx={{
                marginTop: "8px",
                width: "350px",
                background: "#EDF2F7",
                borderRadius: "5px",
                "& label.Mui-focused": {
                  color: "grey",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#EDF2F7",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#EDF2F7",
                  },
                },
              }}
            />{" "}
          </div>
          <div className={classes.pDetailsField}>
            DCS
            <TextField
              placeholder=""
              id="outlined-size-small"
              size="small"
              variant="standard"
              value={data?.smsDetails?.dcs}
              //   onChange={handleEmailName}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
                sx: {
                  height: "40px",
                  padding: "0px 10px",
                  borderRadius: "5px",
                },
              }}
              sx={{
                marginTop: "8px",
                width: "350px",
                background: "#EDF2F7",
                borderRadius: "5px",
                "& label.Mui-focused": {
                  color: "grey",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#EDF2F7",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#EDF2F7",
                  },
                },
              }}
            />
          </div>
          <div className={classes.pDetailsField}>
            FlashSMS
            <TextField
              placeholder=""
              id="outlined-size-small"
              size="small"
              variant="standard"
              value={data?.smsDetails?.flashsms}
              //   onChange={handleEmailName}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
                sx: {
                  height: "40px",
                  padding: "0px 10px",
                  borderRadius: "5px",
                },
              }}
              sx={{
                marginTop: "8px",
                width: "350px",
                background: "#EDF2F7",
                borderRadius: "5px",
                "& label.Mui-focused": {
                  color: "grey",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#EDF2F7",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#EDF2F7",
                  },
                },
              }}
            />
          </div>
          <div className={classes.pDetailsField}>
            Route
            <TextField
              placeholder=""
              id="outlined-size-small"
              size="small"
              variant="standard"
              value={data?.smsDetails?.route}
              //   onChange={handleEmailName}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
                sx: {
                  height: "40px",
                  padding: "0px 10px",
                  borderRadius: "5px",
                },
              }}
              sx={{
                marginTop: "8px",
                width: "350px",
                background: "#EDF2F7",
                borderRadius: "5px",
                "& label.Mui-focused": {
                  color: "grey",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#EDF2F7",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#EDF2F7",
                  },
                },
              }}
            />
          </div>
          <div className={classes.pDetailsField}>
            Document
            <div
              className={classes.view}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              To view the image, Click on view -
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleViewClick(imageUrl)}
                style={{
                  width: "90px",
                  height: "30px",
                  marginTop: "10px",
                }}
              >
                View
              </Button>
              <Dialog open={opens} onClose={handleClosed}>
                <DialogTitle>
                  Image
                  <IconButton
                    edge="end"
                    color="inherit"
                    onClick={handleClosed}
                    aria-label="close"
                    style={{ position: "absolute", right: 8, top: 8 }}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <img
                    src={selectedImage}
                    alt="View"
                    style={{
                      width: "100%",
                      objectFit: "cover",
                      height: "100%",
                      padding: "100px",
                    }}
                  />
                </DialogContent>
              </Dialog>
            </div>
            {/* <img src={imageUrl} alt="Document" style={{
              height: "150px",
              width: "200px",
              marginTop: "10px"
            }}/> */}
          </div>
          {/* <div className={classes.pDetailsField}
            >
              Credit History
              <DataTable
              data={data?.smsDetails?.creditsHistory}
              columns={columns}
              title=" "
              pagination={true}
              selectableRows={false}
              customStyles={customStyles}
              fixedHeader
              progressComponent={<StyledLoader />}
            /> */}
          {/* {data?.smsDetails?.creditsHistory?.length > 0 ? (
        data?.smsDetails?.creditsHistory.map((historyItem:any, index:any) => (
          <div key={index} style={{ border: '1px solid #ccc', padding: '10px', marginBottom: '10px', marginTop: "10px" }}>
            <p className={classes.credithistory}><strong>Request ID:</strong> {historyItem.requestId}</p>
            <p className={classes.credithistory}><strong>Requested Timestamp:</strong> {historyItem.requestedTS}</p>
            <p className={classes.credithistory}><strong>Transactional Count:</strong> {historyItem.transactionalCount}</p>
            <p className={classes.credithistory}><strong>Promotional Count:</strong> {historyItem.promotionalCount}</p>
            <p className={classes.credithistory}><strong>Total Count:</strong> {historyItem.totalCount}</p>
            <p className={classes.credithistory}><strong>Per SMS Charges:</strong> {historyItem.perSMSCharges}</p>
            <p className={classes.credithistory}><strong>Approved Timestamp:</strong> {historyItem.approvedTS || 'N/A'}</p>
            <p className={classes.credithistory}><strong>Approved User:</strong> {historyItem.approvedUser || 'N/A'}</p>
            <p className={classes.credithistory}><strong>Status:</strong> {historyItem.status}</p>
          </div>
        ))
      ) : (
        <p className={classes.nocredits}>No credits history available.</p>
      )} */}
          {/* <TextField
                placeholder=""
                id="outlined-size-small"
                size="small"
                variant="standard"
                value={data?.smsDetails?.creditsHistory}
                //   onChange={handleEmailName}
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    height: "40px",
                    padding: "0px 10px",
                    borderRadius: "5px",
                  },
                }}
                sx={{
                  marginTop: "8px",
                  width: "-webkit-fill-available",
                  background: "#EDF2F7",
                  borderRadius: "5px",
                  "& label.Mui-focused": {
                    color: "grey",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#EDF2F7",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#EDF2F7",
                    },
                  },
                }}
              /> */}
          {/* </div> */}
        </div>
        <div className={classes.pBottom}>
          <div className={classes.pDetailsField}>
            DLT Id
            <TextField
              placeholder=""
              id="outlined-size-small"
              size="small"
              variant="standard"
              value={data?.smsDetails?.dltId}
              //   onChange={handleEmailName}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
                sx: {
                  height: "40px",
                  padding: "0px 10px",
                  borderRadius: "5px",
                },
              }}
              sx={{
                marginTop: "8px",
                width: "350px",
                background: "#EDF2F7",
                borderRadius: "5px",
                "& label.Mui-focused": {
                  color: "grey",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#EDF2F7",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#EDF2F7",
                  },
                },
              }}
            />
          </div>
          <div className={classes.pDetailsField}>
            Transactional Count
            <TextField
              placeholder=""
              id="outlined-size-small"
              size="small"
              variant="standard"
              value={data?.subscription?.products?.sms?.transactionalCount}
              //   onChange={handleEmailName}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
                sx: {
                  height: "40px",
                  padding: "0px 10px",
                  borderRadius: "5px",
                },
              }}
              sx={{
                marginTop: "8px",
                width: "350px",
                background: "#EDF2F7",
                borderRadius: "5px",
                "& label.Mui-focused": {
                  color: "grey",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#EDF2F7",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#EDF2F7",
                  },
                },
              }}
            />
          </div>
          <div className={classes.pDetailsField}>
            Promotional Count
            <TextField
              placeholder=""
              id="outlined-size-small"
              size="small"
              variant="standard"
              value={data?.subscription?.products?.sms?.promotionalCount}
              //   onChange={handleEmailName}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
                sx: {
                  height: "40px",
                  padding: "0px 10px",
                  borderRadius: "5px",
                },
              }}
              sx={{
                marginTop: "8px",
                width: "350px",
                background: "#EDF2F7",
                borderRadius: "5px",
                "& label.Mui-focused": {
                  color: "grey",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#EDF2F7",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#EDF2F7",
                  },
                },
              }}
            />
          </div>
          <div className={classes.pDetailsField}>
            Per Sms Charges
            <TextField
              placeholder=""
              id="outlined-size-small"
              size="small"
              variant="standard"
              value={data?.subscription?.products?.sms?.perSMSCharges}
              //   onChange={handleEmailName}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
                sx: {
                  height: "40px",
                  padding: "0px 10px",
                  borderRadius: "5px",
                },
              }}
              sx={{
                marginTop: "8px",
                width: "350px",
                background: "#EDF2F7",
                borderRadius: "5px",
                "& label.Mui-focused": {
                  color: "grey",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#EDF2F7",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#EDF2F7",
                  },
                },
              }}
            />
          </div>
          <div className={classes.pDetailsField}>
            Approved timestamp
            <TextField
              placeholder=""
              id="outlined-size-small"
              size="small"
              variant="standard"
              value={formatDate(data?.smsDetails?.approvedTS)}
              //   onChange={handleEmailName}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
                sx: {
                  height: "40px",
                  padding: "0px 10px",
                  borderRadius: "5px",
                },
              }}
              sx={{
                marginTop: "8px",
                width: "350px",
                background: "#EDF2F7",
                borderRadius: "5px",
                "& label.Mui-focused": {
                  color: "grey",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#EDF2F7",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#EDF2F7",
                  },
                },
              }}
            />
          </div>
        </div>
        {/* <div className={classes.pDetailsField}>
              Subscription History
              <TextField
                placeholder=""
                id="outlined-size-small"
                size="small"
                variant="standard"
                value={data?.subscription?.products?.sms?.subscriptionHistory}
                //   onChange={handleEmailName}
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    height: "40px",
                    padding: "0px 10px",
                    borderRadius: "5px",
                  },
                }}
                sx={{
                  marginTop: "8px",
                  width: "-webkit-fill-available",
                  background: "#EDF2F7",
                  borderRadius: "5px",
                  "& label.Mui-focused": {
                    color: "grey",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#EDF2F7",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#EDF2F7",
                    },
                  },
                }}
              />
            </div> */}
        {/* <div className={classes.pbuttons}>
              <div className={classes.pApprove}><Button variant="contained"
              onClick={() => {
                handleClickApproveOpen();
              }}
              >Approve</Button></div>
              <Dialog
        open={opened}
        onClose={handleClose}
      >
        <DialogTitle>Data</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To approve, please enter all the details. 
          </DialogContentText>
          <TextField
            
            required
            margin="dense"
            label="Route"
            type="text"
            fullWidth
            variant="standard"
            value={route}
            onChange={(e:any) => {
              setRoute(e.target.value);
            }}
          />
           <TextField
            autoFocus
            required
            margin="dense"
            label="Flash Sms"
            type="text"
            fullWidth
            variant="standard"
            value={flashsms}
            onChange={(e:any) => {
              setFlashSms(e.target.value);
            }}
          />
           <TextField
            autoFocus
            required
            margin="dense"
            label="DCS"
            type="text"
            fullWidth
            variant="standard"
            value={dcs}
            onChange={(e:any) => {
              setDcs(e.target.value);
            }}
          />
           <TextField
            autoFocus
            required
            margin="dense"
            label="Username"
            type="text"
            fullWidth
            variant="standard"
            value={username}
            onChange={(e:any) => {
              setusername(e.target.value);
            }}
          />
           <TextField
            autoFocus
            required
            margin="dense"
            label="Password"
            type="text"
            fullWidth
            variant="standard"
            value={password}
            onChange={(e:any) => {
              setpassword(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit"
          onClick={() => {
            handleClose();
            handleApprove();
          }}
          >Approve</Button>
        </DialogActions>
      </Dialog>
              <div className={classes.pReject}><Button variant="outlined"
               onClick={() => {
                handleClickOpen();
               
              }}
              >Reject</Button></div>
               <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0)', 
            backdropFilter: 'blur(5px)',
          },
        }}
        PaperProps={{
          elevation: 0,
          style: {
            boxShadow: '0 1px 1px rgba(0,0,0,0.11), 0 2px 2px rgba(0,0,0,0.11), 0 4px 4px rgba(0,0,0,0.11), 0 8px 8px rgba(0,0,0,0.11), 0 16px 16px rgba(0,0,0,0.11), 0 32px 32px rgba(0,0,0,0.11)',
          },
        }}
        
      >
        <DialogTitle sx={{fontSize: '15px'}}>Why are you rejecting?</DialogTitle>
              <DialogContent>
                <Box>
              <TextField
              fullWidth
              multiline
              rows={4} 
              variant="outlined"
              value={rejectionMessage}
              onChange={(e) => setRejectionMessage(e.target.value)}
              sx={{
                height: '70%', 
                '& .MuiInputBase-root': {
                  height: '100%', 
                  alignItems: 'flex-start', 
                },
              }}
            />
          </Box>
              </DialogContent>
              {errorMessage && (
          <Typography color="error" variant="body2" sx={{  marginLeft: '30px' }}>
            {errorMessage}
          </Typography>
        )}
              <DialogActions>
                <Button onClick={() => {
                  handleClose();
                }}>Cancel</Button>
                <Button
                  onClick={() => {
                    handleRejectData();
                    handleReject();
                  }}

                  sx={{color: 'red'}}
                >
                  Reject
                </Button>
              </DialogActions>
      </Dialog>
            </div> */}
      </div>
    </>
  );
};

export default SMSDetails;
