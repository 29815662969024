import React, { useState, useRef, useEffect } from "react";
import EmailEditor from "react-email-editor";
import classes from "./EmailtemplateEditor.module.scss";
import { toPng } from "html-to-image";
import { TextField, FormHelperText } from "@mui/material";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { useDispatch, useSelector } from "react-redux";
import { setCollapsed } from "../../../Store/Project/ProjectSlice";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import {
  CreateTemplateAPICall,
  EditTemplateAPICall,
} from "../../../Utils/Apihelper";
import { Toaster, toast } from "react-hot-toast";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import "./EmailTemplateEditor.css";
import { ThemeProvider } from "@emotion/react";
import { theme } from "../../../components/ThemeProvider/ThemeProvider";

const fonts = [
  {
    label: "Comic Sans",
    value: "'Comic Sans MS', cursive, sans-serif",
  },
  {
    label: "Lobster Two",
    value: "'Lobster Two',cursive",
    url: "https://fonts.googleapis.com/css?family=Lobster+Two:400,700",
  },
  {
    label: "Anton",
    value: "'Anton', sans-serif",
    url: "https://fonts.googleapis.com/css?family=Anton",
  },
  {
    label: "Georgia",
    value: "Georgia, Times, 'Times New Roman', serif",
  },
  {
    label: "Helvetica",
    value: "'Helvetica Neue', Helvetica, Arial, sans-serif",
  },
  {
    label: "Lucida",
    value: "'Lucida Grande', 'Lucida Sans', Geneva, Verdana, sans-serif",
  },
  {
    label: "Lato",
    value: "'Lato', Tahoma, Verdana, sans-serif",
    url: "https://fonts.googleapis.com/css?family=Lato",
  },
  {
    label: "Andale Mono",
    value: "andale mono,times",
  },
  {
    label: "Arial",
    value: "arial,helvetica,sans-serif",
  },
  {
    label: "Arial Black",
    value: "arial black,avant garde,arial",
  },
  {
    label: "Book Antiqua",
    value: "book antiqua,palatino",
  },
  {
    label: "Comic Sans MS",
    value: "comic sans ms,sans-serif",
  },
  {
    label: "Courier New",
    value: "courier new,courier",
  },
  {
    label: "Georgia",
    value: "georgia,palatino",
  },
  {
    label: "Helvetica",
    value: "helvetica,sans-serif",
  },
  {
    label: "Impact",
    value: "impact,chicago",
  },
  {
    label: "Symbol",
    value: "symbol",
  },
  {
    label: "Tahoma",
    value: "tahoma,arial,helvetica,sans-serif",
  },
  {
    label: "Terminal",
    value: "terminal,monaco",
  },
  {
    label: "Times New Roman",
    value: "times new roman,times",
  },
  {
    label: "Trebuchet MS",
    value: "trebuchet ms,geneva",
  },
  {
    label: "Verdana",
    value: "verdana,geneva",
  },
  {
    label: "Lobster Two",
    value: "'Lobster Two',cursive",
    url: "https://fonts.googleapis.com/css?family=Lobster+Two:400,700",
  },
  {
    label: "Playfair Display",
    value: "'Playfair Display',serif",
    url: "https://fonts.googleapis.com/css?family=Playfair+Display:400,700",
  },
  {
    label: "Rubik",
    value: "'Rubik',sans-serif",
    url: "https://fonts.googleapis.com/css?family=Rubik:400,700",
  },
  {
    label: "Source Sans Pro",
    value: "'Source Sans Pro',sans-serif",
    url: "https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700",
  },
  {
    label: "Open Sans",
    value: "'Open Sans',sans-serif",
    url: "https://fonts.googleapis.com/css?family=Open+Sans:400,700",
  },
  {
    label: "Crimson Text",
    value: "'Crimson Text',serif",
    url: "https://fonts.googleapis.com/css?family=Crimson+Text:400,700",
  },
  {
    label: "Montserrat",
    value: "'Montserrat',sans-serif",
    url: "https://fonts.googleapis.com/css?family=Montserrat:400,700",
  },
  {
    label: "Old Standard TT",
    value: "'Old Standard TT',serif",
    url: "https://fonts.googleapis.com/css?family=Old+Standard+TT:400,700",
  },
  {
    label: "Lato",
    value: "'Lato',sans-serif",
    url: "https://fonts.googleapis.com/css?family=Lato:400,700",
  },
  {
    label: "Raleway",
    value: "'Raleway',sans-serif",
    url: "https://fonts.googleapis.com/css?family=Raleway:400,700",
  },
  {
    label: "Cabin",
    value: "'Cabin',sans-serif",
    url: "https://fonts.googleapis.com/css?family=Cabin:400,700",
  },
  {
    label: "Pacifico",
    value: "'Pacifico',cursive",
    url: "https://fonts.googleapis.com/css?family=Pacifico",
  },
];

const EmailTemplateEditor = () => {
  const editlabel = sessionStorage.getItem("TemplateLabel");
  const tempName = sessionStorage.getItem("TemplateName");
  const emailEditorRef = useRef();
  const textFieldRef = useRef(null);
  const elementRef = useRef(null);
  const [html, setHtml] = useState("");
  const [encodedhtml, setEncodedHtml] = useState("");
  const [json, setJson] = useState("");
  const [urll, setUrll] = useState("");
  const [saveLoad, setSaveLoad] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [confirmSave, setConfirmSave] = React.useState(false);
  const editTemp = sessionStorage.getItem("editFlag");

  const loadJson = useSelector((state) => state.project.loadTemplateJson);
  const [templateNameEdited, setTemplateNameEdited] = useState(
    tempName ? tempName : ""
  );
  const [labelName, setLabelName] = useState(editlabel ? editlabel : "");

  console.log("editlabel",editlabel,"tempName",tempName)

  // useEffect(() => {
  //   var div = document.createElement("div");
  //   div.setAttribute("ref", elementRef);
  //   div.innerHTML = html;
  // }, [html]);

  useEffect(() => {
    elementRef.current.innerHTML = html;
  }, [html]);

  useEffect(() => {
    dispatch(setCollapsed(true));
    // setTemplateNameEdited(sessionStorage.getItem("TemplateName"));
  }, []);

  const handleCloseSave = () => {
    setConfirmSave(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      setHtml(html);
    });
  };

  // const htmlToImageConvert = () => {
  //   elementRef.current.className = "displayAll";
  //   console.log("elementRef.current", elementRef.current);
  //   toPng(elementRef.current, { cacheBust: false })
  //     .then((dataUrl) => {
  //       setUrll(dataUrl);
  //       const link = document.createElement("a");
  //       link.download = "my-image-name.png";
  //       link.href = dataUrl;
  //       link.click();
  //       elementRef.current.className = "displayNone";
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const htmlToImageConvert = (htmlParam) => {
    elementRef.current.innerHTML = htmlParam;
    elementRef.current.className = "displayAll";
    toPng(elementRef.current, { cacheBust: false })
      .then((dataUrl) => {
        setUrll(dataUrl);
        elementRef.current.className = "displayNone";
      })
      .catch((err) => {
        //  console.log("OOps something went wrong");
      });
  };

  const onLoad = () => {
    editTemp === "true" &&
      emailEditorRef.current?.editor.loadDesign(saveLoad ? json : loadJson);
  };

  const onReady = () => {
    emailEditorRef.current.editor.setBodyValues({
      backgroundColor: "white",
      fontFamily: {
        label: "Times New Roman",
        value: "times new roman,times",
      },
    });
  };

  // const handleInputChange = (event) => {
  //   setTemplateNameEdited(event.target.value);
  // };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;

    const isValidInput = /^[a-zA-Z0-9]*$/.test(inputValue);

    if (isValidInput) {
      setTemplateNameEdited(inputValue);
    }

    if (!isValidInput) {
      textFieldRef.current.focus();
    }
  };
  const handleInputChangeLabel = (event) => {
    const inputValue = event.target.value;

    const isValidInput = /^[a-zA-Z0-9 ]*$/.test(inputValue);

    if (isValidInput) {
      setLabelName(inputValue);
    }
  };

  const saveDesign = () => {
    editTemp === "true" && setSaveLoad(true);
    emailEditorRef.current.editor.saveDesign((data) => {
      setJson(data);
    });
    function utf8ToBase64(str) {
      const encoder = new TextEncoder();
      const data = encoder.encode(str);
      const base64 = btoa(String.fromCharCode(...data));
      return base64;
    }
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      htmlToImageConvert(html);
      setHtml(html);
      const encodedString = utf8ToBase64(html);
      setEncodedHtml(encodedString);
    });

    setConfirmSave(true);
  };

  // console.log(urll, "url");

  const handleSave = () => {
    const requestBody = {
      templateName: templateNameEdited,
      templateLabel: labelName,
      templateCategory: sessionStorage.getItem("TemplateCategory"),
      templateString: html,
      templateJson: json,
    };
    // const toastLoad = toast.loading("Saving...");
    CreateTemplateAPICall(requestBody)
      .then((res) => {
        // console.log(res.data);
        // toast.dismiss(toastLoad);

        toast.success("Template created Successfully");
        setConfirmSave(false);
        navigate("/dashboard/templates");
      })
      .catch((error) => {
        // toast.dismiss(toastLoad);
        toast.error(
          error.response.data.message
            ? error.response.data.message
            : "Error Creating Template"
        );
      });
  };

  const handleEditSave = () => {
    const requestBody = {
      oldTemplateName: tempName,
      newTemplateName: templateNameEdited,
      templateLabel: labelName,
      templateCategory: sessionStorage.getItem("TemplateCategory"),
      templateString: html,
      templateJson: json,
    };
    // const toastLoad = toast.loading("Saving...");
    EditTemplateAPICall(requestBody)
      .then((res) => {
        // console.log(res.data);
        // toast.dismiss(toastLoad);

        // if (res.data.status === "success") {
        toast.success("Template updated successfully");
        setConfirmSave(false);
        navigate("/dashboard/templates");
        sessionStorage.setItem("editFlag", "false");
        // } else {
        // toast.error(res.data.error.message);
        // }
      })
      .catch((error) => {
        // toast.dismiss(toastLoad);
        toast.error(
          error.response?.data?.error?.message
            ? error.response.data.error.message
            : "Error Saving Edited Template"
        );
      });
  };

  const handleDelete = () => {
    const dltBody = {
      templatesList: [sessionStorage.getItem("TemplateName")],
      templateCategory: sessionStorage.getItem("TemplateCategory"),
    };
    // deleteTemplateAPICall(dltBody)
    //   .then((res) => {
    //     navigate("/dashboard/allTemplates");
    //     toast.success(res.data.data.group[0].data);
    //   })
    //   .catch((res) => {
    //   //  console.log(res.data);

    //     toast.error("Deletion Failed");
    //   });

    setOpen(false);
  };

  return (
    <>
      {/* <button onClick={exportHtml}>html</button> */}
      {/* <button onClick={htmlToImageConvert}>png</button> */}
      <Toaster />
      <ThemeProvider theme={theme}>
      <div style={{ position: "relative", padding: "0px 5px" }}>
        <div
          ref={elementRef}
          className="displayNone"
          // style={{
          //   display:"none"
          // }}
        ></div>
        <div>
          <div className={classes.header}>
            <div className={classes.hLeft}>
              <div>
                <TextField
                  placeholder="Enter Template Name"
                  id="outlined-size-small"
                  size="small"
                  inputRef={textFieldRef}
                  sx={{
                    minWidth: "300px",
                    backgroundColor: "#E3ECF7",
                    outline: "none",
                    height: "fit-content",
                  }}
                  autoFocus
                  value={templateNameEdited}
                  disabled={tempName}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <TextField
                  placeholder="Enter Label Name"
                  id="outlined-size-small"
                  size="small"
                  // inputRef={textFieldRef}
                  sx={{
                    minWidth: "300px",
                    backgroundColor: "#E3ECF7",
                    outline: "none",
                    height: "fit-content",
                  }}
                  value={labelName}
                  onChange={handleInputChangeLabel}
                  inputProps={{ maxLength: 15 }}
                />
              </div>
              {/* <div
                className={classes.igBtnGrp}
                style={{ backgroundColor: "#4C6FFF", marginLeft: "5px" }}
                onClick={() => {
                  if (textFieldRef.current) {
                    textFieldRef.current.focus();
                  }
                }}
              >
                <BorderColorOutlinedIcon fontSize="small" />
                Edit name
              </div> */}
            </div>
            <div className={classes.hRight}>
              {/* <div className={classes.hRight1}>
                {" "}
                <Tooltip title="Tags">
                  <IconButton>
                    <LocalOfferOutlinedIcon fontSize="small" />{" "}
                  </IconButton>
                </Tooltip>
              </div> */}
              {/* <div
                className={classes.hRight1}
                onClick={() => {
                  setOpen(true);
                }}
              >
                <Tooltip title="Delete">
                  <IconButton>
                    <DeleteOutlineOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </div> */}
              {/* <div className={classes.hRight1}>
                <Tooltip title="Email">
                  <IconButton>
                    <RocketLaunchOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </div> */}
              <div
              // className={classes.save}
              >
                <Button
                  disabled={templateNameEdited === "" || labelName === ""}
                  startIcon={<SaveOutlinedIcon />}
                  onClick={() => {
                    saveDesign();
                  }}
                  sx={{
                    textTransform: "none",
                    marginLeft: "10px",
                    padding: "10px 10px",
                
                  }}
                  variant="contained"
                >
                  {" "}
                  Save Template
                </Button>
              </div>
            </div>
          </div>

          {/* <div ref={elementRef}></div> */}
          <EmailEditor
            ref={emailEditorRef}
            onLoad={onLoad}
            onReady={onReady}
            minHeight={"74vh"}
            options={{
              displayMode: "email",
              editor: {
                autoSelectOnDrop: true,
              },
              fonts: {
                showDefaultFonts: true,
                customFonts: fonts,
              },
            }}
            appearance={{}}
            style={{
              backgroundColor: "white",
            }}
          />
          {/* <EmailEditor
            ref={emailEditorRef}
            onLoad={onLoad}
            onReady={onReady}
            minHeight={"74vh"}
            options={{
              displayMode: "email",
              editor: {
                autoSelectOnDrop: true,
              },
              fonts: {
                showDefaultFonts: true,
                customFonts: fonts,
              },
            }}
            appearance={{}}
            style={{
              backgroundColor: "white",
            }}
          /> */}
        </div>
      </div>
      </ThemeProvider>
      <Dialog
        open={confirmSave}
        onClose={handleCloseSave}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color={"primary"}>
          Save Template
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to save this Template?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className={classes.buttons}>
            <div className={classes.cancelBtn} onClick={handleCloseSave}>
              Cancel
            </div>
            <div
              className={classes.createBtn}
              onClick={() => {
                editTemp === "true" ? handleEditSave() : handleSave();
              }}
            >
              Save{" "}
            </div>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color={"red"}>
          Delete Template
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this Template?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className={classes.buttonsModal}>
            <div className={classes.cancelBtn} onClick={handleClose}>
              Cancel
            </div>
            <div
              className={classes.createBtn}
              onClick={() => {
                handleDelete();
              }}
            >
              Delete{" "}
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EmailTemplateEditor;
