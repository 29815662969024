import React from "react";
import { Routes, Route, Navigate, useRoutes } from "react-router-dom";
import Register from "../../containers/Register/Register";
import Login from "../../containers/Login/Login";
import ForgotPassword from "../../containers/ForgotPassword/ForgotPassword";
import OtpScreen from "../../containers/OtpScreen/OtpScreen";
import ChangePassword from "../../containers/ChangePassword/ChangePassword";
import Dashboard from "../../containers/Dashboard/Dashboard";
import RegisterOtp from "../../containers/Register/RegisterOtp/RegisterOtp";
import GeneralBoard from "../../containers/GeneralBoard/GeneralBoard";

import ClientAdmin from "../../containers/ClientAdminTable/ClientAdminTable";
import ProfileDetails from "../ProfileDetails/ProfileDetails";

import GenerateKey from "../../containers/Partners/GenerateKey/GenerateKey";
import VendorList from "../../containers/Partners/VendorList/VendorList";
import Templates from "../../containers/Templates/Templates";
import Users from "../../containers/Users/Users";
import ClientDetails from "../../containers/ClientDetails/ClientDetails";
import EmailTemplateEditor from "../../containers/Templates/EmailTemplateEditor/EmailTemplateEditor";
import Enquires from "../../containers/Enquires/Enquires";
import SmsSenderId from "../../containers/SmsSenderId/SmsSenderId";
import SmsTemplates from "../../containers/SmsTemplates/SmsTemplates";
import SmsDashboard from "../../containers/SmsDashboard/SmsDashboard";
import SmsCredits from "../../containers/SmsCredits/SmsCredits";
import SmsPreview from "../../containers/SmsPreview/SmsPreview";
import SmsCreditsPreview from "../../containers/SmsCreditsPreview/SmsCreditsPreview";
import SmsAdminClient from "../../containers/SmsAdminClient/SmsAdminClient";
import SmsClients from "../../containers/SmsClients/SmsClients"
import SmsSubscriptionDetails from "../../containers/SmsSubscriptionDetails.tsx/SmsSubscriptionDetails";
import WhatsappDashBoard from "../../containers/Whatsapp/WhatsappDashboard/WhatsappDashBoard";

function Router() {
  const routes = useRoutes([
    {
      path: "",
      element: <Login />,
    },

    {
      path: `otpScreen`,
      element: <OtpScreen />,
    },
    {
      path: `register`,
      element: <Register />,
    },
    {
      path: `RegisterOtp`,
      element: <RegisterOtp />,
    },
    {
      path: `forgotPassword`,
      element: <ForgotPassword />,
    },
    {
      path: `changePassword`,
      element: <ChangePassword />,
    },

    {
      path: "/dashboard",
      element: <Dashboard />,
      children: [
        {
          path: ``,
          children: [
     
            { element: <Navigate to="dashboard" />, index: true },
            {
              path: `dashboard`,
              element: <GeneralBoard />,
            },
            {
              path: `clients`,
              element: <ClientAdmin />,
            },
            {
              path: `clients/clientDetails`,
              element: <ClientDetails/>
            },
            {
              path: `templates`,
              element: <Templates />
            },
            {
              path: `templates/emailTemplateEditor`,
              element: <EmailTemplateEditor />
            },
            {
              path: `users`,
              element: <Users />
            },
            {
              path: `enquires`,
              element: <Enquires />
            },

          ],
        },
        {
          path: "",
          children : [
           
            { element: <Navigate to="sms-dashboard" />, index: true },
            {
              path: `sms-dashboard`,
              element: <SmsDashboard />,
            },
            {
              path: `sms-admindetails`,
              element: <SmsAdminClient />,
            },
            {
              path: `sms-clients`,
              element: <SmsClients />,
            },
            {
              path: `sms-clients/smsclientsdetails`,
              element: <SmsSubscriptionDetails />,
            },
            {
              path: `sms-senderid`,
              element: <SmsSenderId />,
            },
            {
              path: `sms-templates`,
              element: <SmsTemplates />
            },
            {
              path: `sms-credits`,
              element: <SmsCredits />
            },
            {
              path: `preview-details`,
              element: <SmsPreview />
            },
            {
              path: `creditspreview-details`,
              element: <SmsCreditsPreview />
            }
    
          ],
        },
        {
          path: "",
          children : [
           
            { element: <Navigate to="whatsapp-dashboard" />, index: true },
            {
              path: `whatsapp-dashboard`,
              element: <WhatsappDashBoard />,
            },
            // {
            //   path: `sms-admindetails`,
            //   element: <SmsAdminClient />,
            // },
            // {
            //   path: `sms-clients`,
            //   element: <SmsClients />,
            // },
            // {
            //   path: `sms-clients/smsclientsdetails`,
            //   element: <SmsSubscriptionDetails />,
            // },
            // {
            //   path: `sms-senderid`,
            //   element: <SmsSenderId />,
            // },
            // {
            //   path: `sms-templates`,
            //   element: <SmsTemplates />
            // },
            // {
            //   path: `sms-credits`,
            //   element: <SmsCredits />
            // },
            // {
            //   path: `preview-details`,
            //   element: <SmsPreview />
            // },
            // {
            //   path: `creditspreview-details`,
            //   element: <SmsCreditsPreview />
            // }
    
          ],
        },
        
      ],
    },

 
   
    {
      path: "/dashboard",
      element: <Dashboard />,
      children: [
        {
          path: ``,
          children: [
            {
              path: `partners/generateKey`,
              element: <GenerateKey />,
            },
            {
              path: `partners/vendorList`,
              element: <VendorList />
            },

            { element: <Navigate to="upload" />, index: true },
          ],
        },
      ],
    },
  ]);
  return routes;
}

export default Router;

// export default Router
