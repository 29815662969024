import React, { useEffect, useState } from "react";
import { GetCompanySubscriptionLogsAPICall } from "../../../Utils/Apihelper";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
} from "@mui/material";
import classes from "./Subscripitionlogs.module.scss";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DataTable from "react-data-table-component";
import StyledLoader from "../../../components/Styledloader/StyledLoader";
import SearchIcon from "@mui/icons-material/Search";

export default function SubscripitionLogs() {
  const CompanyName: any = sessionStorage.getItem("companyName");
  const [paymentdata, setpaymentdata] = useState([]);
  const [expanded4, setExpanded4] = useState(false);
  const [loading, setloading] = useState(false);
  const [query, setQuery] = useState("");

  const handleChange4 = (panel: any) => (event: any, isExpanded: any) => {
    setExpanded4(isExpanded ? panel : false);
  };
  useEffect(() => {
    setloading(true);
    GetCompanySubscriptionLogsAPICall(CompanyName)
      .then((response: any) => {
        // console.log("response", response.data.data);
        setloading(false);
        setpaymentdata(response?.data?.data ? response.data.data : []);
      })
      .catch((error: any) => {
        setloading(false);
      });
  }, []);
  const columns: any = [
    {
      name: <b style={{ fontSize: "12px", color: "#2D3748" }}>SrNo</b>,
      selector: "srno",
      sortable: true,
      width: "70px",
      cell: (row: any, index: any) => (
        <b style={{ fontSize: "12px", color: "#2D3748" }}>{index + 1}</b>
      ),
    },
    {
      name: <b style={{ fontSize: "12px", color: "#2D3748" }}>Trans Id</b>,

      selector: "transactionId",
      sortable: true,

      cell: (row: any) => (
        <b style={{ fontSize: "12px", color: "#2D3748" }}>
          {row.data.transactionId ? row.data.transactionId : "-"}
        </b>
      ),
    },
    {
      name: <b style={{ fontSize: "12px", color: "#2D3748" }}>Merchant Id</b>,

      selector: "merchantId",
      sortable: true,

      cell: (row: any) => (
        <b style={{ fontSize: "12px", color: "#2D3748" }}>
          {row.data.merchantId}
        </b>
      ),
    },
    {
      name: (
        <b style={{ fontSize: "12px", color: "#2D3748" }}>Merchant Trans Id</b>
      ),

      selector: "merchantTransactionId",
      sortable: true,

      cell: (row: any) => (
        <b style={{ fontSize: "12px", color: "#2D3748" }}>
          {row.data.merchantTransactionId}
        </b>
      ),
    },

    {
      name: (
        <b style={{ fontSize: "12px", color: "#2D3748" }}>Payment Details</b>
      ),

      selector: "createdAt",
      sortable: true,
      //   width: "300px",

      cell: (row: any) => (
        <b style={{ fontSize: "12px", color: "#2D3748" }}>
          {row.data.paymentInstrument ? (
            <div>
              <p className={classes.section3AccordionTitle}>
                <span className={classes.subt}>Account Type:</span> &nbsp;
                {row.data.paymentInstrument.accountType}
              </p>
              <p className={classes.section3AccordionTitle}>
                <span className={classes.subt}>Payment Type:</span> &nbsp;
                {row.data.paymentInstrument.type}
              </p>
              <p className={classes.section3AccordionTitle}>
                <span className={classes.subt}>UTR No:</span> &nbsp;
                {row.data.paymentInstrument.utr}
              </p>
              {/* <p className={classes.section3AccordionTitle}>
                <span className={classes.subt}>UPI Trans Id:</span> &nbsp;
                {row.data.paymentInstrument.upiTransactionId}
              </p> */}
            </div>
          ) : (
            "-"
          )}
        </b>
      ),
    },
    {
      name: <b style={{ fontSize: "12px", color: "#2D3748" }}>Response</b>,

      selector: "subscriptionType",
      sortable: true,

      cell: (row: any) => (
        <b style={{ fontSize: "12px", color: "#2D3748" }}>
          {row.data.responseCode}
        </b>
      ),
    },

    {
      name: <b style={{ fontSize: "12px", color: "#2D3748" }}>Status</b>,
      selector: "subscriptionStatus",
      sortable: true,
      cell: (row: any) => (
        <div
          className={
            row.code === "PAYMENT_ERROR"
              ? classes.statusOrange
              : classes.statusGreen
          }
        >
          <b style={{ fontSize: "13px" }}>
            {row.code === "PAYMENT_ERROR" ? "FAILED" : "SUCCESS"}
          </b>
        </div>
      ),
    },
  ];
  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F3F6FA",
        // display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
      },
    },

    headRow: {
      style: {
        borderBottom: "none",
        marginBottom: "10px",
      },
    },
    header: {
      style: {
        fontSize: "16px",
        minHeight: "38px",
        paddingLeft: "5px",
        paddingRight: "5px",
      },
    },
    contextMenu: {
      style: {
        fontSize: "12px",
        fontWeight: 400,
        paddingLeft: "16px",
        paddingRight: "8px",
        transform: "translate3d(0, -100%, 0)",
        transitionDuration: "125ms",
        transitionTimingFunction: "cubic-bezier(0, 0, 0.2, 1)",
        willChange: "transform",
      },
      activeStyle: {
        transform: "translate3d(0, 0, 0)",
      },
      rows: {
        style: {
          cursor: "pointer",
          fontSize: "13px",
          fontWeight: 400,
          backgroundColor: "red",
          minHeight: "48px",
          "&:not(:last-of-type)": {
            borderBottomStyle: "solid",
            borderBottomWidth: "1px",
          },
        },
      },
    },
    cells: {
      style: {
        paddingTop: "16px",
        paddingBottom: "16px",
        wordBreak: "break-word",
        cursor: "pointer",
      },
      draggingStyle: {},
    },
  };

  const filteredData = paymentdata.filter((res: any) =>
    res.message.toLowerCase().includes(query)
  );
  return (
    <div>
      <div className={classes.pRight}>
        <div className={classes.verifySectionRight}>
          <div className={classes.headSection}>
            <p className={classes.verifyText}>Subscription Logs</p>
            <div className={classes.searchInput}>
              <div className={classes.searchIcon}>
                <SearchIcon sx={{ fill: "black" }} />
              </div>

              <input
                type="text"
                value={query}
                className={classes.inputBox}
                placeholder="Search...."
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
          </div>

          <div>
            <DataTable
              columns={columns}
              data={filteredData}
              fixedHeader
              customStyles={customStyles}
              progressPending={loading}
              progressComponent={<StyledLoader />}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
