import signUp from "../../assets/Images/signup.png";
import classes from "./Login.module.scss";
import { useFormik } from "formik";
import { signUpSchema, signinSchema } from "../../Schemas/Schemas";
import Button from "@mui/joy/Button";
import Input from "@mui/joy/Input";
import { Link, useNavigate } from "react-router-dom";
import { adminLoginAPICall } from "../../Utils/Apihelper";
import leftImage from "../../assets/Images/leftimage.svg";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone";
import VisibilityOffTwoToneIcon from "@mui/icons-material/VisibilityOffTwoTone";
import { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { IAdminLogin } from "../../Utils/dtos";
import { Toaster, toast } from "react-hot-toast";

const initialValues = {
  email: "",
  password: "",
};

const Login = () => {
  const navigate = useNavigate();
  const [showAccountNumber, setShowAccountNumber] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setloading] = useState(false);
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: signinSchema,
      onSubmit: (values, action) => {
        let obj: IAdminLogin = {
          email: values.email,
          password: values.password,
        };
        setloading(true);
        adminLoginAPICall(obj)
          .then((res: any) => {
            sessionStorage.setItem("email", values.email);
            navigate("/otpScreen");

            setloading(false);
          })
          .catch((error: any) => {
            setloading(false);

            toast.error(
              error.response.data.error?.message
                ? error.response.data.error.message
                : "Error Login"
            );

            console.log(error.data);
          });
      },
    });
  const handleClickShowAccountNumber = () =>
    setShowAccountNumber((show) => !show);

  const handleMouseDownAccountNumber = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  return (
    <>
      <Toaster />
      <div className={classes.container}>
        <div className={classes.modal}>
          <div className={classes.modalContainer}>
            <div className={classes.modalLeft}>
              <h1 className={classes.modalTitle}>Welcome !</h1>
              <p className={classes.modalTitleSubtext}>
                Sign In to Enotifiers Admin
              </p>
              <p className={classes.modalDesc}>Enter your email address</p>
              <div className={classes.loginData}>
                <form onSubmit={handleSubmit}>
                  <div className={classes.inputBlock}>
                    <TextField
                      name="email"
                      id="name"
                      placeholder="Email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputProps={{ sx: { borderRadius: "10px" } }}
                      sx={{
                        "& label.Mui-focused": {
                          color: "#FB754B",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "#FB754B",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#FB754B",
                          },
                        },
                      }}
                    />
                    {errors.email && touched.email ? (
                      <p className={classes.formError}>{errors.email}</p>
                    ) : null}
                  </div>
                  <p className={classes.modalDesc}>Enter your Password</p>
                  <div className={classes.inputBlock}>
                    <TextField
                      type={showAccountNumber ? "text" : "password"}
                      autoComplete="off"
                      name="password"
                      id="password"
                      placeholder="Enter Password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputProps={{
                        sx: { borderRadius: "10px" },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowAccountNumber}
                              onMouseDown={handleMouseDownAccountNumber}
                              edge="end"
                            >
                              {showAccountNumber ? (
                                <VisibilityTwoToneIcon />
                              ) : (
                                <VisibilityOffTwoToneIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        "& label.Mui-focused": {
                          color: "#FB754B",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "#FB754B",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#FB754B",
                          },
                        },
                      }}
                    />
                    {errors.password && touched.password ? (
                      <p className={classes.formError}>{errors.password}</p>
                    ) : null}
                  </div>

                  <div className={classes.modalButtons}>
                    <p
                      style={{
                        color: "red",
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      {errorMessage}
                    </p>
                    <LoadingButton
                      type="submit"
                      loading={loading}
                      style={{ padding: "14px 50px" }}
                      sx={{
                        background: "#FF5151",
                        color: "white",
                        borderRadius: "10px",
                        "&:hover": {
                          background: "#FF5151",
                        },
                      }}
                    >
                      Login
                    </LoadingButton>
                    {/* <a href="#" className={classes.link}>
                      <Link
                        to={"/forgotPassword"}
                        style={{ textDecoration: "none" }}
                      >
                        Forgot Password?
                      </Link>
                    </a> */}
                  </div>
                </form>
              </div>
            </div>
            <div className={classes.modalRight}>
              <img src={leftImage} alt="" className={classes.signupImage} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
