import axios from "axios";
import {
  BASE_URL,
  CLIENTADMIN_URL,
  UPLOADFILE_MIDDLEWARE_URL,
} from "./constants";
import {
  IADDUser,
  IAdminLogin,
  IAssignUser,
  IEditUser,
  IGetAllCompany,
  IGetAllEnquires,
  IVerifyLoginOTP,
} from "./dtos";

const axiosInterceptor = axios.create({
  baseURL: BASE_URL,
});

axiosInterceptor.interceptors.request.use(
  async (req) => {
    const token = sessionStorage.getItem("Token");

    req.headers.Authorization = `Bearer ${token}`;
    return req;
  },
  (error) => Promise.reject(error)
);

axiosInterceptor.interceptors.response.use(
  (res) => res,
  (err) => {
    const status = err.response ? err.response.status : null;
    if (status === 401) {
      console.log("Logout", status);
      handleLogout();
    }
    return Promise.reject(err);
  }
);
const handleLogout = () => {
  // sessionStorage.clear();
  // window.location.href = "/";
};

// ***************Login api

export const adminLoginAPICall = (requestBody: IAdminLogin) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/V1/login`, requestBody, {})
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const VerifyLoginOTPAPICall = (requestBody: IVerifyLoginOTP) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/V1/verifyloginOTP`, requestBody, {})
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

// ***************Dashboard api

export const GetDashboardAnalyticsAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/V1/analyticsDashboard`, "", {
        params: {
          type: requestBody,
        },
      })
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

// ***************Enquires apis
export const GetAllEnquiresAPICall = ({
  query,
  pageNo,
  pageLimit,
}: IGetAllEnquires) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/V1/getEnquiries`, {
        params: {
          query,
          pageNo,
          pageLimit,
        },
      })
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

// ***************Companys apis

export const GetAllCompanyAPICall = ({
  query,
  pageNo,
  pageLimit,
  plan,
}: IGetAllCompany) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/V1/allcompany`, {
        params: {
          query,
          pageNo,
          pageLimit,
          plan,
        },
      })
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const GetCompanyDetailsAPICall = (companyName: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/V1/companydetails/${companyName}`, {
        params: {
          companyName: companyName,
        },
      })
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const GetCompanyEmailHistoryAPICall = (companyName: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/V1/emailHistory/${companyName}`, {
        params: {
          companyName: companyName,
        },
      })
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const GetCompanySubscriptionHistoryAPICall = (companyName: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/V1/getsubscriptionHistory/${companyName}`, {
        params: {
          companyName: companyName,
        },
      })
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const GetAllPlansAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/V1/getAllPlans`, {})
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const GetCompanySubscriptionLogsAPICall = (companyName: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/V1/getsubscriptionLogs/${companyName}`, {
        params: {
          companyName: companyName,
        },
      })
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const GetAWSSettingsAPICall = (companyName: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/V1/get_aws_settings/${companyName}`, {
        params: {
          companyName: companyName,
        },
      })
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const GetCompanySubscriptionReceiptAPICall = (
  companyName: any,
  transactionId: any
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/V1/getsubscriptionReceipt/${companyName}/${transactionId}`, {
        params: {
          companyName: companyName,
          transactionId: transactionId,
        },
      })
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

// ***************Users api

export const GetUsersAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/V1/getUsers`, {})
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const GetUserDetailsAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/V1/getUserDetails`, {})
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const AssignUserAPICall = (requestBody: IAssignUser) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/V1/assignUser`, requestBody, {})
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const EditUserAPICall = (requestBody: IEditUser) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/V1/editUser`, requestBody, {})
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const AddUserAPICall = (requestBody: IADDUser) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/V1/createUser`, requestBody, {})
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const DeleteUserAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .delete(`/V1/deleteUser/${requestBody}`, { data: requestBody })
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

// ***************Templates api

export const CreateTemplateAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/V1/createTemplate`, requestBody, {})
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const EditTemplateAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(
        `/V1/editTemplateDetails/${requestBody.newTemplateName}/${requestBody.templateCategory}`,
        {
          templateJson: requestBody.templateJson,
          templateString: requestBody.templateString,
          templateLabel: requestBody.templateLabel,
        },
        requestBody
      )
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const GetTemplatesAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/V1/getTemplates`, {})
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const DeleteTemplateAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .delete(`/V1/deleteTemplate/${requestBody.templateName}`, {
        data: { templateCategory: requestBody.templateCategory },
      })
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const GetSingleTemplateDetailsAPICall = (requestBody: any) => {
  console.log("requestBody", requestBody);
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(
        `/V1/getTemplateDetails/${requestBody.templateName}/${requestBody.templateCategory}`,
        {
          params: {
            templateName: requestBody.templateName,
            templateCategory: requestBody.templateCategory,
          },
        }
      )
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const CloneTemplateAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(
        `/V1/cloneTemplate/${requestBody.templateName}/${requestBody.templateCategory}`,
        {
          new_templateName: requestBody.new_templateName,
          new_templateCategory: requestBody.new_templateCategory,
        },
        requestBody
      )
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const EditTemplatelabelAPICall = (
  requestBody: any,
  templateName: any,
  templateCategory: any
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/V1/editTemplatelabel/${templateName}/${templateCategory}`, {
        templateLabel: requestBody.templateLabel,
      })
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

// ***************Templates Category api

export const CreateTemplateCategoryAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/V1/createTemplateCategory`, requestBody, {})
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const GetTemplateCategoryAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/V1/getTemplateCategory`, {})
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const GetTemplateCategoryDetailsAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/V1/getTemplateCategoryDetails`, {})
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const deleteSingleTemplateAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .delete(
        `/V1/deleteTemplateCategory/${requestBody.templateCategoryName}`,
        {
          data: requestBody.templateCategoryName,
        }
      )
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const EditTemplateCategoryNameAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(
        `/V1/editTemplateCategoryName/${requestBody.oldTemplateCategoryName}`,
        { templateCategoryName: requestBody.newTemplateCategoryName },
        requestBody.newTemplateCategoryName
      )
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

// ***************Connect Downloaders api
export const symbolFileUploadApi = (requestBody: any, config: any) => {
  return axios.post(
    `https://openapidev.thefirstock.tech/uploadsymbol`,
    requestBody,
    {
      auth: {
        username: "m8gbthp5@firstock",
        password: "uJMzt2WntozA3sRuSsCQp9XnQLsXeKwNFqVCnDicEPBQvah7uR",
      },
    }
  );
};
export const DeleteSymbolAPICall = (requestBody: any) => {
  return axios.delete(`https://openapidev.thefirstock.tech/deletesymbol/`, {
    data: requestBody,
    auth: {
      username: "m8gbthp5@firstock",
      password: "uJMzt2WntozA3sRuSsCQp9XnQLsXeKwNFqVCnDicEPBQvah7uR",
    },
  });
};

// *************Client Admin table

export const clientAdminTableApi = (page: any, limit: any, query: any) => {
  return axios.get(`${CLIENTADMIN_URL}/firstock/clients`, {
    params: {
      page: page,
      limit: limit,
      expr: query ? query : "",
    },
    auth: {
      username: "tCTzC54r8bJUCUvh9T6y",
      password: "q5NL8T9zdCiKYv24VwBbRB2rYt4hiRKjVkjCzVZUqxu5hpQii6",
    },
  });
};

export const clientAdminProfileApi = (userid: any) => {
  return axios.get(`${CLIENTADMIN_URL}/firstock/client/${userid}`, {
    auth: {
      username: "tCTzC54r8bJUCUvh9T6y",
      password: "q5NL8T9zdCiKYv24VwBbRB2rYt4hiRKjVkjCzVZUqxu5hpQii6",
    },
  });
};

export const clientUploadApi = (requestBody: any) => {
  return axios.post(
    `${CLIENTADMIN_URL}/firstock/uploadClientList`,
    requestBody,
    {
      auth: {
        username: "tCTzC54r8bJUCUvh9T6y",
        password: "q5NL8T9zdCiKYv24VwBbRB2rYt4hiRKjVkjCzVZUqxu5hpQii6",
      },
    }
  );
};
export const clientUploadAndResetApi = (requestBody: any) => {
  return axios.post(
    `${CLIENTADMIN_URL}/firstock/resetAndUploadClientList`,
    requestBody,
    {
      auth: {
        username: "tCTzC54r8bJUCUvh9T6y",
        password: "q5NL8T9zdCiKYv24VwBbRB2rYt4hiRKjVkjCzVZUqxu5hpQii6",
      },
    }
  );
};

// *************Upload Files api

export const UploadmiddlewarefilesApi = (requestBody: any, file_type: any) => {
  return axios.post(`${UPLOADFILE_MIDDLEWARE_URL}/uploadFlies/`, requestBody, {
    params: {
      file_type: file_type,
    },
  });
};

//SMS Admin
export const smsGetallClientsCompany = (status: string) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/V1/sms/allcompany_status`, {
        params: {
          status
        },
      })
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const smsGetallClients = (requestBody: { userName: string }) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/V1/sms/smsclientDetails`, requestBody)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const smsGetallCredits = (userName: string) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/V1/sms/get_creditHistory`, {
        params: {
          userName
        },
      })
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const smsGetallCreditsData = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/V1/sms/get_creditHistory`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const smsGetSenderId = (userName: string) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/V1/sms/get_senderid`, {
        params: {
          userName
        },
      })
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const smsGetSenderIdIndividual = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/V1/sms/get_senderid`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const smsGetTemplate = (userName: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/V1/sms/get_templateid`, {
        params: {
          userName
        },
      })
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const smsGetTemplateIndividual = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/V1/sms/get_templateid`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const creditsApproveReject = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/V1/sms/ApproveReject_creditRequest`,requestBody)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const GetCompanyDetailsAPI = (requestBody: { userName: string }) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/V1/sms/smsclientDetails`, requestBody)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};


export const approveDltId = (requestBody:any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/V1/sms/approve_dltid`, requestBody)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const rejectDltId = (requestBody:any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/V1/sms/reject_dltid`, requestBody)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const addCredit = (requestBody:any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/V1/sms/add_credit`, requestBody)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const approveRejectSenderIdTemplateId = (requestBody:any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/V1/sms/approveReject_SenderIdTemplateId`, requestBody)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const approveRejectCredits = (requestBody:any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/V1/sms/ApproveReject_creditRequest`, requestBody)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};







