import React, {useState, useEffect} from 'react';
import classes from './Subscription.module.scss'
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";

const Subscription = ({data} : any) => {
  const [open, setOpen] = React.useState(false);
  const [opened, setOpened] = React.useState(false);
  const [rejectionMessage, setRejectionMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [route, setRoute] = useState<any>();
  const [dcs, setDcs] = useState<any>();
  const [flashsms, setFlashSms] = useState<any>();
  const [username, setusername] = useState<any>();
  const [password, setpassword] = useState<any>();

  const handleClickApproveOpen = () => {
    setOpened(true);
  };

  const handleClose = () => {
    setRejectionMessage('');
    setErrorMessage('');
    setOpen(false);
    setOpened(false);
    setRoute('');
    setDcs('');
    setFlashSms('');
    setusername('');
    setpassword('');

  };

  const handleApprove = () => {
    // approveId();
  }

  const handleReject = () => {
    // rejectId();
  }


  return (
    <>
    <div className={classes.pRight}>
            <div className={classes.pDetailsField}>
            Subscription Status
              <TextField
                placeholder=""
                id="outlined-size-small"
                size="small"
                variant="standard"
                value={data?.subscription?.products?.sms?.subscriptionStatus}
                //   onChange={handleEmailName}
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    height: "40px",
                    padding: "0px 10px",
                    borderRadius: "5px",
                  },
                }}
                sx={{
                  marginTop: "8px",
                  width: "-webkit-fill-available",
                  background: "#EDF2F7",
                  borderRadius: "5px",
                  "& label.Mui-focused": {
                    color: "grey",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#EDF2F7",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#EDF2F7",
                    },
                  },
                }}
              />
            </div>
            <div className={classes.pDetailsField}>
              Subscripton Type
              <TextField
                placeholder=""
                id="outlined-size-small"
                size="small"
                variant="standard"
                value={data?.subscription?.products?.sms?.subscriptionType}
                //   onChange={handleEmailName}
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    height: "40px",
                    padding: "0px 10px",
                    borderRadius: "5px",
                  },
                }}
                sx={{
                  marginTop: "8px",
                  width: "-webkit-fill-available",
                  background: "#EDF2F7",
                  borderRadius: "5px",
                  "& label.Mui-focused": {
                    color: "grey",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#EDF2F7",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#EDF2F7",
                    },
                  },
                }}
              />
            </div>
            <div className={classes.pDetailsField}>
              Subscription Expiry
              <TextField
                placeholder=""
                id="outlined-size-small"
                size="small"
                variant="standard"
                value={data?.subscription?.products?.sms?.subscriptionExpiry}
                //   onChange={handleEmailName}
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    height: "40px",
                    padding: "0px 10px",
                    borderRadius: "5px",
                  },
                }}
                sx={{
                  marginTop: "8px",
                  width: "-webkit-fill-available",
                  background: "#EDF2F7",
                  borderRadius: "5px",
                  "& label.Mui-focused": {
                    color: "grey",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#EDF2F7",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#EDF2F7",
                    },
                  },
                }}
              />
            </div>
            <div className={classes.pDetailsField}>
              Subscription History
              <TextField
                placeholder=""
                id="outlined-size-small"
                size="small"
                variant="standard"
                value={data?.subscription?.products?.sms?.subscriptionHistory}
                //   onChange={handleEmailName}
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    height: "40px",
                    padding: "0px 10px",
                    borderRadius: "5px",
                  },
                }}
                sx={{
                  marginTop: "8px",
                  width: "-webkit-fill-available",
                  background: "#EDF2F7",
                  borderRadius: "5px",
                  "& label.Mui-focused": {
                    color: "grey",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#EDF2F7",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#EDF2F7",
                    },
                  },
                }}
              />
            </div>
            
            {/* <div className={classes.pbuttons}>
              <div className={classes.pApprove}><Button variant="contained"
              onClick={() => {
                handleClickApproveOpen();
              }}
              >Approve</Button></div>
              <Dialog
        open={opened}
        onClose={handleClose}
      >
        <DialogTitle>Data</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To approve, please enter all the details. 
          </DialogContentText>
          <TextField
            
            required
            margin="dense"
            label="Route"
            type="text"
            fullWidth
            variant="standard"
            value={route}
            onChange={(e:any) => {
              setRoute(e.target.value);
            }}
          />
           <TextField
            autoFocus
            required
            margin="dense"
            label="Flash Sms"
            type="text"
            fullWidth
            variant="standard"
            value={flashsms}
            onChange={(e:any) => {
              setFlashSms(e.target.value);
            }}
          />
           <TextField
            autoFocus
            required
            margin="dense"
            label="DCS"
            type="text"
            fullWidth
            variant="standard"
            value={dcs}
            onChange={(e:any) => {
              setDcs(e.target.value);
            }}
          />
           <TextField
            autoFocus
            required
            margin="dense"
            label="Username"
            type="text"
            fullWidth
            variant="standard"
            value={username}
            onChange={(e:any) => {
              setusername(e.target.value);
            }}
          />
           <TextField
            autoFocus
            required
            margin="dense"
            label="Password"
            type="text"
            fullWidth
            variant="standard"
            value={password}
            onChange={(e:any) => {
              setpassword(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit"
          onClick={() => {
            handleClose();
            handleApprove();
          }}
          >Approve</Button>
        </DialogActions>
      </Dialog>
              <div className={classes.pReject}><Button variant="outlined"
               onClick={() => {
                handleClickOpen();
               
              }}
              >Reject</Button></div>
               <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0)', 
            backdropFilter: 'blur(5px)',
          },
        }}
        PaperProps={{
          elevation: 0,
          style: {
            boxShadow: '0 1px 1px rgba(0,0,0,0.11), 0 2px 2px rgba(0,0,0,0.11), 0 4px 4px rgba(0,0,0,0.11), 0 8px 8px rgba(0,0,0,0.11), 0 16px 16px rgba(0,0,0,0.11), 0 32px 32px rgba(0,0,0,0.11)',
          },
        }}
        
      >
        <DialogTitle sx={{fontSize: '15px'}}>Why are you rejecting?</DialogTitle>
              <DialogContent>
                <Box>
              <TextField
              fullWidth
              multiline
              rows={4} 
              variant="outlined"
              value={rejectionMessage}
              onChange={(e) => setRejectionMessage(e.target.value)}
              sx={{
                height: '70%', 
                '& .MuiInputBase-root': {
                  height: '100%', 
                  alignItems: 'flex-start', 
                },
              }}
            />
          </Box>
              </DialogContent>
              {errorMessage && (
          <Typography color="error" variant="body2" sx={{  marginLeft: '30px' }}>
            {errorMessage}
          </Typography>
        )}
              <DialogActions>
                <Button onClick={() => {
                  handleClose();
                }}>Cancel</Button>
                <Button
                  onClick={() => {
                    handleRejectData();
                    handleReject();
                  }}

                  sx={{color: 'red'}}
                >
                  Reject
                </Button>
              </DialogActions>
      </Dialog>
            </div> */}
          </div>
    </>
  )
}

export default Subscription