import * as Yup from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const signUpSchema = Yup.object({
  name: Yup.string().min(2).max(25).required("Please enter your name"),
  email: Yup.string().email().required("Please enter your email"),
  password: Yup.string().min(6).required("Please enter your password"),
  number: Yup.string()
    .required("Mobile Number is Required")
    .max(10)
    .min(8)
    .matches(/^[0-9 ]*$/, "Enter Valid Name"),
  companyname: Yup.string().required("Please enter your Company Name"),
});
export const signinSchema = Yup.object({
  email: Yup.string(),
  password: Yup.string().min(6).required("Please enter your password"),
});

export const forgotpwSchema = Yup.object({
  name: Yup.string()
    .min(2)
    .max(25)
    .required("Please enter your email or phone number"),
});

export const confirmpwSchema = Yup.object({
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$/,
      "Password must have one uppercase, one lowercase, one number, one special symbol (@$!%*?&), and be 8 to 15 length"
    ),
  // confirm_password: Yup.string().oneOf(
  //   [Yup.ref("password"), null],
  //   "Passwords must match"
  // ),
});

export const UserAddScheme = Yup.object().shape({
  mobile: Yup.string().required("Mobile is Required").max(10).min(10),
  email: Yup.string().required("Email is Required").email(),
  name: Yup.string().required("Name is Required"),
  // password: Yup.string().required("Password is Required")
  // .matches(
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$/,
  //   "Password must have one uppercase, one lowercase, one number, one special symbol (@$!%*?&), and be 8 to 15 length"
  // ),
  // is_admin: Yup.bool().required("Mention whether User is Admin or Not"),
  isActive: Yup.bool().required("Mention whether User is Active or Not"),
});

export const UserEditScheme = Yup.object().shape({
  mobile: Yup.string().required("Mobile is Required").max(10).min(10),
  email: Yup.string().required("Email is Required"),
  name: Yup.string().required("Name is Required"),
  isAdmin: Yup.bool().required("Mention whether User is Admin or Not"),
  isActive: Yup.bool().required("Mention whether User is Active or Not"),
});

export const UserDeleteScheme = Yup.object().shape({
  user_id: Yup.string().required("UserId is Required"),
  deleted_reason: Yup.string().required("Email is Required").max(200),
});


export const TodayFollowUpScheme = Yup.object().shape({
  remarks: Yup.string().required("Remarks is Required"),
 date_time: Yup.string().required("Next Followup Date is a required field"),
 status: Yup.string().required("Please select an option"),
});
export const AddFollowUpScheme = Yup.object().shape({
  remarks: Yup.string().required("Remarks is Required"),
  update_date: Yup.string().required(" Date is a Required"),
 status: Yup.string().required("Please select an option"),
});
export const EditFollowUpHistoryScheme = Yup.object().shape({
  remarks: Yup.string().required("Remarks is Required"),
//  date_time: Yup.string().required("Next Followup Date is a required field"),
 status: Yup.string().required("Please select an option"),
});

export const TariffAddScheme = Yup.object().shape({
  partnerId: Yup.string().required("Partner Id is Required").max(10),
  equityDelivery: Yup.string().required("Equity Delivery is Required"),
  partnerName: Yup.string().required("Partner Name is Required"),
  equityIntraday: Yup.string().required("Equity Intraday is Required"),
  futures: Yup.string().required("Futures is Required"),
  options: Yup.string().required("Options is Required"),
  brokerageTemplate: Yup.object().required("Brokerage brokerageTemplate is Required"),

});



export const TariffDeleteScheme = Yup.object().shape({
  // user_id: Yup.string().required("UserId is Required"),
  deleted_reason: Yup.string().required("Delete Reason is Required").max(200),
});