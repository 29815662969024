import { useEffect, useRef, useState } from "react";
import classes from "./Mainboard.module.scss";
import Topbar from "../Topbar/Topbar";
import GeneralBoard from "../../GeneralBoard/GeneralBoard";
import { useSelector } from "react-redux";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { useLocation } from "react-router-dom";
import { IconButton } from "@mui/material";
import DashSidebar from "../DashSidebar/DashSidebar";

interface Props {
  children: any;
}

const Mainboard = ({ children }: Props) => {
  const pathName = useLocation().pathname;

  const contentRef = useRef<any>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [changeStyles, setChangeStyles] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setChangeStyles(true);
    } else {
      setChangeStyles(false);
    }
    const handleScroll = () => {
      const scrollTop = contentRef.current.scrollTop;
      if (scrollTop > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    if (contentRef.current) {
      contentRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (contentRef.current) {
        contentRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    contentRef.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [children]);

  const handleBackToTop = () => {
    if (contentRef.current) {
      contentRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <div className={classes.wrapper}>
        <Topbar />

        <div
          className={classes.dataBoardWrapper}
          ref={contentRef}
          style={{ border: pathName.includes("upload") ? "none" : "" }}
        >
          {children}
        </div>
        {isVisible && (
          <IconButton
            sx={{
              background: "#FB754B",
              color: "white",
              position: "fixed",
              right: "30px",
              bottom: "30px",
              "&:hover": {
                backgroundColor: "#FB754B",

                color: "white",
              },
            }}
            onClick={handleBackToTop}
          >
            <KeyboardArrowUpIcon />
          </IconButton>
        )}
      </div>
    </>
  );
};

export default Mainboard;
