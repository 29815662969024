import classes from "./OtpScreen.module.scss";
import { useFormik } from "formik";
import { signUpSchema } from "../../Schemas/Schemas";
import Button from "@mui/joy/Button";
import Input from "@mui/joy/Input";
import { Link, useNavigate } from "react-router-dom";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import leftImage from "../../assets/Images/leftimage.svg";
import LoadingButton from "@mui/lab/LoadingButton";
import { VerifyLoginOTPAPICall, GetUserDetailsAPICall } from "../../Utils/Apihelper";
import { IVerifyLoginOTP } from "../../Utils/dtos";
import toast, { Toaster } from "react-hot-toast";

const OtpScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const email: any = sessionStorage.getItem("email");
  const [loading, setloading] = useState(false);
  const [mobileno, setmobileno] = useState();

  const [otp, setOtp] = useState("");



  useEffect(() => {

    if (otp.length === 6) {
      handleVerify();
    }
  }, [otp]);

  const handleOtp = (newValue: any) => {
    setOtp(newValue);
  };

  const GetUserDetails = () => {
    GetUserDetailsAPICall().then((response: any) => {
      console.log("userResponse", response.data);
      if (response.data?.data) {
        // setUserDetails(response.data.data);
      }
    });
  };

  const handleVerify = () => {
    let obj: IVerifyLoginOTP = {
      email: email,
      otp: otp,
    };
    VerifyLoginOTPAPICall(obj)
      .then((response: any) => {
        // console.log("response", response.data.accessToken);
        sessionStorage.setItem("Token", response.data.data.accessToken);
        navigate("/dashboard");
      })
      .catch((error: any) => {
        toast.error(
          error.response.data.error?.message
            ? error.response.data.error.message
            : "Error Login"
        );
      });
  };

  const handleKeyPress = (event: any) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const isValidKey = /^\d+$/.test(keyValue);

    if (!isValidKey) {
      event.preventDefault();
    }
  };
  return (
    <>
      <Toaster />
      <div className={classes.container}>
        <div className={classes.modal}>
          <div className={classes.modalContainer}>
            <div className={classes.modalLeft}>
              <h1 className={classes.modalTitle}>OTP Verification</h1>
              <p className={classes.modalDesc}>
                Enter the verification code sent to <b>{email}</b>{" "}
              </p>

              <div className={classes.inputBlock}>
                <MuiOtpInput
                  length={6}
                  value={otp}
                  onChange={handleOtp}
                  onKeyPress={handleKeyPress}
                  sx={{
                    ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#FB754B",
                      },
                    ".MuiOutlinedInput-input": {
                      padding: "0px !important",
                      height: "60px",
                    },
                  }}
                />
              </div>

              <div className={classes.modalButtons}>
                <LoadingButton
                  type="submit"
                  loading={loading}
                  disabled={otp.length !== 6}
                  style={{ padding: "14px 50px" }}
                  sx={{
                    background: "#FF5151",
                    color: "white",
                    borderRadius: "10px",
                    "&:hover": {
                      background: "#FF5151",
                    },
                    "&:disabled": {
                      background: "#cac9c9",
                    },
                  }}
                  onClick={handleVerify}
                >
                  Login
                </LoadingButton>
              </div>

              <p className={classes.signUp}></p>
            </div>
            <div className={classes.modalRight}>
              <img src={leftImage} alt="" className={classes.signupImage} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtpScreen;
