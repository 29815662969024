import React, { useState, useEffect } from "react";
import classes from "./CompanyDetails.module.scss";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { approveDltId, rejectDltId } from "../../../Utils/Apihelper";
import toast, { Toaster } from "react-hot-toast";

const CompanyDetails = ({ data }: any,handleRefresh: any) => {
  const [open, setOpen] = React.useState(false);
  const [opened, setOpened] = React.useState(false);
  const [rejectionMessage, setRejectionMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [route, setRoute] = useState<any>();
  const [dcs, setDcs] = useState<any>();
  const [flashsms, setFlashSms] = useState<any>();
  const [username, setusername] = useState<any>();
  const [password, setpassword] = useState<any>();
  const [initialData, setinitialData] = useState("");

   const approveStatus= data?.smsDetails?.status;


  function formatDateTime(dateTimeString: any) {
    const date = new Date(dateTimeString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hours = date.getHours() % 12 || 12;
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = date.getHours() >= 12 ? "PM" : "AM";

    return `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
  }

  const handleClose = () => {
    setRejectionMessage("");
    setErrorMessage("");
    setOpen(false);
    setOpened(false);
    setRoute("");
    setDcs("");
    setFlashSms("");
    setusername("");
    setpassword("");
  };

  const handleRejectData = () => {
    if (rejectionMessage.trim() === "") {
      setErrorMessage("* rejection message is required");
    } else {
      setErrorMessage("");
      handleClose();
    }
  };

  const handleReject = () => {
    rejectId();
  };

  const handleClickApproveOpen = () => {
    setOpened(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleApprove = () => {
    approveId();
  };

  const user = sessionStorage.getItem("userName");

  const approveId = () => {
    console.log(route, flashsms, dcs, username, password);
    const requestBody: any = {
      userName: user,
      smsDetails: {
        route: route,
        flashsms: flashsms,
        dcs: dcs,
        username: username,
        password: password,
      },
    };
    approveDltId(requestBody)
      .then((res:any) => {
        console.log(res);
        toast.success(res.data.data.message);
        handleRefresh();
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.error.message);
      });
  };

  const rejectId = () => {
    console.log(user, rejectionMessage);
    const requestBody: any = {
      userName: user,
      rejectionMessage: rejectionMessage,
    };
    rejectDltId(requestBody)
      .then((res:any) => {
        console.log(res);
        toast.success(res.data.data.message);
        handleRefresh();
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.error.message);
      });
  };

console.log("status"+ data.smsDetails.status);

  return (
   <>
    <Toaster />
    <div className={classes.wrapper}>
      {/* <div className={classes.header}>{data.companyDetails.companyName}</div> */}
      {errorMessage && (
                <Typography
                  color="error"
                  variant="body2"
                  sx={{ marginLeft: "30px" }}
                >
                  {errorMessage}
                </Typography>
              )}
      <div className={classes.contentWrapperNew}>
        <div className={classes.pLeft}>
          <div className={classes.pProfile}>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <div className={classes.ppImage}>
                <Avatar
                  alt=""
                  src={data.companyDetails.companyLogoURL}
                  style={{ background: "#FF5151", color: "white" }}
                />
              </div>
              <div className={classes.ppNameEmail}>
                <div className={classes.ppName}>
                  {data.companyDetails.companyName}
                </div>
              </div>
            </div>
            {data.verification && (
              <div className={classes.pVerify}>Verified</div>
            )}
          </div>
          <div className={classes.contentWrapper}>
            <p className={classes.contentWrapperTitle}>Created At</p>
            <p className={classes.contentWrapperDesc}>
              {data.createdAt
                ? data.createdAt === ""
                  ? "-"
                  : formatDateTime(data.createdAt)
                : "-"}
            </p>
          </div>
        </div>
        <div className={classes.pRight}>
          <div className={classes.pDetailsField}>
            Username
            <TextField
              placeholder="Username"
              id="outlined-size-small"
              size="small"
              variant="standard"
              value={data.userName}
              //   onChange={handleEmailName}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
                sx: {
                  height: "40px",
                  padding: "0px 10px",
                  borderRadius: "5px",
                },
              }}
              sx={{
                marginTop: "8px",
                width: "-webkit-fill-available",
                background: "#EDF2F7",
                borderRadius: "5px",
                "& label.Mui-focused": {
                  color: "grey",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#EDF2F7",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#EDF2F7",
                  },
                },
              }}
            />
          </div>
          <div className={classes.pDetailsField}>
            Phone Number
            <TextField
              placeholder="Phone Number"
              id="outlined-size-small"
              size="small"
              variant="standard"
              value={data.contactNumber}
              //   onChange={handleEmailName}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
                sx: {
                  height: "40px",
                  padding: "0px 10px",
                  borderRadius: "5px",
                },
              }}
              sx={{
                marginTop: "8px",
                width: "-webkit-fill-available",
                background: "#EDF2F7",
                borderRadius: "5px",
                "& label.Mui-focused": {
                  color: "grey",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#EDF2F7",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#EDF2F7",
                  },
                },
              }}
            />
          </div>
          <div className={classes.pDetailsField}>
            Email
            <TextField
              placeholder="Email"
              id="outlined-size-small"
              size="small"
              variant="standard"
              value={data.email}
              //   onChange={handleEmailName}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
                sx: {
                  height: "40px",
                  padding: "0px 10px",
                  borderRadius: "5px",
                },
              }}
              sx={{
                marginTop: "8px",
                width: "-webkit-fill-available",
                background: "#EDF2F7",
                borderRadius: "5px",
                "& label.Mui-focused": {
                  color: "grey",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#EDF2F7",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#EDF2F7",
                  },
                },
              }}
            />
          </div>
          <div className={classes.pDetailsField}>
            Address
            <TextField
              placeholder="Address"
              id="outlined-size-small"
              size="small"
              variant="standard"
              value={data.address}
              //   onChange={handleEmailName}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
                sx: {
                  height: "40px",
                  padding: "0px 10px",
                  borderRadius: "5px",
                },
              }}
              sx={{
                marginTop: "8px",
                width: "-webkit-fill-available",
                background: "#EDF2F7",
                borderRadius: "5px",
                "& label.Mui-focused": {
                  color: "grey",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#EDF2F7",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#EDF2F7",
                  },
                },
              }}
            />
          </div>
          <div className={classes.pbuttons}>
            
            <div className={classes.pApprove}>
            {approveStatus!== "APPROVED" && (
              <Button
                variant="contained"
                onClick={() => {
                  handleClickApproveOpen();
                }}
              >
                Approve
              </Button>
                )}
            </div>
          
            <Dialog open={opened} onClose={handleClose}>
              <DialogTitle>Data</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  To approve, please enter all the details.
                </DialogContentText>
                <TextField
                  required
                  margin="dense"
                  label="Route"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={route}
                  onChange={(e: any) => {
                    setRoute(e.target.value);
                  }}
                />
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  label="Flash Sms"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={flashsms}
                  onChange={(e: any) => {
                    setFlashSms(e.target.value);
                  }}
                />
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  label="DCS"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={dcs}
                  onChange={(e: any) => {
                    setDcs(e.target.value);
                  }}
                />
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  label="Username"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={username}
                  onChange={(e: any) => {
                    setusername(e.target.value);
                  }}
                />
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  label="Password"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={password}
                  onChange={(e: any) => {
                    setpassword(e.target.value);
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                  type="submit"
                  onClick={() => {
                    handleClose();
                    handleApprove();
                  }}
                >
                  Approve
                </Button>
              </DialogActions>
            </Dialog>
            
            <div className={classes.pReject}>
              <Button
                variant="outlined"
                onClick={() => {
                  handleClickOpen();
                }}
              >
                Reject
              </Button>
            </div>
            <Dialog
              open={open}
              onClose={handleClose}
              fullWidth
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              BackdropProps={{
                style: {
                  backgroundColor: "rgba(0, 0, 0, 0)",
                  backdropFilter: "blur(5px)",
                },
              }}
              PaperProps={{
                elevation: 0,
                style: {
                  boxShadow:
                    "0 1px 1px rgba(0,0,0,0.11), 0 2px 2px rgba(0,0,0,0.11), 0 4px 4px rgba(0,0,0,0.11), 0 8px 8px rgba(0,0,0,0.11), 0 16px 16px rgba(0,0,0,0.11), 0 32px 32px rgba(0,0,0,0.11)",
                },
              }}
            >
              <DialogTitle sx={{ fontSize: "15px" }}>
                Why are you rejecting?
              </DialogTitle>
              <DialogContent>
                <Box>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    variant="outlined"
                    value={rejectionMessage}
                    onChange={(e) => setRejectionMessage(e.target.value)}
                    sx={{
                      height: "70%",
                      "& .MuiInputBase-root": {
                        height: "100%",
                        alignItems: "flex-start",
                      },
                    }}
                  />
                </Box>
              </DialogContent>
              {errorMessage && (
                <Typography
                  color="error"
                  variant="body2"
                  sx={{ marginLeft: "30px" }}
                >
                  {errorMessage}
                </Typography>
              )}
              <DialogActions>
                <Button
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    handleRejectData();
                    handleReject();
                  }}
                  sx={{ color: "red" }}
                >
                  Reject
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    </div>
    </ >
  );
};

export default CompanyDetails;
