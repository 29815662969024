import React from "react";
import classes from "./UserDetails.module.scss";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import { Tooltip } from "@mui/material";

const CompanyDetails = ({ data }: any) => {
  function formatDateTime(dateTimeString: any) {
    const date = new Date(dateTimeString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hours = date.getHours() % 12 || 12;
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = date.getHours() >= 12 ? "PM" : "AM";

    return `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
  }
  return (
    <>
      <div className={classes.wrapper}>
        {/* <div className={classes.header}>{data.companyDetails.companyName}</div> */}
        <div className={classes.contentWrapperNew}>
          <div className={classes.pLeft}>
            <div className={classes.pProfile}>
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <div className={classes.ppImage}>
                  <Avatar
                    alt=""
                    src={data.companyDetails.companyLogoURL}
                    style={{ background: "#FF5151", color: "white" }}
                  />
                </div>
                <div className={classes.ppNameEmail}>
                  <div className={classes.ppName}>
                    {data.companyDetails.companyName}
                  </div>
                  {/* <div className={classes.ppEmail}></div> */}
                </div>
              </div>
              {/* {data.verification && (
                <div className={classes.pVerify}>Verified</div>
              )} */}
              <div className={classes.pVerify}>Verified</div>
            </div>
            <div className={classes.contentWrapper}>
              <p className={classes.contentWrapperTitle}>Created At</p>
              <p className={classes.contentWrapperDesc}>
                {data.createdAt
                  ? data.createdAt === ""
                    ? "-"
                    : formatDateTime(data.createdAt)
                  : "-"}
              </p>
            </div>
            <div
              className={classes.contentWrapper}
              style={{
                borderBottom: "1px solid #c9c9c9",
                borderTop: "1px solid #c9c9c9",
              }}
            >
              <p className={classes.contentWrapperTitle}>Modified At</p>
              <p className={classes.contentWrapperDesc}>
                {data.modifiedAt
                  ? data.modifiedAt === ""
                    ? "-"
                    : formatDateTime(data.modifiedAt)
                  : "-"}
              </p>
            </div>
            <div className={classes.verifySectionRight}>
              <div className={classes.headSection}>
                <p className={classes.verifyText}>Verified mails</p>
              </div>
              <div className={classes.emailssection}>
                {
                data.verifiedEmails.length > 0 && data.verifiedEmails.map((a: any) => (
                    <div className={classes.userDetailsSection}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <div className={classes.userProfile}>
                          {a?.email?.charAt(0)?.toUpperCase()}
                        </div>
                        <div className={classes.userDetailsSectionContent}>
                          <p className={classes.useremail}>{a.email}</p>
                          {/* <p className={classes.useremail}>{a.email}</p> */}
                        </div>
                      </div>
                    </div>
                  ))
                  }
                {data.verifiedEmails.length === 0 && (
                  <div className={classes.nodataText}>
                    No Verified mails Yet
                  </div>
                )}
              </div>
            </div>

            {/* <div className={classes.pButtonL}>Update Brand</div> */}
          </div>
          <div className={classes.pRight}>
            <div className={classes.pDetailsField}>
              Username
              <TextField
                placeholder="Username"
                id="outlined-size-small"
                size="small"
                variant="standard"
                value={data.userName}
                //   onChange={handleEmailName}
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    height: "40px",
                    padding: "0px 10px",
                    borderRadius: "5px",
                  },
                }}
                sx={{
                  marginTop: "8px",
                  width: "-webkit-fill-available",
                  background: "#EDF2F7",
                  borderRadius: "5px",
                  "& label.Mui-focused": {
                    color: "grey",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#EDF2F7",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#EDF2F7",
                    },
                  },
                }}
              />
            </div>
            <div className={classes.pDetailsField}>
              Phone Number
              <TextField
                placeholder="Phone Number"
                id="outlined-size-small"
                size="small"
                variant="standard"
                value={data.contactNumber}
                //   onChange={handleEmailName}
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    height: "40px",
                    padding: "0px 10px",
                    borderRadius: "5px",
                  },
                }}
                sx={{
                  marginTop: "8px",
                  width: "-webkit-fill-available",
                  background: "#EDF2F7",
                  borderRadius: "5px",
                  "& label.Mui-focused": {
                    color: "grey",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#EDF2F7",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#EDF2F7",
                    },
                  },
                }}
              />
            </div>
            <div className={classes.pDetailsField}>
              Email
              <TextField
                placeholder="Email"
                id="outlined-size-small"
                size="small"
                variant="standard"
                value={data.email}
                //   onChange={handleEmailName}
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    height: "40px",
                    padding: "0px 10px",
                    borderRadius: "5px",
                  },
                }}
                sx={{
                  marginTop: "8px",
                  width: "-webkit-fill-available",
                  background: "#EDF2F7",
                  borderRadius: "5px",
                  "& label.Mui-focused": {
                    color: "grey",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#EDF2F7",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#EDF2F7",
                    },
                  },
                }}
              />
            </div>
            <div className={classes.pDetailsField}>
              Database Name
              <TextField
                placeholder="Database Name"
                id="outlined-size-small"
                size="small"
                variant="standard"
                value={data.databaseName}
                //   onChange={handleEmailName}
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    height: "40px",
                    padding: "0px 10px",
                    borderRadius: "5px",
                  },
                }}
                sx={{
                  marginTop: "8px",
                  width: "-webkit-fill-available",
                  background: "#EDF2F7",
                  borderRadius: "5px",
                  "& label.Mui-focused": {
                    color: "grey",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#EDF2F7",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#EDF2F7",
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyDetails;
