import React, { useEffect, useState } from 'react';
import classes from './SmsCredits.module.scss'
import searchIcn from "../../assets/Images/searchIcon.svg";
import StyledLoader from '../../components/Styledloader/StyledLoader';
import DataTable from 'react-data-table-component';
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import PreviewIcon from "@mui/icons-material/Preview";
import { GetUserDetailsAPICall,smsGetallCreditsData, smsGetallCredits } from '../../Utils/Apihelper';
import * as moment from 'moment-timezone';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

const SmsDashboard = () => {
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const opened = Boolean(anchorEl);
  const [tableData, setTableData] = useState<any[]>([]);
  const [userName, setuserName]= useState<any>();
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [requestId, setrequestId]= useState();
  const [status, setstatus]= useState<any>();
  const [open, setOpen] = React.useState(false);
  const [rejectionMessage, setRejectionMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [data,setdata] = useState<any[]>([]);

  const customStyles = {
    table: {
      style: {
        borderRadius: "8px",
        "&:nth-last-child(1)": {
          borderBottom: "none !important",
        },
      },
    },
    headCells: {
      style: {
        paddingLeft: "16px",
        paddingRight: "10px",
        fontWeight: "600",
      },
    },
    headRow: {
      style: {
        backgroundColor: "#F7F9FC",
        borderBottom: "1px solid #9AAAEA ",
      },
    },
    header: {
      style: {
        fontSize: "16px",
        minHeight: "0px",
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingTop: "16px",
        paddingBottom: "16px",
        wordBreak: "break-word",
      },
    },
    rows: {
      style: {
        borderBottom: "1px solid #9AAAEA !important",
        "&:hover": {
          backgroundColor: "#E7F0FF",
        },
        "&:nth-last-child(1)": {
          borderBottom: "none !important",
        },
      },
    },
    contextMenu: {
      style: {
        fontSize: "13px",
        fontWeight: 400,
        paddingLeft: "16px",
        paddingRight: "8px",
        transform: "translate3d(0, -100%, 0)",
        transitionDuration: "125ms",
        transitionTimingFunction: "cubic-bezier(0, 0, 0.2, 1)",
        willChange: "transform",
      },
      activeStyle: {
        transform: "translate3d(0, 0, 0)",
      },
    },
  };

  const handleRowClick = (row: any) => {
    console.log("Clicked row:", row);
    sessionStorage.setItem("companyName", row.companyName);
    sessionStorage.setItem("userName", row.userName);
    navigate("/dashboard/sms-clients/smsclientsdetails");
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row); // Store selected row data
  };

  const handleClose = () => {
    setAnchorEl(null);
    setRejectionMessage('');
    setErrorMessage('');
    setOpen(false);
  };


  useEffect(() => {
    allClientsData();
    userDetails();
    allCredits();
  }, []);

  const allCredits = () => {
    smsGetallCreditsData()
    .then((res:any) => {
      console.log(res.data.history);
      setdata(res.data.data.history);
    })
    .catch((err:any) => {
      console.log(err);
    })
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRejectionMessage(event.target.value);
  };

  const userDetails = () => {
    GetUserDetailsAPICall()
    .then((res:any) => {
      setuserName(res.data.data.name);
      localStorage.setItem("userName", res.data.data.name);
    })
    .catch((err) => {
      console.error("Error", err);
    });
  }

  const name:any= localStorage.getItem("userName");
  

  const allClientsData = () => {
    
    smsGetallCredits("sin")
      .then((res: any) => {
        console.log(res);
        setTableData(res.data.data.history);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
  };

  const handleApprove = (row: any) => {
    setrequestId(row.requestId);
    setstatus("APPROVED");
    // creditsAction();
  };
  
  const handleReject = (row: any) => {
    setrequestId(row.requestId);
    setstatus("REJECTED");
    handleClickOpen();
  };

  const handleRejectData = () => {
    if (rejectionMessage.trim() === '') {
      setErrorMessage('* rejection message is required');
    } else {
      setErrorMessage('');
      handleClose();
    }
  };

  // const creditsAction = () => {
  //   if (!requestId || !status) {
  //     console.error('Missing requestId or status');
  //     return;
  //   }
  //   const requestBody = {
  //     userName:  "sin", 
  //     requestId: requestId,
  //     status: status.toUpperCase(),
  //     rejectionMessage: rejectionMessage,
  //   };

  //   creditsApproveReject(requestBody)
  //     .then((res: any) => {
  //       console.log(res);
  //       allClientsData();
  //     })
  //     .catch((err) => {
  //       console.error("Error fetching client data:", err);
  //     });
  // };

  const formatDate = (utcTime: any) => {
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localTime: any = moment.tz(utcTime, localTimeZone);
    const utcDate = new Date(localTime.format());
    const istDate = new Date(utcDate.getTime() + localTime._offset * 60 * 1000);

    const options: any = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };

    const formattedDate = istDate.toLocaleString("en-IN", options);

    return formattedDate.replace("am", "AM").replace("pm", "PM");
  };

  const handlePreviewDetails = () => {
    navigate("/dashboard/creditspreview-details", { state: { row: selectedRow } });
    handleClose();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const columns:any = [
    {
      name: <div style={{ fontSize: "13px", color: "black", marginLeft: "10px"}}>Requested ID</div>,
      selector: (row: any) => row.requestId,
      sortable: true,
      cell: (row: any) => (
        <div style={{ fontSize: "13px", color: "#5FC322", marginLeft: "10px" }}>
          {row.requestId || "-"}
        </div>
      ),
    },
    {
      name: <div style={{ fontSize: "13px", color: "black", marginLeft: "10px"}}>Company Name</div>,
      selector: (row: any) => row.companyDetails?.companyName,
      sortable: true,
      cell: (row: any) => (
        <div style={{ fontSize: "13px", color: "#5FC322", marginLeft: "10px" }} onClick={() => handleRowClick(row)}>
          {row.companyDetails?.companyName || "-"}
        </div>
      ),
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Transactional Count</div>,
      selector: (row: any) => row.transactionalCount,
      sortable: false,
      cell: (row: any) => (
        <div style={{ fontSize: "13px" }}>
          {row.transactionalCount}
        </div>
      ),
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Promotional Count</div>,
      selector: (row: any) => row.promotionalCount,
      sortable: false,
      cell: (row: any) => (
        <div style={{ fontSize: "13px" }}>
          {row.promotionalCount}
        </div>
      ),
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Total Count</div>,
      selector: (row: any) => row.totalCount,
      sortable: false,
      cell: (row: any) => (
        <div style={{ fontSize: "13px" }}>
          {row.totalCount}
        </div>
      ),
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Per Sms Charges</div>,
      selector: (row: any) => row.perSMSCharges,
      sortable: false,
      cell: (row: any) => (
        <div style={{ fontSize: "13px" }}>
          {row.perSMSCharges}
        </div>
      ),
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Requested Timestamp</div>,
      selector: (row: any) => row.requestedTS,
      sortable: false,
      cell: (row: any) => (
        <div style={{ fontSize: "13px" }}>
          {row.crrequestedTSatedAt==="" ? "" : formatDate(row.crrequestedTSatedAt)}
        </div>
      ),
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Status</div>,
      selector: (row: any) => row.status,
      sortable: true,
      cell: (row: any) => (
        <div
          style={{
            fontSize: "12px",
            textTransform: "uppercase",
            background:
            row.status === "approved" || row.status === "APPROVED"
            ? "#5FC322"
            : row.status === "pending" || row.status === "PENDING"
            ? "orange"
            : row.status === "INITIATED" || row.status === "initiated"
            ? "blue"
            : row.status === "rejected" || row.status === "REJECTED"
            ? "red"
            : "white",
            color: "white",
            padding: "0.5rem",
            minWidth: "85px",
            display: "flex",
            justifyContent: "center",
            borderRadius: "8px",
            fontWeight: "500",
          }}
        >
          {row.status}
        </div>
      ),
    },
    // {
    //   name: <b style={{ fontSize: "13px", color: "black", marginRight: "15px" }}></b>,
    //   selector: (row: any) => row.spamReportRate,
    //   sortable: false,
    //   allowOverflow: true,
    //   button: true,
    //   width: "60px",
    //   cell: (row: any) => (
    //     <b>
    //       <>
    //         <Tooltip title="View Details">
    //           <IconButton
    //             onClick={(event) => handleClick(event, row)}
    //             style={{
    //               background: "#F7F8FF",
    //               borderRadius: "10px",
    //               height: "30px",
    //               marginRight: "18px",
    //             }}
    //           >
    //             <MoreHorizOutlinedIcon />
    //           </IconButton>
    //         </Tooltip>
    //         <Menu
    //           anchorEl={anchorEl}
    //           id="account-menu"
    //           open={open}
    //           PaperProps={{
    //             elevation: 0,
    //             sx: {
    //               overflow: "visible",
    //               filter: "drop-shadow(0px 0px 2px rgba(0,0,0,0.02))",
    //               border: "1px solid #9AAAEA",
    //               mt: 0.5,
    //               background: "#F9FBFF",
    //               borderRadius: "10px",
    //               "& .MuiAvatar-root": {
    //                 width: 32,
    //                 height: 32,
    //                 ml: -0.5,
    //                 mr: 1,
    //               },
    //             },
    //           }}
    //           onClose={handleClose}
    //           transformOrigin={{ horizontal: "right", vertical: "top" }}
    //           anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    //         >
    //           <MenuItem
    //             onClick={() => {
    //               handlePreviewDetails();
    //               handleClose();
    //             }}
    //             sx={{ fontSize: "14px", fontWeight: 400, padding: "10px 14px" }}
    //           >
    //             <ListItemIcon>
    //               <PreviewIcon fontSize="small" sx={{ color: "#000000" }} />
    //             </ListItemIcon>
    //             Preview Details
    //           </MenuItem>
    //         </Menu>
    //       </>
    //     </b>
    //   ),
    // },
    // {
    //   name: <b style={{ fontSize: "13px", color: "black", marginRight: "15px" }}></b>,
    //   selector: (row: any) => row.spamReportRate,
    //   sortable: false,
    //   allowOverflow: true,
    //   button: true,
    //   width: "60px",
    //   cell: (row: any) => (
    //     <b>
    //       <>
    //         <Tooltip title="Approve">
    //           <IconButton
    //             onClick={(event) => {handleClick(event, row);
    //               handleApprove(row);}
    //             }
    //             style={{
    //               background: "#F7F8FF",
    //               borderRadius: "10px",
    //               height: "30px",
    //               marginRight: "18px",
    //               fontSize: "large",
    //             }}
    //           >
    //            <DoneIcon />
    //           </IconButton>
    //         </Tooltip>
    //       </>
    //     </b>
    //   ),
    // },
    // {
    //   name: <b style={{ fontSize: "13px", color: "black", marginRight: "15px" }}></b>,
    //   selector: (row: any) => row.spamReportRate,
    //   sortable: false,
    //   allowOverflow: true,
    //   button: true,
    //   width: "60px",
    //   cell: (row: any) => (
    //     <b>
    //       <>
    //         <Tooltip title="Reject">
    //           <IconButton
    //             onClick={(event) => {handleClick(event, row);
    //               handleClickOpen();
    //               handleReject(row);
    //             }}
    //             style={{
    //               background: "#F7F8FF",
    //               borderRadius: "10px",
    //               height: "30px",
    //               marginRight: "18px",
    //               fontSize: "large"
    //             }}
    //           >
    //             <CloseIcon />
    //           </IconButton>
    //         </Tooltip>
            
    //         <Dialog
    //     open={open}
    //     onClose={handleClose}
    //     fullWidth
    //     aria-labelledby="alert-dialog-title"
    //     aria-describedby="alert-dialog-description"
    //     BackdropProps={{
    //       style: {
    //         backgroundColor: 'rgba(0, 0, 0, 0)', 
    //         backdropFilter: 'blur(5px)',
    //       },
    //     }}
    //     PaperProps={{
    //       elevation: 0,
    //       style: {
    //         boxShadow: '0 1px 1px rgba(0,0,0,0.11), 0 2px 2px rgba(0,0,0,0.11), 0 4px 4px rgba(0,0,0,0.11), 0 6px 8px rgba(0,0,0,0.11), 0 8px 16px rgba(0,0,0,0.11)',
    //       },
    //     }}
        
    //   >
    //     <DialogTitle sx={{fontSize: '15px'}}>Why are you rejecting this credits?</DialogTitle>
    //           <DialogContent>
    //             <Box>
    //           <TextField
    //           fullWidth
    //           multiline
    //           rows={4} 
    //           variant="outlined"
    //           value={rejectionMessage}
    //           onChange={(e) => setRejectionMessage(e.target.value)}
    //           sx={{
    //             height: '70%', 
    //             '& .MuiInputBase-root': {
    //               height: '100%', 
    //               alignItems: 'flex-start', 
    //             },
    //           }}
    //         />
    //       </Box>
    //           </DialogContent>
    //           {errorMessage && (
    //       <Typography color="error" variant="body2" sx={{  marginLeft: '30px' }}>
    //         {errorMessage}
    //       </Typography>
    //     )}
    //           <DialogActions>
    //             <Button onClick={handleClose}>Cancel</Button>
    //             <Button
    //               onClick={() => {
    //                 handleRejectData();
    //                 creditsAction();
    //               }}
                  
    //               sx={{color: 'red'}}
    //             >
    //               Reject
    //             </Button>
    //           </DialogActions>
    //   </Dialog>
    //       </>
    //     </b>
    //   ),
    // },
  ];

  // const handleRowClick = (row: any) => {
  //   navigate('/dashboard/creditspreview-details', { state: { row } });
  // };

  const filteredData = query.trim().length
  ? data.filter(
      (a: any) =>
        a.companyDetails?.companyName.toLowerCase().includes(query.toLowerCase().trim()) ||
        a.requestId.toLowerCase().includes(query.toLowerCase().trim())
    )
  : data;

  return (
    <>
      <div className={classes.tabsWrapper}>
        <div className={classes.tableWrapper}>
          <div className={classes.emailListHeader}>
            <div 
              style={{
                color: "#000",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
                letterSpacing: "-0.2px",
                display: "flex",
                minHeight: "3rem",
                alignItems: "center",
              }}
            >
              Manage Credits
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "right",
              }}
            >
              <div className={classes.searchInput}>
                <div>
                  <img
                    src={searchIcn}
                    alt=""
                    className={classes.searchIcon}
                  />
                </div>
                <input
                  type="text"
                  value={query}
                  className={classes.inputBox}
                  placeholder="Search...."
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>
            </div>
          </div>
          
          <div
            style={{
              border: "1px solid  #D9D9D9",
              borderRadius: "20px",
              paddingBottom: "20px",
            }}
          >
            <DataTable
              data={filteredData}
              columns={columns}
              title=" "
              pagination={true}
              selectableRows={false}
              customStyles={customStyles}
              fixedHeader
              progressComponent={<StyledLoader />}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default SmsDashboard;
