import React, { useState, useEffect } from "react";
import classes from "./CreditHistory.module.scss";
import {
  GetUserDetailsAPICall,
  smsGetallCredits,
  creditsApproveReject,
  addCredit,
  approveRejectCredits,
} from "../../../Utils/Apihelper";
import searchIcn from "../../../assets/Images/searchIcon.svg";
import StyledLoader from "../../..//components/Styledloader/StyledLoader";
import DataTable from "react-data-table-component";
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import PreviewIcon from "@mui/icons-material/Preview";
import * as moment from "moment-timezone";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import toast, { Toaster } from "react-hot-toast";

const CreditHistory = ({ data }: any) => {
  // useEffect(() => {
  //   getCredits();
  // }, []);

  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const opened = Boolean(anchorEl);
  const [tableData, setTableData] = useState<any[]>([]);
  const [userName, setuserName] = useState<any>();
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [requestId, setrequestId] = useState();
  const [status, setstatus] = useState<any>();
  const [open, setOpen] = React.useState(false);
  const [rejectionMessage, setRejectionMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [opens, setOpened] = React.useState(false);
  const [transactionalCount, setTransactionalCount]= useState(0);
  const [promotionalCount, setPromotionalCount]= useState(0);
  const [perSmsCharge, setperSmsCharge]= useState(0);
  const [actionrequestedID, setactionrequestedID] = useState("");
  const [error, setError] = useState(false);

  const customStyles = {
    tableWrapper: {
      style: {
        marginTop: "0px !important",
        paddingTop: "0px !important",
      },
    },
    table: {
      style: {
        borderRadius: "8px",
        paddingTop: "0px",
        marginTop: "0px",
        "&:nth-last-child(1)": {
          borderBottom: "none !important",
        },
      },
    },
    headCells: {
      style: {
        paddingLeft: "16px",
        paddingRight: "10px",
        fontWeight: "600",
      },
    },
    headRow: {
      style: {
        backgroundColor: "#F7F9FC",
        borderBottom: "1px solid #9AAAEA ",
      },
    },
    header: {
      style: {
        fontSize: "16px",
        minHeight: "0px",
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingTop: "16px",
        paddingBottom: "16px",
        wordBreak: "break-word",
      },
    },
    rows: {
      style: {
        borderBottom: "1px solid #9AAAEA !important",
        "&:hover": {
          backgroundColor: "#E7F0FF",
        },
        "&:nth-last-child(1)": {
          borderBottom: "none !important",
        },
      },
    },
    contextMenu: {
      style: {
        fontSize: "13px",
        fontWeight: 400,
        paddingLeft: "16px",
        paddingRight: "8px",
        paddingTop: "0px",
        marginTop: "0px",
        transform: "translate3d(0, -100%, 0)",
        transitionDuration: "125ms",
        transitionTimingFunction: "cubic-bezier(0, 0, 0.2, 1)",
        willChange: "transform",
      },
      activeStyle: {
        transform: "translate3d(0, 0, 0)",
      },
    },
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row); // Store selected row data
  };

  const handleClickApproveOpen = () => {
    setError(false);
    setOpened(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setRejectionMessage("");
    setErrorMessage("");
    setOpen(false);
    setTransactionalCount(0);
    setPromotionalCount(0);
    setperSmsCharge(0);
  };

  useEffect(() => {
    allClientsData();
    // userDetails();
    // creditsAction();
    // addCredits();
    // approveRejectCredit();
  }, []);

  const handleClosed = () => {
    setOpened(false);
  };

  // const approveTransactionalCount = (e:any) => {
  //   setTransactionalCount(e.target.value);
  // }

  // const approvePromotionalCount = (e:any) => {
  //   setPromotionalCount(e.target.value);
  // }

  // const approvePerSmsCharges = (e:any) => {
  //   setperSmsCharge(e.target.value);
  // }


  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRejectionMessage(event.target.value);
  };

  const userDetails = () => {
    GetUserDetailsAPICall()
      .then((res: any) => {
        setuserName(res.data.data.name);
        localStorage.setItem("userName", res.data.data.name);
      })
      .catch((err) => {
        console.error("Error", err);
      });
  };

  const name: any = localStorage.getItem("userName");

  const allClientsData = () => {
    const userName: any = sessionStorage.getItem("userName");
    smsGetallCredits(userName)
      .then((res: any) => {
        console.log(res);
        setTableData(res.data.data.history);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
  };

  // const handleApprove = (row: any) => {
  //   setrequestId(row.requestId);
  //   setstatus("APPROVED");
  //   creditsAction();
  // };

  const handleReject = (row: any) => {
    setrequestId(row.requestId);
    // handleClickOpen(row);
  };

  const handleRejectData = () => {
    if (rejectionMessage.trim() === "") {
      setErrorMessage("* rejection message is required");
    } else {
      setErrorMessage("");
      handleClose();
    }
  };

  // const creditsAction = () => {
  //   // if (!requestId || !status) {
  //   //   console.error("Missing requestId or status");
  //   //   return;
  //   // }
  //   const userName: any = sessionStorage.getItem("userName");
  //   const requestBody = {
  //     userName: userName,
  //     requestId: requestId,
  //     status: status.toUpperCase(),
  //     rejectionMessage: rejectionMessage,
  //   };

  //   creditsApproveReject(requestBody)
  //     .then((res: any) => {
  //       console.log(res);
  //       allClientsData();
  //     })
  //     .catch((err) => {
  //       console.error("Error fetching client data:", err);
  //     });
  // };

  const formatDate = (utcTime: any) => {
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localTime: any = moment.tz(utcTime, localTimeZone);
    const utcDate = new Date(localTime.format());
    const istDate = new Date(utcDate.getTime() + localTime._offset * 60 * 1000);

    const options: any = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };

    const formattedDate = istDate.toLocaleString("en-IN", options);

    return formattedDate.replace("am", "AM").replace("pm", "PM");
  };


  const handlePreviewDetails = () => {
    navigate("/dashboard/creditspreview-details", {
      state: { row: selectedRow },
    });
    handleClose();
  };

  const handleClickOpen = (row:any) => {
    setOpen(true);
    setactionrequestedID(row.requestId);
  };

  const columns: any = [
    {
      name: (
        <div style={{ fontSize: "13px", color: "black", marginLeft: "10px" }}>
          Requested ID
        </div>
      ),
      selector: (row: any) => row.requestId,
      sortable: true,
      cell: (row: any) => (
        <div style={{ fontSize: "13px", color: "#5FC322", marginLeft: "10px" }}>
          {row.requestId || "-"}
        </div>
      ),
    },
    {
      name: (
        <div style={{ fontSize: "13px", color: "black", marginRight: "15px" }}>
          Transactional
        </div>
      ),
      selector: (row: any) => row.transactionalCount,
      sortable: true,
      cell: (row: any) => (
        <div style={{ fontSize: "13px" }}>{row.transactionalCount}</div>
      ),
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Promotional</div>,
      selector: (row: any) => row.promotionalCount,
      sortable: false,
      cell: (row: any) => (
        <div style={{ fontSize: "13px",  }}>
          {row.promotionalCount}
        </div>
      ),
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Total Count</div>,
      selector: (row: any) => row.totalCount,
      sortable: false,
      cell: (row: any) => (
        <div style={{ fontSize: "13px",  }}>
          {row.totalCount}
        </div>
      ),
    },
    {
      name: (
        <div style={{ fontSize: "13px", color: "black" }}>Per Sms Charges</div>
      ),
      selector: (row: any) => row.perSMSCharges,
      sortable: false,
      cell: (row: any) => (
        <div style={{ fontSize: "13px",  }}>
          {row.perSMSCharges}
        </div>
      ),
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Requested At</div>,
      selector: (row: any) => row.requestedTS,
      sortable: false,
      cell: (row: any) => (
        <div style={{ fontSize: "13px",  }}>
          {formatDate(row.requestedTS)}
        </div>
      ),
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Approved At</div>,
      selector: (row: any) => row.approvedTS,
      sortable: false,
      cell: (row: any) => (
        <div style={{ fontSize: "13px",  }}>
          {row.approvedTS==="" ? "" : formatDate(row.approvedTS)}
        </div>
      ),
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Status</div>,
      selector: (row: any) => row.status,
      sortable: true,
      cell: (row: any) => (
        <div
          style={{
            fontSize: "12px",
            textTransform: "uppercase",
            background:
              row.status === "APPROVED" || row.status === "approved"
                ? "#5FC322"
                : row.status === "PENDING" || row.status === "pending"
                ? "orange"
                : row.status === "REJECTED" || row.status === "rejected"
                ? "red"
                : 'white',
            color: "white",
            padding: "0.5rem",
            minWidth: "85px",
            display: "flex",
            justifyContent: "center",
            borderRadius: "8px",
            fontWeight: "500",
          }}
        >
          {row.status}
        </div>
      ),
    },
    // {
    //   name: <b style={{ fontSize: "13px", color: "black", marginRight: "15px" }}></b>,
    //   selector: (row: any) => row.spamReportRate,
    //   sortable: false,
    //   allowOverflow: true,
    //   button: true,
    //   width: "60px",
    //   cell: (row: any) => (
    //     <b>
    //       <>
    //         <Tooltip title="View Details">
    //           <IconButton
    //             onClick={(event) => handleClick(event, row)}
    //             style={{
    //               background: "#F7F8FF",
    //               borderRadius: "10px",
    //               height: "30px",
    //               marginRight: "18px",
    //             }}
    //           >
    //             <MoreHorizOutlinedIcon />
    //           </IconButton>
    //         </Tooltip>
    //         <Menu
    //           anchorEl={anchorEl}
    //           id="account-menu"
    //           open={open}
    //           PaperProps={{
    //             elevation: 0,
    //             sx: {
    //               overflow: "visible",
    //               filter: "drop-shadow(0px 0px 2px rgba(0,0,0,0.02))",
    //               border: "1px solid #9AAAEA",
    //               mt: 0.5,
    //               background: "#F9FBFF",
    //               borderRadius: "10px",
    //               "& .MuiAvatar-root": {
    //                 width: 32,
    //                 height: 32,
    //                 ml: -0.5,
    //                 mr: 1,
    //               },
    //             },
    //           }}
    //           onClose={handleClose}
    //           transformOrigin={{ horizontal: "right", vertical: "top" }}
    //           anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    //         >
    //           <MenuItem
    //             onClick={() => {
    //               handlePreviewDetails();
    //               handleClose();
    //             }}
    //             sx={{ fontSize: "14px", fontWeight: 400, padding: "10px 14px" }}
    //           >
    //             <ListItemIcon>
    //               <PreviewIcon fontSize="small" sx={{ color: "#000000" }} />
    //             </ListItemIcon>
    //             Preview Details
    //           </MenuItem>
    //         </Menu>
    //       </>
    //     </b>
    //   ),
    // },
    {
      name: <b style={{ fontSize: "13px", color: "black", marginRight: "15px" }}></b>,
      selector: (row: any) => row.spamReportRate,
      sortable: false,
      allowOverflow: true,
      button: true,
      width: "60px",
      cell: (row: any) => (
        row.status === "PENDING" ? (
        <b>
          <>
            <Tooltip title="Approve">
              <IconButton
                onClick={(event) => {handleClick(event, row);
                  // handleApprove(row);
                  approveRejectCredit(row.requestId, "APPROVED");
                }
                }
                style={{
                  background: "#F7F8FF",
                  borderRadius: "10px",
                  height: "30px",
                  marginRight: "18px",
                  fontSize: "large",
                }}
              >
               <DoneIcon />
              </IconButton>
            </Tooltip>
          </>
        </b>
        ): null
      ),
    },
    {
      name: <b style={{ fontSize: "13px", color: "black", marginRight: "15px" }}></b>,
      selector: (row: any) => row.spamReportRate,
      sortable: false,
      allowOverflow: true,
      button: true,
      width: "60px",
      cell: (row: any) => (
        row.status === "PENDING" ? (
        <b>
          <>
            <Tooltip title="Reject">
              <IconButton
                onClick={(event) => {handleClick(event, row);
                  handleClickOpen(row);
                  handleReject(row);
                }}
                style={{
                  background: "#F7F8FF",
                  borderRadius: "10px",
                  height: "30px",
                  marginRight: "18px",
                  fontSize: "large"
                }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </>
        </b>
        ): null
      ),
    },
  ];

  const handleRowClick = (row: any) => {
    navigate("/dashboard/creditspreview-details", { state: { row } });
  };

  // const getCredits = () => {
  //   const userName: any = sessionStorage.getItem("userName");
  //   smsGetallCredits(userName)
  //     .then((res: any) => {
  //       console.log(res);
        
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const approveRejectCredit = (requestId:any, status:any) => {
    const userName: any = sessionStorage.getItem("userName");
    // const rejectionMessage: any = sessionStorage.getItem("rejectionMessage");
    const requestBody:any = {
      userName: userName,
      requestId: requestId,
      status: status,
      rejectionMessage: rejectionMessage,
    }
    approveRejectCredits(requestBody)
    .then((res:any) => {
      console.log(res);
      allClientsData();
      toast.success(res.data.data.message);
    })
    .catch((err) => {
      console.log(err);
      toast.error(err.response.data.error.message);
    }) 
  }

  const addCredits = () => {
    const user: any = sessionStorage.getItem("userName");
    const requestBody:any = {
      userName: user,
      transactionalCount: transactionalCount,
      promotionalCount: promotionalCount,
      perSMSCharges: perSmsCharge,
    }
    addCredit(requestBody)
    .then((res) => {
      console.log(res);
      allClientsData();
    })
    .catch((err) => {
      console.log(err);
    })
  }

  const handleApprove = () => {
    if (
      perSmsCharge !== 0 &&
      ((transactionalCount !== 0 && promotionalCount === 0) || 
       (transactionalCount === 0 && promotionalCount !== 0) ||
       (transactionalCount !== 0 && promotionalCount !== 0))
    ) {
      addCredits();
      handleClosed();
    } else {
      setError(true);
    }
  };

  return (
    <>
    <Toaster />
      <div className={classes.tabsWrapper}>
        <div className={classes.tableWrapper}>
          <div className={classes.emailListHeader}>
            {/* <div 
              style={{
                color: "#000",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
                letterSpacing: "-0.2px",
                display: "flex",
                minHeight: "3rem",
                alignItems: "center",
              }}
            >
              Manage Credits
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "right",
              }}
            >
              <div className={classes.searchInput}>
                <div>
                  <img
                    src={searchIcn}
                    alt=""
                    className={classes.searchIcon}
                  />
                </div>
                <input
                  type="text"
                  value={query}
                  className={classes.inputBox}
                  placeholder="Search...."
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>
            </div> */}
          </div>
          <div className={classes.addCredit}>
            <p style={{
              fontWeight: "700",
              marginLeft: "10px",
            }}>Credits</p>
            <Button
              style={{
                color: "white",
                backgroundColor: "#4C6FFF",
              }}
              onClick={() => {
                handleClickApproveOpen();
                handleClose();
              }}
            >
             + Add Credits
            </Button>
            <Dialog
        open={opens}
        onClose={handleClosed}
        sx={{
            '& .MuiDialog-paper': {
              display: 'flex', 
              justifyContent: 'center',
              width: "600px",     
            },
        }}
      >
        <DialogTitle>Credits</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To approve, please enter all the details. 
          </DialogContentText>
          <TextField
            required
            margin="dense"
            label="Transactional Count"
             type="number"
            fullWidth
            variant="standard"
            value={transactionalCount}
            onChange={(e:any) => {
              setTransactionalCount(Number(e.target.value));
            }}
          />
          <div>
          { error && transactionalCount===0 && (
            <p style={{
              fontSize: "10px",
              color: "red",
            }}> Field cannot be empty</p>
          )}
          </div>
           <TextField
            autoFocus
            required
            margin="dense"
            label="Promotinal Count"
             type="number"
            fullWidth
            variant="standard"
            value={promotionalCount}
            onChange={(e:any) => {
              setPromotionalCount(Number(e.target.value));
            }}
          />
          <div>
          { error && promotionalCount===0 && (
            <p style={{
              fontSize: "10px",
              color: "red",
            }}> Field cannot be empty</p>
          )}
          </div>
           <TextField
            autoFocus
            required
            margin="dense"
            label="Per SMS Charges"
             type="number"
            fullWidth
            variant="standard"
            value={perSmsCharge}
            onChange={(e:any) => {
              setperSmsCharge(Number(e.target.value));
            }}
          />
          <div>
          { error && perSmsCharge===0 && (
            <p style={{
              fontSize: "10px",
              color: "red",
            }}> Field cannot be empty</p>
          )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosed}>Cancel</Button>
          <Button type="submit"
          onClick={() => {
            handleApprove();
          }}
          >Add Credit</Button>
        </DialogActions>
      </Dialog>
          </div>
          <div
            style={{
              border: "1px solid  #D9D9D9",
              borderRadius: "20px",
              paddingBottom: "20px",
              marginTop: "0px",
              paddingTop: "0px",
            }}
          >
            <DataTable
              data={tableData}
              columns={columns}
              title=" "
              pagination={true}
              selectableRows={false}
              customStyles={customStyles}
              fixedHeader
              progressComponent={<StyledLoader />}
            />
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            backdropFilter: 'blur(5px)',
          },
        }}
        PaperProps={{
          elevation: 0,
          style: {
            boxShadow: '0 1px 1px rgba(0,0,0,0.11), 0 2px 2px rgba(0,0,0,0.11), 0 4px 4px rgba(0,0,0,0.11), 0 6px 8px rgba(0,0,0,0.11), 0 8px 16px rgba(0,0,0,0.11)',
          },
        }}

      >
        <DialogTitle sx={{fontSize: '15px'}}>Why are you rejecting this credits?</DialogTitle>
              <DialogContent>
                <Box>
              <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              value={rejectionMessage}
              onChange={(e) => setRejectionMessage(e.target.value)}
              sx={{
                height: '70%',
                '& .MuiInputBase-root': {
                  height: '100%',
                  alignItems: 'flex-start',
                },
              }}
            />
          </Box>
              </DialogContent>
              {errorMessage && (
          <Typography color="error" variant="body2" sx={{  marginLeft: '30px' }}>
            {errorMessage}
          </Typography>
        )}
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                  onClick={() => {
                    handleRejectData();
                    {rejectionMessage ?
                    approveRejectCredit(actionrequestedID, "REJECTED") 
                    :  approveRejectCredit(actionrequestedID, "REJECTION MESSAGE IS REQUIRED") 
                    }
                  }}

                  sx={{color: 'red'}}
                >
                  Reject
                </Button>
              </DialogActions>
      </Dialog>
    </>
  );
};

export default CreditHistory;
