import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Stack,
  Fade,
  InputLabel,
  Box,
  IconButton,
  Modal,
  Button,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "60%",
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  p: 2,
};

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

interface Props {
  open: boolean;
  handleClose: any;
  assigneID: any;
  userData: any;
}

export default function AssignUser({
  open,
  handleClose,
  assigneID,
  userData,
}: Props) {
  const [userId, setUserId] = useState("");
  const [users, setusers] = useState<any>();

  const handleChange = (event: SelectChangeEvent) => {
    setUserId(event.target.value as string);
  };

  const handleAssignClick = () => {
    assigneID(userId);
  };
  const close = (e: any) => {
    if (e.keyCode === 27) {
      handleClose();
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        onClose={close}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Stack alignItems={"end"}>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack
              alignItems={"center"}
              justifyContent={"space-evenly"}
              height={"60%"}
            >
              <FormControl sx={{ width: "250px" }}>
                <InputLabel id="demo-simple-select-label">Assign To</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={userId}
                  label="Assign To"
                  onChange={handleChange}
                  size="small"
                  sx={{
                    marginTop: "12px",
                    borderRadius: "10px",
                    width: "100%",
                    background: "#EDF2F7",
                    outline: "none",
                    fontSize: "16px",
                    fontWeight: 500,
                    padding: "0 10px",
                    color: "#000000",
                    boxShadow: "none",
                    "& .MuiOutlinedInput-input": {
                      // padding: "4.5px 4px 2.5px 8px",
                      cursor: "pointer",
                      border: 0,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                  }}
                >
                  {userData?.length>0 &&
                    userData.map((data: any) => {
                      return (
                        <MenuItem value={data.name} key={data.name}>
                          {data.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
              <Button
                variant="contained"
                sx={{ width: "200px" }}
                onClick={handleAssignClick}
              >
                Assign
              </Button>
            </Stack>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
