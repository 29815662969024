import React, { useEffect, useState, useRef } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import classes from "./DashSidebar.module.scss";
import Avatar from "@mui/material/Avatar";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { useDispatch, useSelector } from "react-redux";
import {
  setCollapsed,
  setDashboard,
} from "../../../Store/Project/ProjectSlice";
import { useNavigate, useLocation } from "react-router-dom";
import GroupsIcon from "@mui/icons-material/Groups";
import { ThemeProvider, Tooltip } from "@mui/material";
import { theme } from "../../../components/ThemeProvider/ThemeProvider";
import grid from "../../../assets/Images/grid.svg";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import CompanyTitle from "../../../assets/Images/CNOTIFIERS.svg";
import CompanyLogo from "../../../assets/Images/CLOGO.svg";
import TryIcon from "@mui/icons-material/Try";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { GetUserDetailsAPICall } from "../../../Utils/Apihelper";
import HelpIcon from "@mui/icons-material/Help";
import product1 from "../../../assets/Images/product1.svg";
import product2 from "../../../assets/Images/product3.svg";
import product3 from "../../../assets/Images/product2.svg";
import OutboxIcon from '@mui/icons-material/Outbox';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import zIndex from "@mui/material/styles/zIndex";

const product:any = [
  {
    text: "Email",
    img: product2,
    upcoming: false,
  },
  {
    text: "SMS",
    img: product1,
    upcoming: true,
  },
  {
    text: "Whatsapp",
    img: product3,
    upcoming: true,
  },
];

const DashSidebar = () => {
  const collapsed = useSelector((state: any) => state.project.collapsed);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dataRef = useRef<any>(null);
  const pathName = useLocation().pathname;
  const userEmail = sessionStorage.getItem("email");
  const userName = sessionStorage.getItem("username");
  const [userDetails, setUserDetails] = useState({
    id: "",
    name: "",
    email: "",
    mobile: "",
    isActive: false,
    isAdmin: false,
  });
  const [profileMenu, setprofileMenu] = useState(false);
  const productroute = sessionStorage.getItem("product");
  const buttonRef = useRef<any>(null);

  useEffect(() => {
    GetUserDetails();
  }, []);

  // useEffect(() => {
  //   getUserDetails();
  //   const handleClickOutside = (event) => {
  //     if (dataRef.current && !dataRef.current.contains(event.target)) {
  //       setprofileMenu(false);
  //     }
  //   };
  //   },[]);

  useEffect(() => {
    GetUserDetails();
    if(profileMenu) {
    const handleClickOutside = (event:any) => {
      if (dataRef.current && !dataRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target)) {
        setprofileMenu(false);
        console.log("useeffect", profileMenu);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }
  }, [profileMenu]);

  const handleProfileMenu= () => {
    setprofileMenu((prevState:any) => !prevState);
  }

  const GetUserDetails = () => {
    GetUserDetailsAPICall().then((response: any) => {
      console.log("userResponse", response.data.data);
      if (response.data?.data) {
        setUserDetails(response.data.data);
      }
    });
  };

  const handleClickProduct = (product:any) => {
    sessionStorage.setItem("product", product);
    setprofileMenu(false);

    // if (product !== "Email") {
    //   toast("Hey,this feature is under development!!", {
    //     icon: "⏱️",
    //   });
    // }
    if (product === "Email") {
      navigate("/dashboard/dashboard");
    } else  if (product === "SMS") {
      navigate("/dashboard/sms-dashboard");
    } else {
      navigate("/dashboard/whatsapp-dashboard");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.sidebar}>
        <Sidebar
          collapsed={collapsed}
          backgroundColor="white"
          transitionDuration={600}
          className={classes.mainsidebar}
          width="240px"
          style={{ borderColor: "white", zIndex: 0 }}
        >
          <div className={classes.wrapper}>
            <div className={classes.wrapperTop}>
              <div className={classes.brand}>
              <div
                    className={classes.prWrap}
                    ref={buttonRef}
                    style={{ backgroundColor: profileMenu ? "#e9f0ff" : "" }}
                    onClick={() => {
                      handleProfileMenu();
                    }}
                  >
                <img className={classes.prGrid} src={grid} alt="" />
                {profileMenu && (
                  <>
                    <div className={classes.prMenu} ref={dataRef}>
                      {product.map((a:any) => (
                        <>
                          <div
                            className={classes.prEach}
                            onClick={() => handleClickProduct(a.text)}
                            style={{
                              backgroundColor:
                                a.text === productroute ? "#e9f0ff" : "",
                            }}
                          >
                            <div className={classes.prIcn}>
                              <img src={a.img} alt="" />
                            </div>
                            <div className={classes.prText}>{a.text}</div>
                            {/* <div className={classes.upcoming}>
                                    Upcoming!
                                  </div> */}
                          </div>
                        </>
                      ))}
                    </div>
                  </>
                )}
                </div>
                {!collapsed && (
                  <>
                  <img
                  src={CompanyLogo}
                  style={{ marginLeft: "5px", width: "30px",transition: "all 0.5s ease", }}
                />

                <img
                  src={CompanyTitle}
                  style={{
                    width: "100px",
                    display: collapsed ? "none" : "block",
                    transition: "all 0.5s ease",
                  }}
                />
                </>
                )}
                
                {!collapsed && (
                  <p
                    style={{
                      fontSize: "10px",
                      marginLeft: "2px",
                      marginTop: "5px",
                    }}
                  >
                    (Admin)
                  </p>
                )}
              </div>

              <div className={classes.sidebarMenu}>
                <div className={classes.collapse}>
                  <div
                    onClick={() => dispatch(setCollapsed(!collapsed))}
                    style={{ cursor: "pointer", display: "flex", justifyContent: "center" }}
                  >
                    <MenuOpenIcon
                      sx={{
                        transform: collapsed ? "rotate(180deg)" : "",
                        transition: "transform 0.4s ease",
                        fontSize: "20px",
                        color: "#FF5151",
                        display: "flex",
                        justifyContent: "center"
                      }}
                    />
                  </div>
                </div>
                {productroute === "Email" ? (
                  <Menu
                  menuItemStyles={{
                    button: {
                      color: "#A0AEC0",
                      "&:hover": {
                        backgroundColor: "#F8F9FA",
                        color: "#FF5151",
                      },
                    },
                  }}
                  closeOnClick={true}
                >
                  <Tooltip
                    title={collapsed ? "Dashboard" : ""}
                    placement="right"
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [2, -14],
                            },
                          },
                        ],
                      },
                    }}
                  >
                    <div className={classes.submenu}>
                      <div
                        className={classes.subitem}
                        style={{
                          background: pathName.includes("/dashboard/dashboard")
                            ? "#F8F9FA"
                            : "",
                          color: pathName.includes("/dashboard/dashboard")
                            ? "#2D3748"
                            : "#A0AEC0",
                        }}
                        onClick={() => {
                          navigate("/dashboard");
                        }}
                      >
                        <MenuItem
                          active
                          style={{
                            display: "flex",
                            fontSize: "14px",
                            background: pathName.includes(
                              "/dashboard/dashboard"
                            )
                              ? "#F8F9FA"
                              : "",
                            color: pathName.includes("/dashboard/dashboard")
                              ? "#FF5151"
                              : "",
                            borderRadius: "12px",
                          }}
                          icon={
                            <div
                              style={{
                                borderRadius: "12px",
                                padding: "4px",
                                background: pathName.includes(
                                  "/dashboard/dashboard"
                                )
                                  ? "white"
                                  : "#F7F7F7",
                                height: "max-content",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <DashboardIcon
                                sx={{
                                  fill: pathName.includes(
                                    "/dashboard/dashboard"
                                  )
                                    ? "#FF5151"
                                    : "",
                                }}
                              />
                            </div>
                          }
                        >
                          Dashboard
                        </MenuItem>
                      </div>
                    </div>
                  </Tooltip>
                  <Tooltip
                    title={collapsed ? "All Clients" : ""}
                    placement="right"
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [2, -14],
                            },
                          },
                        ],
                      },
                    }}
                  >
                    <div className={classes.submenu}>
                      <div
                        className={classes.subitem}
                        style={{
                          background: pathName.includes("/clients")
                            ? "#F8F9FA"
                            : "",
                          color: pathName.includes("/clients")
                            ? "#2D3748"
                            : "#A0AEC0",
                        }}
                        onClick={() => {
                          navigate("clients");
                        }}
                      >
                        <MenuItem
                          active
                          style={{
                            display: "flex",
                            fontSize: "14px",
                            background: pathName.includes("/clients")
                              ? "#F8F9FA"
                              : "",
                            color: pathName.includes("/clients")
                              ? "#FF5151"
                              : "",
                            borderRadius: "12px",
                          }}
                          icon={
                            <div
                              style={{
                                borderRadius: "12px",
                                padding: "4px",
                                background: pathName.includes("/clients")
                                  ? "white"
                                  : "#F7F7F7",
                                height: "max-content",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <GroupsIcon
                                sx={{
                                  fill: pathName.includes("/clients")
                                    ? "#FF5151"
                                    : "",
                                }}
                              />
                            </div>
                          }
                        >
                          Clients
                        </MenuItem>
                      </div>
                    </div>
                  </Tooltip>
                  <Tooltip
                    title={collapsed ? "Templates" : ""}
                    placement="right"
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [2, -14],
                            },
                          },
                        ],
                      },
                    }}
                  >
                    <div className={classes.submenu}>
                      <div
                        className={classes.subitem}
                        style={{
                          background: pathName.includes("/templates")
                            ? "#F8F9FA"
                            : "",
                          color: pathName.includes("/templates")
                            ? "#2D3748"
                            : "#A0AEC0",
                        }}
                        onClick={() => {
                          navigate("templates");
                        }}
                      >
                        <MenuItem
                          active
                          style={{
                            display: "flex",
                            fontSize: "14px",
                            background: pathName.includes("/templates")
                              ? "#F8F9FA"
                              : "",
                            color: pathName.includes("/templates")
                              ? "#FF5151"
                              : "",
                            borderRadius: "12px",
                          }}
                          icon={
                            <div
                              style={{
                                borderRadius: "12px",
                                padding: "4px",
                                background: pathName.includes("/templates")
                                  ? "white"
                                  : "#F7F7F7",
                                height: "max-content",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <TryIcon
                                sx={{
                                  fill: pathName.includes("/templates")
                                    ? "#FF5151"
                                    : "",
                                }}
                              />
                            </div>
                          }
                        >
                          Templates
                        </MenuItem>
                      </div>
                    </div>
                  </Tooltip>
                  <Tooltip
                    title={collapsed ? "Users" : ""}
                    placement="right"
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [2, -14],
                            },
                          },
                        ],
                      },
                    }}
                  >
                    <div className={classes.submenu}>
                      <div
                        className={classes.subitem}
                        style={{
                          background: pathName.includes("/users")
                            ? "#F8F9FA"
                            : "",
                          color: pathName.includes("/users")
                            ? "#2D3748"
                            : "#A0AEC0",
                        }}
                        onClick={() => {
                          navigate("users");
                        }}
                      >
                        <MenuItem
                          active
                          style={{
                            display: "flex",
                            fontSize: "14px",
                            background: pathName.includes("/users")
                              ? "#F8F9FA"
                              : "",
                            color: pathName.includes("/users") ? "#FF5151" : "",
                            borderRadius: "12px",
                          }}
                          icon={
                            <div
                              style={{
                                borderRadius: "12px",
                                padding: "4px",
                                background: pathName.includes("/users")
                                  ? "white"
                                  : "#F7F7F7",
                                height: "max-content",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <GroupAddIcon
                                sx={{
                                  fill: pathName.includes("/users")
                                    ? "#FF5151"
                                    : "",
                                }}
                              />
                            </div>
                          }
                        >
                          Users
                        </MenuItem>
                      </div>
                    </div>
                  </Tooltip>
                  <Tooltip
                    title={collapsed ? "Enquires" : ""}
                    placement="right"
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [2, -14],
                            },
                          },
                        ],
                      },
                    }}
                  >
                    <div className={classes.submenu}>
                      <div
                        className={classes.subitem}
                        style={{
                          background: pathName.includes("/enquires")
                            ? "#F8F9FA"
                            : "",
                          color: pathName.includes("/enquires")
                            ? "#2D3748"
                            : "#A0AEC0",
                        }}
                        onClick={() => {
                          navigate("enquires");
                        }}
                      >
                        <MenuItem
                          active
                          style={{
                            display: "flex",
                            fontSize: "14px",
                            background: pathName.includes("/enquires")
                              ? "#F8F9FA"
                              : "",
                            color: pathName.includes("/enquires")
                              ? "#FF5151"
                              : "",
                            borderRadius: "12px",
                          }}
                          icon={
                            <div
                              style={{
                                borderRadius: "12px",
                                padding: "4px",
                                background: pathName.includes("/enquires")
                                  ? "white"
                                  : "#F7F7F7",
                                height: "max-content",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <HelpIcon
                                sx={{
                                  fill: pathName.includes("/enquires")
                                    ? "#FF5151"
                                    : "",
                                }}
                              />
                            </div>
                          }
                        >
                          Enquires
                        </MenuItem>
                      </div>
                    </div>
                  </Tooltip>
                  {/* 
                  <SubMenu
                    style={{
                      display: "flex",
                      fontSize: "14px",
                      background: pathName.includes("/partners")
                        ? "#F8F9FA"
                        : "",
                      color: pathName.includes("/partners") ? "#FF5151" : "",
                      borderRadius: "15px",
                    }}
                    icon={
                      <div
                        style={{
                          borderRadius: "12px",
                          padding: "4px",
                          background: pathName.includes("/partners")
                            ? "white"
                            : "#F8F9FA",
                          height: "max-content",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <HandshakeRoundedIcon
                          sx={{
                            fill: pathName.includes("/partners")
                              ? "#FF5151"
                              : "",
                          }}
                        />
                      </div>
                    }
                    label="Partners"
                  >
                    <div className={classes.submenu}>
                      <div
                        className={classes.subitem}
                        style={{
                          background: pathName.includes("/partners/generateKey")
                            ? "#F8F9FA"
                            : "",
                          color: pathName.includes("/partners/generateKey")
                            ? "#2D3748"
                            : "#A0AEC0",
                        }}
                        onClick={() => {
                          navigate("partners/generateKey");
                        }}
                      >
                        <MenuItem
                          active
                          style={{
                            borderRadius: "15px",
                            fontSize: "13px",
                            height: "35px",
                            fontWeight: 500,
                          }}
                        >
                          Generate Key
                        </MenuItem>
                      </div>
                    </div>
                    <div className={classes.submenu}>
                      <div
                        className={classes.subitem}
                        style={{
                          background: pathName.includes("/partners/vendorList")
                            ? "#F8F9FA"
                            : "",
                          color: pathName.includes("/partners/vendorList")
                            ? "#2D3748"
                            : "#A0AEC0",
                        }}
                        onClick={() => {
                          navigate("partners/vendorList");
                        }}
                      >
                        <MenuItem
                          active
                          style={{
                            borderRadius: "15px",
                            fontSize: "13px",
                            height: "35px",
                            fontWeight: 500,
                          }}
                        >
                          Vendor List
                        </MenuItem>
                      </div>
                    </div>
                  </SubMenu> */}
                </Menu>
                ) : productroute === "SMS" ? (
                  <>
                  <Menu
                  menuItemStyles={{
                    button: {
                      color: "#A0AEC0",
                      "&:hover": {
                        backgroundColor: "#F8F9FA",
                        color: "#FF5151",
                      },
                    },
                  }}
                  closeOnClick={true}
                >
                  {!collapsed &&  <p
                  className={classes.maintext}
                  style={{
                    fontSize: "8px",
                    transition: "all 0.5s ease",
                  }}
                  >
                    MAIN&nbsp;MENU
                  </p>}
                 
                  <Tooltip
                    title={collapsed ? "Dashboard" : ""}
                    placement="right"
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [2, -14],
                            },
                          },
                        ],
                      },
                    }}
                  >
                    <div className={classes.submenu}>
                      <div
                        className={classes.subitem}
                        style={{
                          background: pathName.includes("/dashboard/sms-dashboard")
                            ? "#F8F9FA"
                            : "",
                          color: pathName.includes("/dashboard/sms-dashboard")
                            ? "#2D3748"
                            : "#A0AEC0",
                        }}
                        onClick={() => {
                          navigate("sms-dashboard");
                        }}
                      >
                        <MenuItem
                          active
                          style={{
                            display: "flex",
                            fontSize: "14px",
                            background: pathName.includes(
                              "/dashboard/sms-dashboard"
                            )
                              ? "#F8F9FA"
                              : "",
                            color: pathName.includes("/dashboard/sms-dashboard")
                              ? "#FF5151"
                              : "",
                            borderRadius: "12px",
                          }}
                          icon={
                            <div
                              style={{
                                borderRadius: "12px",
                                padding: "4px",
                                background: pathName.includes(
                                  "/dashboard/sms-dashboard"
                                )
                                  ? "white"
                                  : "#F7F7F7",
                                height: "max-content",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <DashboardIcon
                                sx={{
                                  fill: pathName.includes(
                                    "/dashboard/sms-dashboard"
                                  )
                                    ? "#FF5151"
                                    : "",
                                }}
                              />
                            </div>
                          }
                        >
                          Dashboard
                        </MenuItem>
                      </div>
                    </div>
                  </Tooltip>
                  <Tooltip
                    title={collapsed ? "Dashboard" : ""}
                    placement="right"
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [2, -14],
                            },
                          },
                        ],
                      },
                    }}
                  >
                    <div className={classes.submenu}>
                      <div
                        className={classes.subitem}
                        style={{
                          background: pathName.includes("/dashboard/sms-clients")
                            ? "#F8F9FA"
                            : "",
                          color: pathName.includes("/dashboard/sms-clients")
                            ? "#2D3748"
                            : "#A0AEC0",
                        }}
                        onClick={() => {
                          navigate("sms-clients");
                        }}
                      >
                        <MenuItem
                          active
                          style={{
                            display: "flex",
                            fontSize: "14px",
                            background: pathName.includes(
                              "/dashboard/sms-clients"
                            )
                              ? "#F8F9FA"
                              : "",
                            color: pathName.includes("/dashboard/sms-clients")
                              ? "#FF5151"
                              : "",
                            borderRadius: "12px",
                          }}
                          icon={
                            <div
                              style={{
                                borderRadius: "12px",
                                padding: "4px",
                                background: pathName.includes(
                                  "/dashboard/sms-clients"
                                )
                                  ? "white"
                                  : "#F7F7F7",
                                height: "max-content",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <GroupsIcon
                                sx={{
                                  fill: pathName.includes(
                                    "/dashboard/sms-clients"
                                  )
                                    ? "#FF5151"
                                    : "",
                                }}
                              />
                            </div>
                          }
                        >
                          Clients
                        </MenuItem>
                      </div>
                    </div>
                  </Tooltip>
                  {/* <Tooltip
                    title={collapsed ? "Dashboard" : ""}
                    placement="right"
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [2, -14],
                            },
                          },
                        ],
                      },
                    }}
                  >
                    <div className={classes.submenu}>
                      <div
                        className={classes.subitem}
                        style={{
                          background: pathName.includes("/dashboard/sms-admindetails")
                            ? "#F8F9FA"
                            : "",
                          color: pathName.includes("/dashboard/sms-admindetails")
                            ? "#2D3748"
                            : "#A0AEC0",
                        }}
                        onClick={() => {
                          navigate("sms-admindetails");
                        }}
                      >
                        <MenuItem
                          active
                          style={{
                            display: "flex",
                            fontSize: "14px",
                            background: pathName.includes(
                              "/dashboard/sms-admindetails"
                            )
                              ? "#F8F9FA"
                              : "",
                            color: pathName.includes("/dashboard/sms-admindetails")
                              ? "#FF5151"
                              : "",
                            borderRadius: "12px",
                          }}
                          icon={
                            <div
                              style={{
                                borderRadius: "12px",
                                padding: "4px",
                                background: pathName.includes(
                                  "/dashboard/sms-admindetails"
                                )
                                  ? "white"
                                  : "#F7F7F7",
                                height: "max-content",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <DashboardIcon
                                sx={{
                                  fill: pathName.includes(
                                    "/dashboard/sms-admindetails"
                                  )
                                    ? "#FF5151"
                                    : "",
                                }}
                              />
                            </div>
                          }
                        >
                          Admin
                        </MenuItem>
                      </div>
                    </div>
                  </Tooltip> */}
                  <Tooltip
                    title={collapsed ? "Send SMS" : ""}
                    placement="right"
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [2, -14],
                            },
                          },
                        ],
                      },
                    }}
                  >
                    <div className={classes.submenu}>
                      <div
                        className={classes.subitem}
                        style={{
                          background: pathName.includes("/dashboard/sms-senderid")
                            ? "#F8F9FA"
                            : "",
                          color: pathName.includes("/dashboard/sms-senderid")
                            ? "#2D3748"
                            : "#A0AEC0",
                        }}
                        onClick={() => {
                          navigate("sms-senderid");
                        }}
                      >
                        <MenuItem
                          active
                          style={{
                            display: "flex",
                            fontSize: "14px",
                            background: pathName.includes("/dashboard/sms-senderid")
                              ? "#F8F9FA"
                              : "",
                            color: pathName.includes("/dashboard/sms-senderid")
                              ? "#FF5151"
                              : "",
                            borderRadius: "12px",
                          }}
                          icon={
                            <div
                              style={{
                                borderRadius: "12px",
                                padding: "4px",
                                background: pathName.includes("/dashboard/sms-senderid")
                                  ? "white"
                                  : "#F7F7F7",
                                height: "max-content",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <OutboxIcon
                                sx={{
                                  fill: pathName.includes("/dashboard/sms-senderid")
                                    ? "#FF5151"
                                    : "",
                                }}
                              />
                            </div>
                          }
                        >
                          Sender Id
                        </MenuItem>
                      </div>
                    </div>
                  </Tooltip>
                  <Tooltip
                    title={collapsed ? "All Clients" : ""}
                    placement="right"
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [2, -14],
                            },
                          },
                        ],
                      },
                    }}
                  >
                    <div className={classes.submenu}>
                      <div
                        className={classes.subitem}
                        style={{
                          background: pathName.includes("/dashboard/sms-templates")
                            ? "#F8F9FA"
                            : "",
                          color: pathName.includes("/dashboard/sms-templates")
                            ? "#2D3748"
                            : "#A0AEC0",
                        }}
                        onClick={() => {
                          navigate("sms-templates");
                        }}
                      >
                        <MenuItem
                          active
                          style={{
                            display: "flex",
                            fontSize: "14px",
                            background: pathName.includes("/dashboard/sms-templates")
                              ? "#F8F9FA"
                              : "",
                            color: pathName.includes("/dashboard/sms-templates")
                            ? "#FF5151"
                              : "",
                            borderRadius: "12px",
                          }}
                          icon={
                            <div
                              style={{
                                borderRadius: "12px",
                                padding: "4px",
                                background: pathName.includes("/dashboard/sms-templates")
                                  ? "white"
                                  : "#F7F7F7",
                                height: "max-content",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <ViewCarouselIcon
                                sx={{
                                  fill: pathName.includes("/dashboard/sms-templates")
                                    ? "#FF5151"
                                    : "",
                                }}
                              />
                            </div>
                          }
                        >
                          Template Id
                        </MenuItem>
                      </div>
                    </div>
                  </Tooltip>
                  <Tooltip
                    title={collapsed ? "Users" : ""}
                    placement="right"
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [2, -14],
                            },
                          },
                        ],
                      },
                    }}
                  >
                    <div className={classes.submenu}>
                      <div
                        className={classes.subitem}
                        style={{
                          background: pathName.includes("/sms-credits")
                            ? "#F8F9FA"
                            : "",
                          color: pathName.includes("/sms-credits")
                            ? "#2D3748"
                            : "#A0AEC0",
                        }}
                        onClick={() => {
                          navigate("sms-credits");
                        }}
                      >
                        <MenuItem
                          active
                          style={{
                            display: "flex",
                            fontSize: "14px",
                            background: pathName.includes("/sms-credits")
                              ? "#F8F9FA"
                              : "",
                            color: pathName.includes("/sms-credits") ? "#FF5151" : "",
                            borderRadius: "12px",
                          }}
                          icon={
                            <div
                              style={{
                                borderRadius: "12px",
                                padding: "4px",
                                background: pathName.includes("/sms-credits")
                                  ? "white"
                                  : "#F7F7F7",
                                height: "max-content",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <CurrencyRupeeIcon
                                sx={{
                                  fill: pathName.includes("/sms-credits")
                                    ? "#FF5151"
                                    : "",
                                }}
                              />
                            </div>
                          }
                        >
                          Credits
                        </MenuItem>
                      </div>
                    </div>
                  </Tooltip>
                </Menu>
                  </>
                ) : productroute === "Whatsapp" ? (
                  <>
                  </>
                ): ( <Menu
                  menuItemStyles={{
                    button: {
                      color: "#A0AEC0",
                      "&:hover": {
                        backgroundColor: "#F8F9FA",
                        color: "#FF5151",
                      },
                    },
                  }}
                  closeOnClick={true}
                >
                  <Tooltip
                    title={collapsed ? "Dashboard" : ""}
                    placement="right"
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [2, -14],
                            },
                          },
                        ],
                      },
                    }}
                  >
                    <div className={classes.submenu}>
                      <div
                        className={classes.subitem}
                        style={{
                          background: pathName.includes("/dashboard/dashboard")
                            ? "#F8F9FA"
                            : "",
                          color: pathName.includes("/dashboard/dashboard")
                            ? "#2D3748"
                            : "#A0AEC0",
                        }}
                        onClick={() => {
                          navigate("/dashboard");
                        }}
                      >
                        <MenuItem
                          active
                          style={{
                            display: "flex",
                            fontSize: "14px",
                            background: pathName.includes(
                              "/dashboard/dashboard"
                            )
                              ? "#F8F9FA"
                              : "",
                            color: pathName.includes("/dashboard/dashboard")
                              ? "#FF5151"
                              : "",
                            borderRadius: "12px",
                          }}
                          icon={
                            <div
                              style={{
                                borderRadius: "12px",
                                padding: "4px",
                                background: pathName.includes(
                                  "/dashboard/dashboard"
                                )
                                  ? "white"
                                  : "#F7F7F7",
                                height: "max-content",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <DashboardIcon
                                sx={{
                                  fill: pathName.includes(
                                    "/dashboard/dashboard"
                                  )
                                    ? "#FF5151"
                                    : "",
                                }}
                              />
                            </div>
                          }
                        >
                          Dashboard
                        </MenuItem>
                      </div>
                    </div>
                  </Tooltip>
                  <Tooltip
                    title={collapsed ? "All Clients" : ""}
                    placement="right"
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [2, -14],
                            },
                          },
                        ],
                      },
                    }}
                  >
                    <div className={classes.submenu}>
                      <div
                        className={classes.subitem}
                        style={{
                          background: pathName.includes("/clients")
                            ? "#F8F9FA"
                            : "",
                          color: pathName.includes("/clients")
                            ? "#2D3748"
                            : "#A0AEC0",
                        }}
                        onClick={() => {
                          navigate("clients");
                        }}
                      >
                        <MenuItem
                          active
                          style={{
                            display: "flex",
                            fontSize: "14px",
                            background: pathName.includes("/clients")
                              ? "#F8F9FA"
                              : "",
                            color: pathName.includes("/clients")
                              ? "#FF5151"
                              : "",
                            borderRadius: "12px",
                          }}
                          icon={
                            <div
                              style={{
                                borderRadius: "12px",
                                padding: "4px",
                                background: pathName.includes("/clients")
                                  ? "white"
                                  : "#F7F7F7",
                                height: "max-content",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <GroupsIcon
                                sx={{
                                  fill: pathName.includes("/clients")
                                    ? "#FF5151"
                                    : "",
                                }}
                              />
                            </div>
                          }
                        >
                          Clients
                        </MenuItem>
                      </div>
                    </div>
                  </Tooltip>
                  <Tooltip
                    title={collapsed ? "Templates" : ""}
                    placement="right"
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [2, -14],
                            },
                          },
                        ],
                      },
                    }}
                  >
                    <div className={classes.submenu}>
                      <div
                        className={classes.subitem}
                        style={{
                          background: pathName.includes("/templates")
                            ? "#F8F9FA"
                            : "",
                          color: pathName.includes("/templates")
                            ? "#2D3748"
                            : "#A0AEC0",
                        }}
                        onClick={() => {
                          navigate("templates");
                        }}
                      >
                        <MenuItem
                          active
                          style={{
                            display: "flex",
                            fontSize: "14px",
                            background: pathName.includes("/templates")
                              ? "#F8F9FA"
                              : "",
                            color: pathName.includes("/templates")
                              ? "#FF5151"
                              : "",
                            borderRadius: "12px",
                          }}
                          icon={
                            <div
                              style={{
                                borderRadius: "12px",
                                padding: "4px",
                                background: pathName.includes("/templates")
                                  ? "white"
                                  : "#F7F7F7",
                                height: "max-content",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <TryIcon
                                sx={{
                                  fill: pathName.includes("/templates")
                                    ? "#FF5151"
                                    : "",
                                }}
                              />
                            </div>
                          }
                        >
                          Templates
                        </MenuItem>
                      </div>
                    </div>
                  </Tooltip>
                  <Tooltip
                    title={collapsed ? "Users" : ""}
                    placement="right"
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [2, -14],
                            },
                          },
                        ],
                      },
                    }}
                  >
                    <div className={classes.submenu}>
                      <div
                        className={classes.subitem}
                        style={{
                          background: pathName.includes("/users")
                            ? "#F8F9FA"
                            : "",
                          color: pathName.includes("/users")
                            ? "#2D3748"
                            : "#A0AEC0",
                        }}
                        onClick={() => {
                          navigate("users");
                        }}
                      >
                        <MenuItem
                          active
                          style={{
                            display: "flex",
                            fontSize: "14px",
                            background: pathName.includes("/users")
                              ? "#F8F9FA"
                              : "",
                            color: pathName.includes("/users") ? "#FF5151" : "",
                            borderRadius: "12px",
                          }}
                          icon={
                            <div
                              style={{
                                borderRadius: "12px",
                                padding: "4px",
                                background: pathName.includes("/users")
                                  ? "white"
                                  : "#F7F7F7",
                                height: "max-content",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <GroupAddIcon
                                sx={{
                                  fill: pathName.includes("/users")
                                    ? "#FF5151"
                                    : "",
                                }}
                              />
                            </div>
                          }
                        >
                          Users
                        </MenuItem>
                      </div>
                    </div>
                  </Tooltip>
                  <Tooltip
                    title={collapsed ? "Enquires" : ""}
                    placement="right"
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [2, -14],
                            },
                          },
                        ],
                      },
                    }}
                  >
                    <div className={classes.submenu}>
                      <div
                        className={classes.subitem}
                        style={{
                          background: pathName.includes("/enquires")
                            ? "#F8F9FA"
                            : "",
                          color: pathName.includes("/enquires")
                            ? "#2D3748"
                            : "#A0AEC0",
                        }}
                        onClick={() => {
                          navigate("enquires");
                        }}
                      >
                        <MenuItem
                          active
                          style={{
                            display: "flex",
                            fontSize: "14px",
                            background: pathName.includes("/enquires")
                              ? "#F8F9FA"
                              : "",
                            color: pathName.includes("/enquires")
                              ? "#FF5151"
                              : "",
                            borderRadius: "12px",
                          }}
                          icon={
                            <div
                              style={{
                                borderRadius: "12px",
                                padding: "4px",
                                background: pathName.includes("/enquires")
                                  ? "white"
                                  : "#F7F7F7",
                                height: "max-content",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <HelpIcon
                                sx={{
                                  fill: pathName.includes("/enquires")
                                    ? "#FF5151"
                                    : "",
                                }}
                              />
                            </div>
                          }
                        >
                          Enquires
                        </MenuItem>
                      </div>
                    </div>
                  </Tooltip>
                  {/* 
                  <SubMenu
                    style={{
                      display: "flex",
                      fontSize: "14px",
                      background: pathName.includes("/partners")
                        ? "#F8F9FA"
                        : "",
                      color: pathName.includes("/partners") ? "#FF5151" : "",
                      borderRadius: "15px",
                    }}
                    icon={
                      <div
                        style={{
                          borderRadius: "12px",
                          padding: "4px",
                          background: pathName.includes("/partners")
                            ? "white"
                            : "#F8F9FA",
                          height: "max-content",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <HandshakeRoundedIcon
                          sx={{
                            fill: pathName.includes("/partners")
                              ? "#FF5151"
                              : "",
                          }}
                        />
                      </div>
                    }
                    label="Partners"
                  >
                    <div className={classes.submenu}>
                      <div
                        className={classes.subitem}
                        style={{
                          background: pathName.includes("/partners/generateKey")
                            ? "#F8F9FA"
                            : "",
                          color: pathName.includes("/partners/generateKey")
                            ? "#2D3748"
                            : "#A0AEC0",
                        }}
                        onClick={() => {
                          navigate("partners/generateKey");
                        }}
                      >
                        <MenuItem
                          active
                          style={{
                            borderRadius: "15px",
                            fontSize: "13px",
                            height: "35px",
                            fontWeight: 500,
                          }}
                        >
                          Generate Key
                        </MenuItem>
                      </div>
                    </div>
                    <div className={classes.submenu}>
                      <div
                        className={classes.subitem}
                        style={{
                          background: pathName.includes("/partners/vendorList")
                            ? "#F8F9FA"
                            : "",
                          color: pathName.includes("/partners/vendorList")
                            ? "#2D3748"
                            : "#A0AEC0",
                        }}
                        onClick={() => {
                          navigate("partners/vendorList");
                        }}
                      >
                        <MenuItem
                          active
                          style={{
                            borderRadius: "15px",
                            fontSize: "13px",
                            height: "35px",
                            fontWeight: 500,
                          }}
                        >
                          Vendor List
                        </MenuItem>
                      </div>
                    </div>
                  </SubMenu> */}
                </Menu>)}
                
              </div>
            </div>

            <div className={classes.profileContainer}>
              <Avatar
                alt=""
                src=""
                sx={{
                  background: "#F7F7F7",
                  color: "#FF5151",
                  fontSize: "18px",
                  fontWeight: "bolder",
                }}
              >
                {userDetails.name.slice(0, 1).toUpperCase()}
              </Avatar>{" "}
              {!collapsed && (
                <div className={classes.data}>
                  <div className={classes.name}>{userDetails.name}</div>
                  <div className={classes.email}>{userDetails.email}</div>
                </div>
              )}
            </div>
          </div>
        </Sidebar>
      </div>
    </ThemeProvider>
  );
};

export default DashSidebar;
