import React, { useEffect, useState } from "react";
import styles from "./ClientDetails.module.scss";
import {
  GetCompanyDetailsAPICall,
  GetCompanyEmailHistoryAPICall,
} from "../../Utils/Apihelper";
import CompanyDetails from "./CompanyDetails/CompanyDetails";
import AWSDetails from "./AWSDetails/AWSDetails";
import SubscripitionDetails from "./SubscripitionDetails/SubscripitionDetails";
import MailHistoryDetails from "./MailHistoryDetails/MailHistoryDetails";
import SubscripitionLogs from "./SubscripitionDetails/SubscripitionLogs";

export default function ClientDetails() {
  const CompanyName: any = sessionStorage.getItem("companyName");

  const [tab, setTab] = useState(1);
  const [data, setData] = useState({
    companyDetails: {
      companyName: "MO",
      companyLogoURL: "",
    },
    userName: "Prajwal",
    contactNumber: "9483081313",
    email: "prajwal.s1321997@gmail.com",
    databaseName: "prajwal42",
    userType: "Admin",
    createdAt: "2024-01-20T12:15:42.081000",
    modifiedAt: "2024-01-20T12:15:42.081000",
    verifiedEmails: [],
    awsCredentials: {
      "client-id": "",
      clientSecret: "",
      region: "",
      connection: false,
      configuration: false,
    },
    verification: true,
    subscription: {
      subscriptionStatus: true,
      subscriptionType: "Basic",
      subscriptionExpiry: "",
      subscriptionHistory: [],
    },
    assignedUser: "Umapathi",
  });

  useEffect(() => {
    let companyname = CompanyName;
    GetCompanyDetailsAPICall(companyname)
      .then((response: any) => {
        console.log("response", response.data.data);
        if (response.data?.data) {
          setData(response.data.data);
        }
      })
      .catch((error: any) => {
        console.log("error", error);
      });
   
  }, []);

  function activeTab(tabNumber: any) {
    setTab(tabNumber);
  }

  return (
    <div className={styles.mainWrapper}>
      <div className={styles.tabsWrapper}>
        <div className={styles.tabsCatagory}>
          <div
            className={styles.tabsItem}
            onClick={() => {
              activeTab(1);
            }}
            style={{
              backgroundColor: tab === 1 ? "#4C6FFF" : "",
              color: tab == 1 ? "white" : "",
            }}
          >
            Company Details
          </div>
          <div
            className={styles.tabsItem}
            onClick={() => {
              activeTab(3);
            }}
            style={{
              backgroundColor: tab === 3 ? "#4C6FFF" : "",
              color: tab == 3 ? "white" : "",
            }}
          >
            Mail History
          </div>
          <div
            className={styles.tabsItem}
            onClick={() => {
              activeTab(4);
            }}
            style={{
              backgroundColor: tab === 4 ? "#4C6FFF" : "",
              color: tab == 4 ? "white" : "",
            }}
          >
            Subscription
          </div>
          <div
            className={styles.tabsItem}
            onClick={() => {
              activeTab(5);
            }}
            style={{
              backgroundColor: tab === 5 ? "#4C6FFF" : "",
              color: tab == 5 ? "white" : "",
            }}
          >
            Subscription Logs
          </div>
          <div
            className={styles.tabsItem}
            onClick={() => {
              activeTab(2);
            }}
            style={{
              backgroundColor: tab === 2 ? "#4C6FFF" : "",
              color: tab == 2 ? "white" : "",
            }}
          >
            Aws
          </div>
        </div>
      </div>
      <p className={styles.companyname}>{data.companyDetails.companyName}</p>
      <div className={styles.contentWrapper}>
        {tab === 1 && <CompanyDetails data={data} />}
        {tab === 2 && <AWSDetails data={data} />}
        {tab === 4 && <SubscripitionDetails data={data} />}
        {tab === 5 && <SubscripitionLogs />}
        {tab === 3 && <MailHistoryDetails  />}
      </div>
    </div>
  );
}
