import React, { useEffect, useState } from "react";
import classes from "./SmsClients.module.scss";
import { deepOrange, deepPurple } from "@mui/material/colors";
import DataTable from "react-data-table-component";
import * as moment from "moment-timezone";
import Avatar from "@mui/material/Avatar";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailIcon from "@mui/icons-material/Mail";
import SearchIcon from "@mui/icons-material/Search";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDropzone } from "react-dropzone";
import {
  AssignUserAPICall,
  GetAllCompanyAPICall,
  GetAllPlansAPICall,
  GetUsersAPICall,
  clientAdminProfileApi,
  clientAdminTableApi,
  clientUploadAndResetApi,
  clientUploadApi,
  smsGetallClientsCompany,
} from "../../Utils/Apihelper";
import StyledLoader from "../../components/Styledloader/StyledLoader";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  Select,
  SelectChangeEvent,
  Stack,
  ThemeProvider,
  Tooltip,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Pagination from "@mui/material/Pagination";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setProfile } from "../../Store/Project/ProjectSlice";
import { Toaster, toast } from "react-hot-toast";
import phoneimage from "../../assets/Images/phonevector.svg";
import emailimage from "../../assets/Images/emailvector.svg";
import { theme } from "../../components/ThemeProvider/ThemeProvider";
import { IAssignUser, IGetAllCompany } from "../../Utils/dtos";
import AutoCompleteUser from "../../components/AutoComplete/AutoComplete";
import PersonIcon from "@mui/icons-material/Person";
import AssignUser from "../../components/Modals/AssignUser";
import { Helmet } from "react-helmet";

let randomClr = Math.floor(Math.random() * 16777215).toString(16);

// const filteredData = data.filter((email) =>
// email.tradeCode.toLowerCase().includes(query)  || email.tradeCode.toUpperCase().includes(query)
// );

const SmsClients = () => {
  const [selectedFile, setSelectedFile] = React.useState(null);
  const accepectedFileTypes = ".csv";

  const [openUpload, setOpenUpload] = React.useState(false);
  const [query, setQuery] = useState("");
  const [data, setData] = useState([]);

  const [page, setPage] = useState(1);
  const [rowslimit, setRowsLimit] = useState(30);
  const [loading, setloading] = useState(false);
  const [paginationCount, setpaginationCount] = useState(1);
  const [totalRows, setTotalRows] = useState(5);
  const [disableUpload, setdisableUpload] = React.useState(false);
  const [clear, setclear] = useState(false);
  const [users, setUsers] = useState([]);
  const [plan, setplan] = useState("All");
  const [userDetails, setUserDetails] = useState({
    id: "",
    name: "",
    email: "",
    mobile: "",
    isActive: false,
    isAdmin: false,
  });
  const [openAssignUser, setOpenAssignUser] = useState(false);
  const [companyname, setCompanyName] = useState("");
  const [plansData, setPlansData] = useState<any>([]);
  const [statusdata, setstatusdata] = useState<any[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [Clientname, setuserName] = useState<any[]>([]);
  const [userAssigned, setuserAssigned] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page, query, plan]);

  useEffect(() => {
    GetUsers();
    allClientsCompanyData();
  }, []);

  const allClientsCompanyData = () => {
    const requestBody: any = {
      status: statusdata,
    };

    smsGetallClientsCompany(requestBody)
      .then((res: any) => {
        console.log(res.data.data);
        setTableData(res.data.data.data ? res.data.data.data : []);
        setstatusdata(res.data.data.status);
      })
      .catch((err) => {
        console.error("Error fetching client data:", err);
      });
  };

  //   const GetALLCompanyDetails = () => {
  //     let obj: IGetAllCompany = {
  //       query: query,
  //       pageNo: page,
  //       pageLimit: 20,
  //       plan: plan,
  //     };
  //     setloading(true);
  //     GetAllCompanyAPICall(obj)
  //       .then((response: any) => {
  //         // console.log("response", response.data.data.data);
  //         setloading(false);
  //         setData(response.data.data.data ? response.data.data.data : []);
  //         setTotalRows(Math.ceil(response.data.data.totalrecords / 20));
  //       })
  //       .catch((error: any) => {
  //         setloading(false);
  //         // console.log("error", error);
  //       });
  //   };

  // const GetAllPlans = () => {
  //   GetAllPlansAPICall()
  //     .then((response: any) => {
  //       console.log("response", response.data.data);

  //       const filtered: any = response.data.data.filter(
  //         (a: any) => a.product === "email"
  //       );

  //       setPlansData([{ planName: "All" },...filtered ]);
  //     })
  //     .catch((error) => {});
  // };

  const GetUsers = () => {
    GetUsersAPICall()
      .then((response: any) => {
        setUsers(response.data.data ? response.data.data : []);
      })
      .catch((error: any) => {});
  };

  const handleRowClick = (row: any) => {
    console.log("Clicked row:", row);
    sessionStorage.setItem("companyName", row.companyDetails.companyName);
    sessionStorage.setItem("userName", row.userName);
    navigate("/dashboard/sms-clients/smsclientsdetails");
  };

  const handlePageChange = (value: any, page: any) => {
    window.scrollTo(0, 0);
    setpaginationCount(page);
    setPage(page);
  };
  const handleAssignePopUpClose = () => {
    setOpenAssignUser(false);
  };

  const handleAssigneID = (data: any) => {
    let obj: IAssignUser = {
      companyName: companyname,
      userName: data,
    };

    AssignUserAPICall(obj)
      .then((response: any) => {
        setuserAssigned(true);
        setOpenAssignUser(false);
        toast.success("User Assigned Successfully");
        allClientsCompanyData();
      })
      .catch((error: any) => {
        setOpenAssignUser(false);
        toast.error("Error Assigning User");
      });
  };

  const formatDate = (utcTime: any) => {
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localTime: any = moment.tz(utcTime, localTimeZone);
    const utcDate = new Date(localTime.format());
    const istDate = new Date(utcDate.getTime() + localTime._offset * 60 * 1000);

    const options: any = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };

    const formattedDate = istDate.toLocaleString("en-IN", options);

    return formattedDate.replace("am", "AM").replace("pm", "PM");
  };

  const handleAssign = (data: any) => {
    setOpenAssignUser(true);
    setCompanyName(data.companyDetails.companyName);
  };

  const columns: any = [
    {
      name: (
        <b style={{ fontSize: "15px", fontWeight: "bolder", color: "#2D3748" }}>
          Sr No
        </b>
      ),
      selector: "srno",
      sortable: true,
      width: "70px",
      cell: (row: any, index: any) => (
        <b style={{ fontSize: "14px", color: "#2D3748", fontWeight: 600 }}
        onClick={() => {
          if (row.assignedUser !== "") {
            handleRowClick(row);
          }
        }}>
          {index + 1 + paginationCount * 20 - 20}
        </b>
      ),
    },
    {
      name: (
        <b style={{ fontSize: "15px", fontWeight: "bolder", color: "#2D3748" }}>
          Company Name
        </b>
      ),

      selector: "companyName",
      sortable: true,

      cell: (row: any) => (
        <b
          style={{ fontSize: "14px", color: "#2D3748", fontWeight: 600 }}
          onClick={() => {
            if (row.assignedUser !== "") {
              handleRowClick(row);
            }
          }}
        >
          {row.companyDetails.companyName}
        </b>
      ),
    },
    {
      name: (
        <b style={{ fontSize: "15px", fontWeight: "bolder", color: "#2D3748" }}>
          Mobile
        </b>
      ),

      selector: "contactNumber",
      sortable: true,

      cell: (row: any) => (
        <b style={{ fontSize: "14px", color: "#2D3748", fontWeight: 600 }}
        onClick={() => {
          if (row.assignedUser !== "") {
            handleRowClick(row);
          }
        }}>
          {row.contactNumber}
        </b>
      ),
    },
    {
      name: (
        <b style={{ fontSize: "15px", fontWeight: "bolder", color: "#2D3748" }}>
          Email
        </b>
      ),

      selector: "email",
      sortable: true,

      cell: (row: any) => (
        <b
          style={{ fontSize: "14px", color: "#2D3748", fontWeight: 600 }}
          onClick={() => {
            if (row.assignedUser !== "") {
              handleRowClick(row);
            }
          }}
        >
          {row.email}
        </b>
      ),
    },

    {
      name: (
        <b style={{ fontSize: "15px", fontWeight: "bolder", color: "#2D3748" }}>
          Created At
        </b>
      ),

      selector: "createdAt",
      sortable: true,

      cell: (row: any) => (
        <b
          style={{ fontSize: "14px", color: "#2D3748", fontWeight: 600 }}
          onClick={() => {
            if (row.assignedUser !== "") {
              handleRowClick(row);
            }
          }}
        >
          {row.createdAt.length > 0 ? formatDate(row.createdAt) : row.createdAt}
        </b>
      ),
    },
    // {
    //   name: (
    //     <b style={{ fontSize: "15px", fontWeight: "bolder", color: "#2D3748" }}>
    //       Expiry
    //     </b>
    //   ),

    //   selector: "subscriptionExpiry",
    //   sortable: true,

    //   cell: (row: any) => (
    //     <b
    //       style={{ fontSize: "14px", color: "#2D3748", fontWeight: 600 }}
    //       // onClick={() => handleRowClick(row)}
    //     >
    //       {row.subscription.subscriptionExpiry}
    //     </b>
    //   ),
    // },
    {
      name: (
        <b style={{ fontSize: "15px", fontWeight: "bolder", color: "#2D3748" }}>
          User
        </b>
      ),

      selector: "assignedUser",
      sortable: true,

      cell: (row: any) => (
        <>
          {row.assignedUser === "" ? (
            <Tooltip title="Assign User ">
              <IconButton
                onClick={() => {
                  handleAssign(row);
                }}
              >
                <PersonIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <b
              style={{ fontSize: "12px", cursor: "pointer" }}
              onClick={() => {
                handleAssign(row);
                if (row.assignedUser!== "") {
                setuserAssigned(true);
                }
              }}
            >
              {row.assignedUser}
            </b>
          )}
        </>
      ),
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Status</div>,
      selector: "status",
      sortable: true,
      cell: (row: any) => (
        <div
          style={{
            fontSize: "12px",
            textTransform: "uppercase",
            background:
              row.smsDetails.status === "approved" ||
              row.smsDetails.status === "APPROVED"
                ? "#5FC322"
                : row.smsDetails.status === "pending" ||
                  row.smsDetails.status === "PENDING"
                ? "orange"
                : row.smsDetails.status === "rejected" ||
                  row.smsDetails.status === "REJECTED"
                ? "red"
                : "white",
            color: "white",
            padding: "0.5rem",
            minWidth: "85px",
            display: "flex",
            justifyContent: "center",
            borderRadius: "8px",
            fontWeight: "500",
          }}
          onClick={() => {
            if (row.assignedUser !== "") {
              handleRowClick(row);
            }
          }}
        >
          {row?.smsDetails?.status}
        </div>
      ),
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F3F6FA",
        // display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
      },
    },

    headRow: {
      style: {
        borderBottom: "none",
        // marginBottom: "10px",
      },
    },
    header: {
      style: {
        fontSize: "16px",
        minHeight: "38px",
        paddingLeft: "5px",
        paddingRight: "5px",
      },
    },
    contextMenu: {
      style: {
        fontSize: "14px",
        fontWeight: 400,
        paddingLeft: "16px",
        paddingRight: "8px",
        transform: "translate3d(0, -100%, 0)",
        transitionDuration: "125ms",
        transitionTimingFunction: "cubic-bezier(0, 0, 0.2, 1)",
        willChange: "transform",
      },
      activeStyle: {
        transform: "translate3d(0, 0, 0)",
      },
      rows: {
        style: {
          cursor: "pointer",
          fontSize: "13px",
          fontWeight: 400,
          backgroundColor: "red",
          minHeight: "48px",
          "&:not(:last-of-type)": {
            borderBottomStyle: "solid",
            borderBottomWidth: "1px",
          },
        },
      },
    },
    cells: {
      style: {
        paddingTop: "16px",
        paddingBottom: "16px",
        wordBreak: "break-word",
        cursor: "pointer",
      },
      draggingStyle: {},
    },
  };

  const handleChange = (event: SelectChangeEvent) => {
    setplan(event.target.value as string);
  };

  const handleClear = () => {
    setplan("All");
    setUserDetails({
      id: "",
      name: "",
      email: "",
      mobile: "",
      isActive: false,
      isAdmin: false,
    });
    setPage(1);
    setQuery("");
  };

  const filteredData = query.trim().length
    ? tableData.filter(
        (a: any) =>
          a.companyDetails.companyName
            .toLowerCase()
            .includes(query.toLowerCase().trim()) ||
          a.email.toLowerCase().includes(query.toLowerCase().trim()) ||
          a.contactNumber.includes(query.trim())
      )
    : tableData;

  return (
    <>
      <Helmet>
        <title>Clients </title>
        <meta name="Clients" content="Clients" />
      </Helmet>
      <ThemeProvider theme={theme}>
        <Toaster />
        <div className={classes.wrapper}>
          <div className={classes.topbar}>
            <div className={classes.searchInput}>
              <div className={classes.searchIcon}>
                <SearchIcon sx={{ fill: "black" }} />
              </div>

              <input
                type="text"
                value={query}
                className={classes.inputBox}
                placeholder="Search...."
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
            {/* <div style={{ width: "300px" }}>
              <AutoCompleteUser
                data={users}
                userName={userDetails}
                setuserName={setUserDetails}
              />
            </div> */}
            <div>
              {/* <FormControl fullWidth>
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{ marginTop: "12px" }}
                >
                  Plan
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={plan}
                  label="Plan"
                  onChange={handleChange}
                  size="small"
                  sx={{
                    marginTop: "12px",
                    borderRadius: "10px",
                    width: "150px",
                    background: "#EDF2F7",
                    outline: "none",
                    fontSize: "16px",
                    fontWeight: 500,
                    padding: "0 10px",
                    color: "#000000",
                    boxShadow: "none",
                    "& .MuiOutlinedInput-input": {
                      cursor: "pointer",
                      border: 0,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                  }}
                >
                  {plansData.map((a: any) => (
                    <MenuItem value={a.planName}>{a.planName}</MenuItem>
                  ))}
                </Select>
              </FormControl> */}
            </div>
            {/* <div>
              <Button variant="contained" onClick={handleClear}>
                Clear
              </Button>
            </div> */}
          </div>
          <div className={classes.tablewrapper}>
            <DataTable
              columns={columns}
              data={filteredData}
              fixedHeader
              customStyles={customStyles}
              progressPending={loading}
              progressComponent={<StyledLoader />}
              onRowClicked={(row) => {
                if (row.assignedUser!== "") {
                handleRowClick(row);
                }
              }}
            />
          </div>
          {data.length > 0 && (
            <Stack
              alignItems={"center"}
              marginTop={"20px"}
              marginBottom={"20px"}
            >
              <Pagination
                onChange={(value, page) => {
                  handlePageChange(value, page);
                  // setpaginationCount(page);
                }}
                page={paginationCount}
                count={totalRows}
                variant="outlined"
                shape="rounded"
              />
            </Stack>
          )}
        </div>

        <AssignUser
          open={openAssignUser}
          handleClose={handleAssignePopUpClose}
          assigneID={handleAssigneID}
          userData={users}
        />
      </ThemeProvider>
    </>
  );
};

export default SmsClients;
