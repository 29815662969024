import React, { useState, useEffect } from "react";
import classes from "./SmsClientsData.module.scss";
import {
  smsGetSenderId,
  approveRejectSenderIdTemplateId,
  AssignUserAPICall,
} from "../../../Utils/Apihelper";
import searchIcn from "../../../assets/Images/searchIcon.svg";
import StyledLoader from "../../..//components/Styledloader/StyledLoader";
import DataTable from "react-data-table-component";
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import PreviewIcon from "@mui/icons-material/Preview";
import * as moment from "moment-timezone";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { IAssignUser, IGetAllCompany } from "../../../Utils/dtos";
import toast, { Toaster } from "react-hot-toast";

const SmsClientsData = () => {
  const [data, setData] = useState<any[]>([]);
  const [rejectionMessage, setRejectionMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = React.useState(false);
  const [actionSenderID, setactionSenderID] = useState("");
  const [companyname, setCompanyName] = useState("");
  const [openAssignUser, setOpenAssignUser] = useState(false);

  useEffect(() => {
    senderIddetails();
    // apprroveRejectSenderId();
  }, []);

  const handleAssigneID = (data: any) => {
    let obj: IAssignUser = {
      companyName: companyname,
      userName: data,
    };

    AssignUserAPICall(obj)
      .then((response: any) => {
        setOpenAssignUser(false);
        toast.success("User Assigned Successfully");
        // GetALLCompanyDetails();
      })
      .catch((error: any) => {
        setOpenAssignUser(false);
        toast.error("Error Assigning User");
      });
  };

  const apprroveRejectSenderId = (senderId: any, status: any) => {
    const userName = sessionStorage.getItem("userName");
    const requestBody = {
      request_id: senderId,
      userName: userName,
      status: status,
      rejectionMessage: rejectionMessage,
      type: "senderId",
    };
    approveRejectSenderIdTemplateId(requestBody)
      .then((res: any) => {
        // console.log(res);
        toast.success(res.data.data.message);
        senderIddetails();
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err.response.data.error.message);
      });
  };

  const handleClose = () => {
    setRejectionMessage("");
    setErrorMessage("");
    setOpen(false);
  };

  const handleRejectData = () => {
    if (rejectionMessage.trim() === "") {
      setErrorMessage("* rejection message is required");
    } else {
      setErrorMessage("");
      handleClose();
    }
  };

  const handleClickOpen = (row: any) => {
    setOpen(true);
    setactionSenderID(row.senderId);
  };

  const customStyles = {
    tableWrapper: {
      style: {
        marginTop: "0px !important",
        paddingTop: "0px !important",
      },
    },
    table: {
      style: {
        borderRadius: "8px",
        paddingTop: "0px",
        marginTop: "0px",
        "&:nth-last-child(1)": {
          borderBottom: "none !important",
        },
      },
    },
    headCells: {
      style: {
        paddingLeft: "16px",
        paddingRight: "10px",
        fontWeight: "600",
      },
    },
    headRow: {
      style: {
        backgroundColor: "#F7F9FC",
        borderBottom: "1px solid #9AAAEA ",
      },
    },
    header: {
      style: {
        fontSize: "16px",
        minHeight: "0px",
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingTop: "16px",
        paddingBottom: "16px",
        wordBreak: "break-word",
      },
    },
    rows: {
      style: {
        borderBottom: "1px solid #9AAAEA !important",
        "&:hover": {
          backgroundColor: "#E7F0FF",
        },
        "&:nth-last-child(1)": {
          borderBottom: "none !important",
        },
      },
    },
    contextMenu: {
      style: {
        fontSize: "13px",
        fontWeight: 400,
        paddingLeft: "16px",
        paddingRight: "8px",
        paddingTop: "0px",
        marginTop: "0px",
        transform: "translate3d(0, -100%, 0)",
        transitionDuration: "125ms",
        transitionTimingFunction: "cubic-bezier(0, 0, 0.2, 1)",
        willChange: "transform",
      },
      activeStyle: {
        transform: "translate3d(0, 0, 0)",
      },
    },
  };

  const formatDate = (utcTime: any) => {
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localTime: any = moment.tz(utcTime, localTimeZone);
    const utcDate = new Date(localTime.format());
    const istDate = new Date(utcDate.getTime() + localTime._offset * 60 * 1000);

    const options: any = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };

    const formattedDate = istDate.toLocaleString("en-IN", options);

    return formattedDate.replace("am", "AM").replace("pm", "PM");
  };

  const columns: any = [
    {
      name: (
        <div style={{ fontSize: "13px", color: "black", marginLeft: "10px" }}>
          Sender Id
        </div>
      ),
      selector: (row: any) => row.senderId,
      sortable: true,
      cell: (row: any) => (
        <div style={{ fontSize: "13px", color: "#5FC322", marginLeft: "10px" }}>
          {row.senderId || "-"}
        </div>
      ),
    },
    {
      name: (
        <div style={{ fontSize: "13px", color: "black", marginRight: "15px" }}>
          Type
        </div>
      ),
      selector: (row: any) => row.type,
      sortable: true,
      cell: (row: any) => <div style={{ fontSize: "13px" }}>{row.type}</div>,
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Purpose</div>,
      selector: (row: any) => row.purpose,
      sortable: false,
      cell: (row: any) => (
        <div style={{ fontSize: "13px" }}>
          {row.purpose}
        </div>
      ),
    },
    {
      name: (
        <div style={{ fontSize: "13px", color: "black" }}>DLT Entity ID</div>
      ),
      selector: (row: any) => row.dltEntityId,
      sortable: false,
      cell: (row: any) => (
        <div style={{ fontSize: "13px" }}>
          {row.dltEntityId}
        </div>
      ),
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Created At</div>,
      selector: (row: any) => row.createdAt,
      sortable: false,
      cell: (row: any) => (
        <div style={{ fontSize: "13px" }}>
          {row.createdAt? formatDate(row.createdAt) : ""}
        </div>
      ),
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Modified At</div>,
      selector: (row: any) => row.modifiedAt,
      sortable: false,
      cell: (row: any) => (
        <div style={{ fontSize: "13px" }}>
          {row.modifiedAt === "" ? "" : formatDate(row.modifiedAt)}
        </div>
      ),
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Status</div>,
      selector: (row: any) => row.status,
      sortable: true,
      cell: (row: any) => (
        <div
          style={{
            fontSize: "12px",
            textTransform: "uppercase",
            background:
              row.status === "APPROVED" || row.status === "approved"
                ? "#5FC322"
                : row.status === "PENDING" || row.status === "pending"
                ? "orange"
                : row.status === "REJECTED" || row.status === "rejected"
                ? "red"
                : "white",
            color: "white",
            padding: "0.5rem",
            minWidth: "85px",
            display: "flex",
            justifyContent: "center",
            borderRadius: "8px",
            fontWeight: "500",
          }}
        >
          {row.status}
        </div>
      ),
    },
    {
      name: <b style={{ fontSize: "13px", color: "black" }}></b>,
      selector: (row: any) => row.spamReportRate,
      sortable: false,
      allowOverflow: true,
      button: true,
      cell: (row: any) =>
        row.status === "PENDING" ? (
          <b>
            <>
              <Tooltip title="Approve">
                <IconButton
                  onClick={(event) => {
                    // handleClick(event, row);
                    apprroveRejectSenderId(row.senderId, "APPROVED");
                  }}
                  style={{
                    background: "#F7F8FF",
                    borderRadius: "8px",
                    width: "90px",
                    height: "34px",
                    color: "green",
                    // border: "1px solid green",
                    marginRight: "8px",
                    fontSize: "medium",
                  }}
                >
                  <DoneIcon />
                </IconButton>
              </Tooltip>
            </>
          </b>
        ) : null,
    },
    {
      name: <b style={{ fontSize: "13px", color: "black" }}></b>,
      selector: (row: any) => row.spamReportRate,
      sortable: false,
      allowOverflow: true,
      button: true,
      width: "90px",
      cell: (row: any) =>
        row.status === "PENDING" || row.status === "pending" ? (
          <b>
            <>
              <Tooltip title="Reject">
                <IconButton
                  onClick={(event) => {
                    // handleClick(event, row);
                    handleClickOpen(row);
                  }}
                  style={{
                    background: "#F7F8FF",
                    borderRadius: "8px",
                    width: "90px",
                    height: "34px",
                    color: "red",
                    // border: "1px solid red",
                    marginRight: "10px",
                    fontSize: "medium",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </>
          </b>
        ) : null,
    },
  ];

  const senderIddetails = () => {
    const userName: any = sessionStorage.getItem("userName");
    smsGetSenderId(userName)
      .then((res: any) => {
        console.log(res);
        setData(res.data.data.history);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Toaster />
      <DataTable
        data={data}
        columns={columns}
        title=" "
        pagination={true}
        selectableRows={false}
        customStyles={customStyles}
        fixedHeader
        progressComponent={<StyledLoader />}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        BackdropProps={{
          style: {
            backgroundColor: "rgba(0, 0, 0, 0)",
            backdropFilter: "blur(5px)",
          },
        }}
        PaperProps={{
          elevation: 0,
          style: {
            boxShadow:
              "0 1px 1px rgba(0,0,0,0.11), 0 2px 2px rgba(0,0,0,0.11), 0 4px 4px rgba(0,0,0,0.11), 0 6px 8px rgba(0,0,0,0.11), 0 8px 16px rgba(0,0,0,0.11)",
          },
        }}
      >
        <DialogTitle sx={{ fontSize: "15px" }}>
        Why are you rejecting this sender ID?
        </DialogTitle>
        <DialogContent>
          <Box>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              value={rejectionMessage}
              onChange={(e) => setRejectionMessage(e.target.value)}
              sx={{
                height: "70%",
                "& .MuiInputBase-root": {
                  height: "100%",
                  alignItems: "flex-start",
                },
              }}
            />
          </Box>
        </DialogContent>
        {errorMessage && (
          <Typography color="error" variant="body2" sx={{ marginLeft: "30px" }}>
            {errorMessage}
          </Typography>
        )}
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              handleRejectData();
              apprroveRejectSenderId(actionSenderID, "REJECTED");
            }}
            sx={{ color: "red" }}
          >
            Reject
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SmsClientsData;
