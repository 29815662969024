import React from 'react';
import classes from './WhatsappDashboard.module.scss'

const WhatsappDashBoard = () => {
  return (
    <>
    <div className={classes.tableWrapper}>
        This feature is under development!
    </div>
    </>
  )
}

export default WhatsappDashBoard