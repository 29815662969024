import React, { useEffect, useState } from 'react';
import classes from "./SmsAdminClient.module.scss"
import searchIcn from "../../assets/Images/searchIcon.svg";
import StyledLoader from '../../components/Styledloader/StyledLoader';
import DataTable from 'react-data-table-component';
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import PreviewIcon from "@mui/icons-material/Preview";
import { smsGetallClients,smsGetallClientsCompany } from '../../Utils/Apihelper';
import * as moment from 'moment-timezone';

const SmsAdminClient = () => {
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [tableData, setTableData] = useState<any[]>([]);
  const [statusdata, setstatusdata] = useState<any> ('');


  const customStyles = {
    table: {
      style: {
        borderRadius: "8px",
        "&:nth-last-child(1)": {
          borderBottom: "none !important",
        },
      },
    },
    headCells: {
      style: {
        paddingLeft: "16px",
        paddingRight: "10px",
        fontWeight: "600",
      },
    },
    headRow: {
      style: {
        backgroundColor: "#F7F9FC",
        borderBottom: "1px solid #9AAAEA ",
      },
    },
    header: {
      style: {
        fontSize: "16px",
        minHeight: "0px",
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingTop: "16px",
        paddingBottom: "16px",
        wordBreak: "break-word",
      },
    },
    rows: {
      style: {
        borderBottom: "1px solid #9AAAEA !important",
        "&:hover": {
          backgroundColor: "#E7F0FF",
        },
        "&:nth-last-child(1)": {
          borderBottom: "none !important",
        },
      },
    },
    contextMenu: {
      style: {
        fontSize: "13px",
        fontWeight: 400,
        paddingLeft: "16px",
        paddingRight: "8px",
        transform: "translate3d(0, -100%, 0)",
        transitionDuration: "125ms",
        transitionTimingFunction: "cubic-bezier(0, 0, 0.2, 1)",
        willChange: "transform",
      },
      activeStyle: {
        transform: "translate3d(0, 0, 0)",
      },
    },
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setAnchorEl(event.currentTarget);
    sessionStorage.setItem("TableRow", row.dltId);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    allClientsData();
    // allClientsCompanyData();
  }, []);

  const allClientsData = () => {
    const requestBody = {
      userName: "sin", 
    };

    smsGetallClients(requestBody)
      .then((res: any) => {
        console.log(res);
        setTableData([res.data.data]);
        setstatusdata(res.data.smsDetails.status);
      })
      .catch((err) => {
        console.error("Error fetching client data:", err);
      });
  };



  const allClientsCompanyData = () => {
    const requestBody: any = {
      status: statusdata, 
    };

    smsGetallClientsCompany(requestBody)
      .then((res: any) => {
        console.log(res);
        setTableData([res.data.data]);
      })
      .catch((err) => {
        console.error("Error fetching client data:", err);
      });
  };

  const formatDate = (utcTime: any) => {
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localTime: any = moment.tz(utcTime, localTimeZone);
    const utcDate = new Date(localTime.format());
    const off = new Date().getTimezoneOffset();
    const istDate = new Date(utcDate.getTime() + localTime._offset * 60 * 1000);
    const options: any = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };
    return istDate.toLocaleString("en-IN", options);
  };

  const columns: any = [
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>DLT Entity Id</div>,
      selector: "dltId",
      sortable: true,
      cell: (row: any) => (
        <div style={{ fontSize: "13px", color: "#5FC322" }}>
          {row?.smsDetails?.dltId === "" ? "-" : row?.smsDetails?.dltId}
        </div>
      ),
    },
    {
      name: <div style={{ fontSize: "13px", color: "black", marginRight: "15px" }}>Username</div>,
      selector: "userName",
      sortable: true,
      cell: (row: any) => (
        <div style={{ fontSize: "13px" }}>{row?.userName}</div>
      ),
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>E-mail</div>,
      selector: "email",
      sortable: false,
      cell: (row: any) => (
        <div style={{ fontSize: "13px", textTransform: "capitalize" }}>
          {row?.email}
        </div>
      ),
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Contact No</div>,
      selector: "contact",
      sortable: false,
      cell: (row: any) => (
        <div style={{ fontSize: "13px", textTransform: "capitalize" }}>
          {row?.contactNumber}
        </div>
      ),
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Date added</div>,
      selector: "createdAt",
      sortable: false,
      cell: (row: any) => (
        <div style={{ fontSize: "13px", textTransform: "capitalize" }}>
          {formatDate(row?.createdAt)}
        </div>
      ),
    },
    {
      name: <div style={{ fontSize: "13px", color: "black" }}>Status</div>,
      selector: "status",
      sortable: true,
      cell: (row: any) => (
        <div
          style={{
            fontSize: "12px",
            textTransform: "uppercase",
            background:
              row.status === "APPROVED"
                ? "#5FC322"
                : row.status === "CREATED"
                ? "#4C6FFF"
                : "red",
            color: "white",
            padding: "0.5rem",
            minWidth: "85px",
            display: "flex",
            justifyContent: "center",
            borderRadius: "8px",
            fontWeight: "500",
          }}
        >
          {row?.smsDetails?.status}
        </div>
      ),
    },
    // {
    //   name: <b style={{ fontSize: "13px", color: "black", marginRight: "15px" }}></b>,
    //   selector: "spamReportRate",
    //   sortable: false,
    //   allowOverflow: true,
    //   button: true,
    //   width: "60px",
    //   cell: (row: any) => (
    //     <b>
    //       <>
    //         <Tooltip title="View Details">
    //           <IconButton
    //             onClick={(event) => handleClick(event, row)}
    //             style={{
    //               background: "#F7F8FF",
    //               borderRadius: "10px",
    //               height: "30px",
    //               marginRight: "18px",
    //             }}
    //           >
    //             <MoreHorizOutlinedIcon />
    //           </IconButton>
    //         </Tooltip>
    //         <Menu
    //           anchorEl={anchorEl}
    //           id="account-menu"
    //           open={open}
    //           PaperProps={{
    //             elevation: 0,
    //             sx: {
    //               overflow: "visible",
    //               filter: "drop-shadow(0px 0px 2px rgba(0,0,0,0.02))",
    //               border: "1px solid #9AAAEA",
    //               mt: 0.5,
    //               background: "#F9FBFF",
    //               borderRadius: "10px",
    //               "& .MuiAvatar-root": {
    //                 width: 32,
    //                 height: 32,
    //                 ml: -0.5,
    //                 mr: 1,
    //               },
    //             },
    //           }}
    //           onClose={handleClose}
    //           transformOrigin={{ horizontal: "right", vertical: "top" }}
    //           anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    //         >
    //           <MenuItem
    //             onClick={() => {
    //               navigate("/dashboard/preview-details");
    //               handleClose();
    //             }}
    //             sx={{ fontSize: "14px", fontWeight: 400, padding: "10px 14px" }}
    //           >
    //             <ListItemIcon>
    //               <PreviewIcon fontSize="small" sx={{ color: "#000000" }} />
    //             </ListItemIcon>
    //             Preview Details
    //           </MenuItem>
    //         </Menu>
    //       </>
    //     </b>
    //   ),
    // },
  ];

  const filteredData = query.trim().length
  ? tableData.filter(
      (a: any) =>
        a.userName.toLowerCase().includes(query.toLowerCase().trim()) ||
        a.smsDetails?.dltId.includes(query.trim())
    )
  : tableData;

  return (
    <>
      <div className={classes.tabsWrapper}>
        <div className={classes.tableWrapper}>
          <div className={classes.emailListHeader}>
            <div 
              style={{
                color: "#000",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
                letterSpacing: "-0.2px",
                display: "flex",
                minHeight: "3rem",
                alignItems: "center",
              }}
            >
              Manage Admin
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "right",
              }}
            >
              <div className={classes.searchInput}>
                <div>
                  <img
                    src={searchIcn}
                    alt=""
                    className={classes.searchIcon}
                  />
                </div>
                <input
                  type="text"
                  value={query}
                  className={classes.inputBox}
                  placeholder="Search...."
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              border: "1px solid  #D9D9D9",
              borderRadius: "20px",
              paddingBottom: "20px",
            }}
          >
            <DataTable
              data={filteredData}
              columns={columns}
              title=" "
              pagination={true}
              selectableRows={false}
              customStyles={customStyles}
              fixedHeader
              progressComponent={<StyledLoader />}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default SmsAdminClient;
